/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

//TODO (vincent.candeau): Manage List key item

import React, {useState, FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauFormLabel} from '../aauFormLabel/aauFormLabel';

import {ComboBox, IComboBoxOption } from '@fluentui/react';
import DualListBox from 'react-dual-listbox';

import './react-dual-listbox.scss';
import nextId from "react-id-generator";

export interface AauFormSelectProps {
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    label: string;
    value: string;
    parentFunction?: any;
    helper?: string | null;
    multiple?: boolean;
    enums?: object;
    required?: boolean | null;
    dualmode?: boolean;
    reactValue?: string | null;
    lang: AauToolsLang;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    label: null,
    readonly: false,
    value: '',
    helper: null,
    multiple: false,
    enums: {},
    parentFunction: null,
    required: false,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    dualmode: false,
    reactValue: null
} as AauFormSelectProps;

export const AauFormSelect: FC<AauFormSelectProps> = props => {
    const validateField = (validateValue):void => {
        let valueTmp:string[] = state['content'];

        let isValid = true;

        if ( dualmode === false ) {
            if (multiple) {
                if (typeof (validateValue) === 'string') {
                    if (valueTmp.includes(validateValue)) {
                        valueTmp = valueTmp.filter(o => o !== validateValue);
                    } else {
                        valueTmp.push(validateValue);
                    }
                }
            } else {
                valueTmp = [];
                valueTmp.push(validateValue);
            }
        } else {
            valueTmp = validateValue;
        }

        if (required === true && (valueTmp.length < 1 || validateValue === null)) {
            isValid = false;
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( ((state['isValid'] === null && isValid === false) || state['isValid'] !== null) && parentFunction !== null ) {
                parentFunction(itemKey, valueTmp.filter(o => o !== '').join(','), isValid);
            }
            setState({
                isValid,
                'content': valueTmp.filter(o => o !== '')
            });
        }
    };

    let content;
    const {readonly, itemKey, name, value, parentFunction, helper, multiple, enums, required, lang, fgColorL1,
            dualmode, label, reactValue} = props;

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': value.split(','),
        'reactValue': reactValue
    });

    // console.log(name + " => " + state['reactValue']);

    if (state['isValid'] === null && readonly === false ) {
        validateField(value.split(','));
    }

    if ( readonly === false) {
        let select;
        if ( dualmode === true && multiple === true) {
            const options = [];

            let selectedValuesText = [];

            Object.keys(enums).sort((a,b) => enums[a].toString().localeCompare(enums[b].toString())).forEach(key => {
                options.push({
                    value: `${key}`,
                    label: `${enums[key]}`
                });
            });

            state['content'].forEach(key => {
                if ( enums.hasOwnProperty(key) ) {
                    selectedValuesText.push(key);
                }
            })


            select = <DualListBox
                canFilter
                preserveSelectOrder
                showOrderButtons
                icons={{
                    moveLeft: <span className="iaau-icon iaau-tinyiest fg-l3-white"><i
                        className="ms-Icon ms-Icon--ChevronLeftMed fg-l3-white" aria-hidden="true"></i>
                    </span>,
                    moveAllLeft: <span className="iaau-icon iaau-tinyiest fg-l3-white">
                        <i className="ms-Icon ms-Icon--DoubleChevronLeft fg-l3-white" aria-hidden="true"></i>
                    </span>,
                    moveRight: <span className="iaau-icon iaau-tinyiest fg-l3-white"><i
                        className="ms-Icon ms-Icon--ChevronRightMed fg-l3-white" aria-hidden="true"></i>
                    </span>,
                    moveAllRight: <span className="iaau-icon iaau-tinyiest fg-l3-white">
                        <i className="ms-Icon ms-Icon--DoubleChevronRight fg-l3-white" aria-hidden="true"></i>
                    </span>,
                    moveDown: <span className="iaau-icon iaau-tinyiest fg-l3-white">
                        <i className="ms-Icon ms-Icon--ChevronDown fg-l3-white" aria-hidden="true"></i>
                    </span>,
                    moveUp: <span className="iaau-icon iaau-tinyiest fg-l3-white">
                        <i className="ms-Icon ms-Icon--ChevronUp fg-l3-white" aria-hidden="true"></i>
                    </span>,
                    moveTop: <span className="iaau-icon iaau-tinyiest fg-l3-white">
                        <i className="ms-Icon ms-Icon--DoubleChevronUp fg-l3-white" aria-hidden="true"></i>
                    </span>,
                    moveBottom: <span className="iaau-icon iaau-tinyiest fg-l3-white">
                        <i className="ms-Icon ms-Icon--DoubleChevronDown fg-l3-white" aria-hidden="true"></i>
                    </span>
                }}
                filterCallback={(option, filterInput) => {
                    if (filterInput === '') {
                        return true;
                    }

                    return (new RegExp(filterInput, 'i')).test(option.label);
                }}
                filterPlaceholder="Filter..."
                options={options}
                selected={selectedValuesText}
                onChange={(selected) => validateField(selected)}
            />;
        } else if ( multiple === true) {
            const options: IComboBoxOption[] = [];

            let selectedValuesText = [];

            Object.keys(enums).sort((a,b) => enums[a].toString().localeCompare(enums[b].toString())).forEach(key => {
                options.push({
                    key: `${key}`,
                    text: `${lang.getSelectOption(itemKey, enums[key])}`
                });
                if ( state['content'] !== null && state['content'].indexOf(key) !== -1 ){
                    selectedValuesText.push(lang.getSelectOption(itemKey, enums[key]))
                }
            });

            select = <ComboBox
                id={itemKey}
                key={itemKey}
                selectedKey={state['content'] as string[]}
                aria-labelledby={name}
                allowFreeform={true}
                autoComplete={'on'}
                onChange={(e, option, index, val) => validateField(typeof(option) !== 'undefined' ? option['key'] : null)}
                options={options}
                useComboBoxAsMenuWidth={true}
                text={state['content'].length < 1 ? lang.getText('opt_undefined') : selectedValuesText.join(',   ')}
                multiSelect
            />;
        } else {
            const options: IComboBoxOption[] = [];
            Object.keys(enums).sort((a,b) => enums[a].toString().localeCompare(enums[b].toString())).forEach(key => {
                options.push({
                    key: `${key}`,
                    text: `${lang.getSelectOption(itemKey, enums[key])}`
                });
            });

            let text = lang.getSelectOption(itemKey, enums[state['content']]);
            if ( text.indexOf('_undefined') !== -1 ) {
                text = lang.getText('opt_undefined');
            }

            select = <ComboBox
                id={itemKey}
                key={itemKey}
                aria-labelledby={name}
                allowFreeform={true}
                autoComplete={'on'}
                onChange={(e, option, index, val) => validateField(typeof(option) !== 'undefined' ? option['key'] : null)}
                options={options}
                useComboBoxAsMenuWidth={true}
                text={text}
            />;
        }

        content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={false}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />
            <div className='mt-1'>
                {select}
            </div>
        </div>;
    } else {
       content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={true}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />
            <div className={`view-value pt-5 fg-${fgColorL1}`}>
                {
                    multiple === false ?
                    <div className={`float-left bd-1 bd-radius-15 p-1 pl-2 pr-2 bd-l3-white mr-2`}>{state['content']}</div> :
                    state['content'].filter(o => o !== '').map(key =>
                        <div key={nextId()} className={`float-left bd-1 bd-radius-15 p-1 pl-2 pr-2 bd-l3-white mr-2`}>{lang.getSelectOption(itemKey, enums[key])}</div>
                    )
                }
                <div className='float-clear' />
            </div>
        </div>;
    }

    return content;
};
AauFormSelect.defaultProps = defaultProps;
