/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import { Nav } from '@fluentui/react/lib/Nav';
import {Pivot, PivotItem} from '@fluentui/react';


export interface AauMixinTabsProps {
    vertical?: boolean;
    data: object;
    menuKey: string;
    setMenuKey: any;
    sizePercent?: number;
    tabNabWidth?: number;
}

const defaultProps = {
    vertical: false,
    sizePercent: 90,
    tabNabWidth: 30
} as AauMixinTabsProps;

export const AauMixinTabs: FC<AauMixinTabsProps> = props => {
    const { vertical, data, menuKey, setMenuKey, tabNabWidth, sizePercent} = props;

    const links = [];
    Object.keys(data).forEach(r => {
        links.push({
            key: r.toString(),
            name: `${data[r]['title']}`
        });
    });

    const numeric2 = 2;
    const numeric100 = 100;

    let content = null;
    if ( vertical === true ) {
        content = <div className={`width-${sizePercent} mt-1`}>
            <Pivot>
                {
                    Object.keys(data).map(r => {
                        return <PivotItem headerText={`${data[r]['title']}`}>
                            {data[r]['content']}
                        </PivotItem>
                    })
                }
            </Pivot>
        </div>;
    } else {
        content = <div className={`width-${sizePercent} mt-1`}>
            <div className={`width-${tabNabWidth} float-left height-vh-100-min bd-1 bd-top-none bd-bottom-none bd-left-none`}>
                <Nav
                    isOnTop={true}
                    selectedKey={menuKey}
                    groups={[{links}]}
                    onLinkClick={(e, item) => setMenuKey(item['key'])}
                />
            </div>
            <div className={`width-${numeric100-numeric2-tabNabWidth} float-right height-vh-100-min`}>
                {data[menuKey]['content']}
                <div className='float-clear' />
            </div>
            <div className='float-clear' />
        </div>;
    }

    return content;
};
AauMixinTabs.defaultProps = defaultProps;
