/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import './aauComponentTable.css';

import {AauComponentTableColumnProps} from './tableHeadColumn.d';
import {AauComponentTableBodyRow} from './tableBodyRow';

export interface AauComponentTableBodyProps {
    columns: AauComponentTableColumnProps[];
    data: object[];
    refreshTable?: () => void;
    columnid: string;
    canEdit: boolean;
    canView: boolean;
    canSaveAndAction: string|null;
    saveAndActionLabel?: string;
    viewAction?: string;
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorMain?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode?: boolean;
    handlerPanelInfo?: (url:string) => void;
}

const defaultProps = {
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    canEdit: false,
    canView: false,
    canSaveAndAction: null,
    viewAction: 'view',
    darkMode: false
} as AauComponentTableBodyProps;

export const AauComponentTableBody: FC<AauComponentTableBodyProps> = props => {
    const { columns, data, canEdit, canView, canSaveAndAction, saveAndActionLabel, viewAction, columnid, refreshTable,
            lang, config, bgColorL1, bgColorMain, fgColorL1, darkMode, handlerPanelInfo } = props;

    let colSpan = 0;
    if ( data.length === 0 ) {
        Object.keys(columns).forEach(column => {
            if (columns[column]['state'] === true) {
                colSpan += 1;
            }
        });
    }

    const colorDivider = 2;

    return (
        <tbody>
            {
                data.length !== 0 ?
                Object.keys(data).map(
                    id => <AauComponentTableBodyRow
                        key={`data-${id}`}
                        rowKey={`data-${id}`}
                        columns={columns}
                        data={data[id]}
                        bgRowColor={`bg-${parseInt(id, 10) % colorDivider === 0 ? config.getColor('bg_color_odd', darkMode) : config.getColor('bg_color_even', darkMode)}`}
                        fgRowColor={fgColorL1}
                        refreshTable={refreshTable}
                        columnid={columnid}
                        canView={canView}
                        canEdit={canEdit}
                        canSaveAndAction={canSaveAndAction}
                        saveAndActionLabel={saveAndActionLabel}
                        viewAction={viewAction}
                        lang={lang}
                        config={config}
                        bgColorL1={bgColorL1}
                        bgColorMain={bgColorMain}
                        fgColorL1={fgColorL1}
                        darkMode={darkMode}
                        handlerPanelInfo={handlerPanelInfo}
                    />
                ) :
                <tr>
                    <td colSpan={colSpan} className={`p-2 text-bold text-center fg-${fgColorL1} bg-${config.getColor('bgColor_odd', darkMode)}`}>
                        NOT FOUND
                    </td>
                </tr>
            }
        </tbody>
    );
};
AauComponentTableBody.defaultProps = defaultProps;
