/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauFormLabel} from '../aauFormLabel/aauFormLabel';

import {AauMixinKey} from '../aauMixinKey/aauMixinKey';

import CodeMirror from '@uiw/react-codemirror';
// import 'codemirror/keymap/sublime'; // NOSONAR
// import 'codemirror/keymap/emacs'; // NOSONAR
// import 'codemirror/keymap/vim'; // NOSONAR
// import 'codemirror/lib/codemirror.css'; // NOSONAR
// import 'codemirror/mode/javascript/javascript'; // NOSONAR
// import 'codemirror/mode/yaml/yaml'; // NOSONAR
// import 'codemirror/addon/lint/lint'; // NOSONAR
// import 'codemirror/addon/lint/css-lint'; // NOSONAR
// import 'codemirror/addon/search/search'; // NOSONAR
// import 'codemirror/addon/search/searchcursor'; // NOSONAR
// import 'codemirror/addon/search/matchesonscrollbar'; // NOSONAR
// import 'codemirror/addon/search/match-highlighter'; // NOSONAR
// import 'codemirror/addon/search/jump-to-line'; // NOSONAR
// import 'codemirror/addon/dialog/dialog'; // NOSONAR
// import 'codemirror/addon/dialog/dialog.css'; // NOSONAR
// import 'codemirror/addon/lint/javascript-lint'; // NOSONAR
// import 'codemirror/addon/lint/json-lint'; // NOSONAR
// import 'codemirror/addon/lint/yaml-lint'; // NOSONAR
// import 'codemirror/addon/edit/closebrackets'; // NOSONAR
// import 'codemirror/addon/edit/closetag'; // NOSONAR
// import 'codemirror/addon/edit/continuelist'; // NOSONAR
// import 'codemirror/addon/edit/matchbrackets'; // NOSONAR
// import 'codemirror/addon/edit/matchtags'; // NOSONAR
// import 'codemirror/addon/edit/trailingspace'; // NOSONAR
// import 'codemirror/addon/display/fullscreen'; // NOSONAR
// import 'codemirror/addon/display/panel'; // NOSONAR
// import 'codemirror/addon/display/placeholder'; // NOSONAR
// import 'codemirror/addon/display/rulers'; // NOSONAR
// import 'codemirror/theme/night.css'; // NOSONAR
// import 'codemirror/addon/fold/foldcode'; // NOSONAR
// import './CodeMirror.css';


export interface AauFormEditorCodeProps {
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    label: string;
    value?: string;
    validator: object;
    parentFunction?: any;
    helper?: string | null;
    required?: boolean | null;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    label: null,
    readonly: false,
    validator: {},
    parentFunction: null,
    value: '',
    helper: null,
    required: false,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauFormEditorCodeProps;

export const AauFormEditorCode: FC<AauFormEditorCodeProps> = props => {
    const validateField = (validateValue):void => {
        let isValid = true;

        if (required === true && (validateValue === '' || validateValue === null)) {
            isValid = false;
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( ((state['isValid'] === null && isValid === false) || state['isValid'] !== null) && parentFunction !== null ) {
                parentFunction(itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                'content': validateValue
            });
        }
    };


    let content;
    const {readonly, itemKey, name, value, parentFunction, helper, required, fgColorL1, label} = props;

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': value
    });
    if ( state['content'] !== value ) {
        setState({
            isValid: state['isValid'],
            content: value
        });
    }
    if (state['isValid'] === null && readonly === false ) {
        validateField(value);
    }

    if ( readonly === false) {
        content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={false}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />
            <div className='mt-3'>
                <CodeMirror
                    id={itemKey}
                    key={`${itemKey}-ctrl`}
                    //name={name}
                    value={state['content']}
                    onChange={(value, viewUpdate) => validateField(value)}
                    theme={'dark'}
                    minHeight="400px"
                    //indentWithTabs={true}
                    //autoCloseBrackets={true}
                    basicSetup={{
                        lineNumbers: true,
                        bracketMatching: true,
                        history: true,
                        foldGutter: true,
                        closeBrackets: true
                    }}
                />
                {/*extraKeys= {{*/}
                {/*    'F11': cm => {*/}
                {/*        cm.setOption('vimMode', !cm.getOption('vimMode'));*/}
                {/*    },*/}
                {/*    'F12': cm => {*/}
                {/*        const wrap = cm.getWrapperElement();*/}
                {/*        if (wrap.className.indexOf('CodeMirror-fullscreen') === -1) {*/}
                {/*            wrap.className += ' CodeMirror-fullscreen';*/}
                {/*        } else {*/}
                {/*            wrap.className = wrap.className.replace(/\s*CodeMirror-fullscreen\b/, '');*/}
                {/*        }*/}
                {/*        cm.refresh();*/}
                {/*    },*/}
                {/*    'Esc': cm => {*/}
                {/*        const wrap = cm.getWrapperElement();*/}
                {/*        wrap.className = wrap.className.replace(/\s*CodeMirror-fullscreen\b/, '');*/}
                {/*        cm.refresh();*/}
                {/*    }*/}
                {/*}}*/}
            </div>
            <label>
                {/*<AauMixinKey keytouch='F11' description='Vim Mode' fgColorL1={fgColorL1} />,&nbsp;*/}
                {/*<AauMixinKey keytouch='F12' description='Full Screen' fgColorL1={fgColorL1} />,&nbsp;*/}
                <AauMixinKey keytouch='CTRL + F' description='Recherche' fgColorL1={fgColorL1} />
            </label>
        </div>;
    } else {
       content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={true}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />
            <div className='view-value pt-9'>
                <pre className={`fg-${fgColorL1}`}>
                    {state['content']}
                </pre>
            </div>
        </div>;
    }

    return content;
};
AauFormEditorCode.defaultProps = defaultProps;

