/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import './aauComponentTable.css';

import {AauComponentTableColumnProps} from './tableHeadColumn.d';

import {AauComponentTableHeadRow} from './tableHeadRow';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';


export interface AauComponentTableHeadProps {
    columns: AauComponentTableColumnProps[];
    parentSetColumns: any;
    sortSetColumn: any;
    lang: AauToolsLang;
    config?: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorMain?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    data?: object[];
    darkMode?: boolean;
}

const defaultProps = {
    columns: [],
    data: [],
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauComponentTableHeadProps;

export const AauComponentTableHead: FC<AauComponentTableHeadProps> = props => {
    const {columns, parentSetColumns, sortSetColumn, lang, config, bgColorL1, bgColorMain, fgColorL1,
           data, darkMode} = props;

    return (
        <thead className={`bg-l3-black fg-${fgColorL1}`}>
            <AauComponentTableHeadRow
                key='th_name'
                rowId='th_name'
                data={data}
                columns={columns}
                showFilter={false}
                parentSetColumns={parentSetColumns}
                sortSetColumn={sortSetColumn}
                lang={lang}
                config={config}
                bgColorL1={bgColorL1}
                bgColorMain={bgColorMain}
                fgColorL1={fgColorL1}
                darkMode={darkMode}
            />
            <AauComponentTableHeadRow
                key='th_filters'
                rowId='th_filters'
                data={data}
                columns={columns}
                showFilter={true}
                parentSetColumns={parentSetColumns}
                sortSetColumn={sortSetColumn}
                lang={lang}
                config={config}
                bgColorL1={bgColorL1}
                fgColorL1={fgColorL1}
                darkMode={darkMode}
            />
        </thead>
    );
};
AauComponentTableHead.defaultProps = defaultProps;
