/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauFormLabel} from '../aauFormLabel/aauFormLabel';

import {Rating, RatingSize } from '@fluentui/react';


export interface AauFormRatingProps {
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    label: string;
    value?: number;
    validator: object;
    parentFunction?: any;
    helper?: string | null;
    required?: boolean | null;
    max: number;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    label: null,
    readonly: false,
    validator: {},
    parentFunction: null,
    value: 1,
    helper: null,
    required: false,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    max: 5
} as AauFormRatingProps;

export const AauFormRating: FC<AauFormRatingProps> = props => {
    const validateField = (validateValue):void => {
        let isValid=true;

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( parentFunction !== null ) {
                parentFunction(itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                'content': validateValue
            });
        }
    };

    let content;
    const {readonly, itemKey, name, max, value, parentFunction, helper, required, fgColorL1, label} = props;

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': value
    });
    if (state['isValid'] === null && readonly === false ) {
        validateField(value === 0 ? 1 : 0);
    }


    if ( readonly === false) {
        content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={false}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />
            <div className='mt-1'>
                <Rating
                    id={itemKey}
                    key={`${itemKey}-ctrl`}
                    max={max}
                    size={RatingSize.Large}
                    defaultRating={0}
                    onChange={ (e, val) => validateField(val)}
                    defaultValue={state['content']}
                />
            </div>
        </div>;
    } else {
       content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={true}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />
            <div className={`pos-relative view-value pt-5 fg-${fgColorL1}`}>
                <Rating
                    max={5}
                    size={RatingSize.Large}
                    defaultRating={state['content']}
                />
                <div className='pos-absolute z-10 width-100 height-px-36 top-32 d-block' />
            </div>
        </div>;
    }

    return content;
};
AauFormRating.defaultProps = defaultProps;
