/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to distribute, remix, adapt without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../../Components/aauToolsConfiguration/aauToolsConfiguration';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';

import axios from 'axios';
import Cookies from 'js-cookie';
import {aauToolsColorEnum} from '../../Components/aauToolsColor/aauToolsColor';

import {AauMixinIconButton} from '../../Components/aauMixinIconButton/aauMixinIconButton';

export interface AauPageAIOProps {
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL2?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode?: boolean;
}

const defaultProps = {
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    darkMode: false

} as AauPageAIOProps;

export const AauPageAIO: FC<AauPageAIOProps> = props => {
    const {lang, config, bgColorL2, fgColorL1} = props;
    const [state, setState] = useState<string>(null);
    const [data, setData] = useState(null);

    const admin = '/admin';
    const module = 'aio';
    const action = 'generatetoken';

    let content = <AauMixinLoader />;

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/${action}/${state}`,
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    if ( data !== null && data !== false ) {
        content = <div>
            <div className='mt-15'>
                Voici le token pour l'environnement: {state}
            </div>
            <div className='mt-5'>
                <div className={`bg-l3-black fg-l3-white width-100 p-5 mx-auto text-wrap`}>
                    {data['info']['access_token']}
                </div>
            </div>
        </div>;
    } else if ( state !== null && data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //SONAR
    }

    return (
        <div className='width-vw-99 height-vh-95-min'>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
                config={config}
                bgColorL2={bgColorL2}
                fgColorL1={fgColorL1}
            />
            <div className='p-5 pos-absolute width-92 top-px-87'>
                <div>
                    <AauMixinIconButton
                        bgColor='l4-green'
                        className='text-bold'
                        variant='onlytext'
                        tooltip={lang.getBtn('aio_integration')}
                        mouseClickHandler={e => {
                            setState('integration')
                            setData(null)
                        }}
                    />
                    <AauMixinIconButton
                        bgColor='l4-red'
                        className='text-bold'
                        variant='onlytext'
                        tooltip={lang.getBtn('aio_production')}
                        mouseClickHandler={e => {
                            setState('production')
                            setData(null)
                        }}
                    />
                </div>
                {data === null ? null : content}
            </div>
        </div>
    );
};
AauPageAIO.defaultProps = defaultProps;
