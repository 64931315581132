/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useParams} from 'react-router-dom';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../../Components/aauToolsConfiguration/aauToolsConfiguration';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';

import axios from 'axios';
import Cookies from 'js-cookie';
import {aauToolsColorEnum} from '../../Components/aauToolsColor/aauToolsColor';

import {AauMixinTabs} from '../../Components/aauMixinTabs/aauMixinTabs';
import {AauMixinIcon} from '../../Components/aauMixinIcon/aauMixinIcon';

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import {AauMixinFilters} from '../../Components/aauMixinFilters/aauMixinFilters';

export interface AauPageDatabaseProps {
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL2?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauPageDatabaseProps;

export const AauPageDatabase: FC<AauPageDatabaseProps> = props => {
    const {lang, config, bgColorL2, fgColorL1} = props;
    const {admin} = useParams();
    const [data, setData] = useState(null);
    const [schemaPattern, setSchemaPattern] = useState<string>('');
    const [userPattern, setUserPattern] = useState<string>('');
    const [menuKey, setMenuKey] = useState<string>('0');

    const module = 'database';

    let content = <AauMixinLoader />;
    let filterContent = null;

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}`,
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    if ( data !== null && data !== false ) {
        let i = 1;
        const dataArray = [];
        Object.keys(data['info']['data']).forEach(did => {
            const bdds = data['info']['data'][did]['bdd'];

            const schemas = Object.keys(bdds).map(tid => {
                let subName = null;
                let subContent = null;
                let schemaContent = null;

                const bddInformation = bdds[tid];

                if (bddInformation.hasOwnProperty('users')) {
                    subName = bddInformation['name'];
                    if ( schemaPattern === '' || (schemaPattern !== '' && subName.toLowerCase().includes(schemaPattern.toLowerCase())) ) {
                        subContent = Object.keys(bddInformation['users']).map(uid => {
                            let userDiv = null;

                            if (userPattern === '' || (userPattern !== '' && bddInformation['users'][uid]['name'].toLowerCase().includes(userPattern.toLowerCase()))) {
                                userDiv = <li key={`bdd_${tid}_${uid}`}>
                                    <AauMixinIcon
                                        size={'tiny'}
                                        icon={bddInformation['users'][uid]['right'] === 'RW' ? 'pencil' : 'eye'}
                                        color={(bddInformation['users'][uid]['right'] === 'RW' ? 'l3-green' : 'l3-red') as aauToolsColorEnum}
                                        className='pr-1'
                                    />
                                    {bddInformation['users'][uid]['name']}
                                </li>;
                            }

                            return userDiv;
                        });
                        schemaContent = <div key={`bdd_${tid}`} className='width-100 bd-1 fit-content'>
                            <h3 className='text-center width-100'>{subName}</h3>
                            <ul className='mt-3'>
                                {subContent}
                            </ul>
                        </div>;
                    }
                } else if (bddInformation.hasOwnProperty('schemas')) {
                    schemaContent = Object.keys(bddInformation['schemas']).map(sid => {
                        let content2 = null;
                        const schemaInformation = bddInformation['schemas'][sid];

                        subName = `${bddInformation['name']}.${schemaInformation['name']}`;
                        if ( schemaPattern === '' || (schemaPattern !== '' && subName.toLowerCase().includes(schemaPattern.toLowerCase())) ) {
                            subContent = null;

                            subContent = Object.keys(schemaInformation['roles']).map(rid => {
                                let roleContent = null;

                                const userContent = Object(schemaInformation['roles'][rid]['users']).map(uname => {
                                    let userContentTmp = null;
                                    if (userPattern === '' || (userPattern !== '' && uname.toLowerCase().includes(userPattern.toLowerCase()))) {
                                        userContentTmp = <li key={`bdd_${rid}_${uname}`}>{uname}</li>;
                                    }

                                    return userContentTmp;
                                });

                                if ( userPattern === '' || (userPattern !== '' && Array.isArray(userContent) && userContent.length) ) {
                                    roleContent = <li>
                                        <AauMixinIcon
                                            size={'tiny'}
                                            icon={schemaInformation['roles'][rid]['right'] === 'RW' ? 'pencil' : 'eye'}
                                            color={(schemaInformation['roles'][rid]['right'] === 'RW' ? 'l3-green' : 'l3-red') as aauToolsColorEnum}
                                            className='pr-1'
                                        />{schemaInformation['roles'][rid]['name']}
                                        <ul>
                                            {userContent}
                                        </ul>
                                    </li>;
                                }

                                return roleContent;
                            });

                            content2 = <div  key={`bdd_${did}_${sid}`} className='width-100 bd-1 fit-content'>
                                <h3 className='text-center width-100'>{subName}</h3>
                                <ul className='mt-3'>
                                    {subContent}
                                </ul>
                            </div>;
                        }

                        return content2;
                    });
                } else {
                    //NOSONAR
                }

                return schemaContent;
            });

            dataArray.push({
                title: data['info']['data'][did]['name'],
                content: data['info']['data'][did] === null ?
                    null : <div>
                        <h1 className='width-100 text-center'>{data['info']['data'][did]['name']}</h1>

                        <ResponsiveMasonry
                            columnsCountBreakPoints={{500: 1, 1000: 2, 1500: 3, 2000:4}}
                        >
                            <Masonry gutter={20}>
                                {schemas}
                            </Masonry>
                        </ResponsiveMasonry>
                    </div>
            });
            i = i + 1;
        });

        content = <AauMixinTabs
            data={dataArray}
            menuKey={menuKey}
            setMenuKey={setMenuKey}
            sizePercent={100}
            tabNabWidth={document.body.offsetWidth < config.getWidth('screen_small_width') ? config.getWidth('vertical_tabs_small') : config.getWidth('vertical_tabs_big')}
        />;

        filterContent = <AauMixinFilters
            lang={lang}
            filters={[
                {component: 'search', name: 'schema', 'width': 200, changeHandler: value => {setSchemaPattern(value);}, value: schemaPattern},
                {component: 'search', name: 'user', 'width': 200, changeHandler: value => {setUserPattern(value);}, value: userPattern}
            ]}
        />;
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }


    return (
        <div className='width-vw-99 height-vh-95-min'>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
                config={config}
                bgColorL2={bgColorL2}
                fgColorL1={fgColorL1}
            />
            <div className='pos-absolute top-px-87'>
                {filterContent}
            </div>
            <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-150'>
                {content}
            </div>
        </div>
    );
};
AauPageDatabase.defaultProps = defaultProps;
