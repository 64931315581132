/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useParams} from 'react-router-dom';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../../Components/aauToolsConfiguration/aauToolsConfiguration';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';

import axios from 'axios';
import Cookies from 'js-cookie';
import {aauToolsColorEnum} from '../../Components/aauToolsColor/aauToolsColor';

import {AauMixinFabBack} from '../../Components/aauMixinFab/aauMixinFabPredined';
import {AauMixinTabs} from '../../Components/aauMixinTabs/aauMixinTabs';
import {AauComponentLogFile} from '../../Components/aauComponentLogFile/aauComponentLogFile';


export interface AauPageDockerInfoProps {
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL2?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauPageDockerInfoProps;

export const AauPageDockerInfo: FC<AauPageDockerInfoProps> = props => {
    const {lang, config, bgColorL2, fgColorL1} = props;
    const {admin, identifier} = useParams();
    const [data, setData] = useState(null);
    const [menuKey, setMenuKey] = useState<string>('0');

    const module = 'docker';
    const action = 'dockerinfo';

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/${action}/${identifier}`,
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    let content = <AauMixinLoader />;

    if ( data !== null && data !== false ) {
        const dataArray = [
            {
                title: 'Log',
                content: <AauComponentLogFile
                    value={data['info']['logs']}
                />
            },
            {
                title: 'stats',
                content: <table className='width-100'>
                    <tr>
                        {
                            Object.keys(data['info']['top']['titles']).map((c, index) => <th>{data['info']['top']['titles'][c]}</th>)
                        }
                    </tr>
                    {
                        Object.keys(data['info']['top']['procs']).map((r, index) => {
                            return <tr>
                                {
                                    Object.keys(data['info']['top']['procs'][r]).map((c, index) => <td className='text-wrap' valign='top'>{data['info']['top']['procs'][r][c]}</td>)
                                }
                            </tr>;
                        })
                    }
                </table>
            }
        ];

        content = <AauMixinTabs
            data={dataArray}
            menuKey={menuKey}
            setMenuKey={setMenuKey}
            sizePercent={100}
            tabNabWidth={document.body.offsetWidth < config.getWidth('screen_small_width') ? config.getWidth('vertical_tabs_small') : config.getWidth('vertical_tabs_big')}
        />;
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <div className='width-vw-99 height-vh-95-min'>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
                config={config}
                bgColorL2={bgColorL2}
                fgColorL1={fgColorL1}
            />
            <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-87'>
                {content}
            </div>
            <AauMixinFabBack
                lang={lang}
                config={config}
                url={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`}
            />
        </div>
    );
};
AauPageDockerInfo.defaultProps = defaultProps;
