/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import { Persona, PersonaSize, PersonaPresence } from '@fluentui/react/lib/Persona';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {TooltipHost} from '@fluentui/react/lib/Tooltip';

export interface AauMixinPersonaProps {
    displayName?: string;
    showText?: boolean;
    config: AauToolsConfiguration;
    className?: string;
}

const defaultProps = {
    showText: false,
    displayName: 'Unknow User',
    className: 'mx-auto width-48 text-center mt-1 mb-1'
} as AauMixinPersonaProps;

export const AauMixinPersona: FC<AauMixinPersonaProps> = props => {
    const { displayName, showText, className, config } = props;

    const d = displayName.split(' ');

    return <TooltipHost
        content={displayName}
    >
        <Persona
            className={className}
            size={PersonaSize.size32}
            presence={PersonaPresence.none}
            imageAlt={displayName}
            imageUrl={`${config.getGlobal('back')}/persona/${displayName}.jpg`}
            imageInitials={`${d[0][0]}${d[d.length-1][0]}`}
            text={displayName}
            hidePersonaDetails={!showText}
        />
    </TooltipHost>;
};
AauMixinPersona.defaultProps = defaultProps;
