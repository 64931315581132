/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';


export interface AauMixinStrUnitProps {
    value: number;
    precision: number,
    unit: string;
}

const defaultProps = {
    value: 0,
    precision: 2,
    unit: 'o'
} as AauMixinStrUnitProps;

export const AauMixinStrUnit: FC<AauMixinStrUnitProps> = props => {
    const { value, precision, unit } = props;

    const power_labels = {
        0: '',
        1: 'K',
        2: 'M',
        3: 'G',
        4: 'T',
        5: 'P'
    }

    let power = 2 ** 10;
    let n = 0;
    let size = value;
    while (size > power) {
        size /= power;
        n++;
    }

    return <div className='text-right'>
        {size.toFixed(precision)} {power_labels[n]}{unit}
    </div>;
};
AauMixinStrUnit.defaultProps = defaultProps;
