/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

//TODO (vincent.candeau): Manage List key item

import React, {useState, FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauFormLabel} from '../aauFormLabel/aauFormLabel';

import { IPersonaProps } from '@fluentui/react/lib/Persona';
import {
    IBasePickerSuggestionsProps,
    IPeoplePickerItemSelectedProps,
    NormalPeoplePicker,
    PeoplePickerItem,
    ValidationState,
} from '@fluentui/react/lib/Pickers';
import {ComboBox, IComboBoxOption } from '@fluentui/react';
import {AauMixinPersona} from '../aauMixinPersona/aauMixinPersona';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';

export interface AauFormPeoplePickerProps {
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    label: string;
    value: string;
    parentFunction?: any;
    helper?: string | null;
    multiple?: boolean;
    enums?: object;
    required?: boolean | null;
    lang: AauToolsLang;
    fgColorL1?: aauToolsColorEnum;
    backurl: string;
    config: AauToolsConfiguration;
}

const defaultProps = {
    label: null,
    readonly: false,
    value: '',
    helper: null,
    multiple: false,
    enums: {},
    parentFunction: null,
    required: false,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauFormPeoplePickerProps;

export const AauFormPeoplePicker: FC<AauFormPeoplePickerProps> = props => {
    const validateField = (validateValue):void => {
        let valueTmp:string[] = state['content'];

        let isValid = true;

        if ( multiple ) {
            if (typeof (validateValue) === 'string') {
                if (valueTmp.includes(validateValue)) {
                    valueTmp = valueTmp.filter(o => o !== validateValue);
                } else {
                    valueTmp.push(validateValue);
                }
            }
        } else if ( validateValue !== null ) {
            valueTmp = [];
            valueTmp.push(validateValue);
        } else {
            valueTmp = null;
        }

        if (required === true) {
            if ( valueTmp === null ) {
                isValid = false;
            } else if ( valueTmp.length < 1 || validateValue === null ) {
                isValid = false;
            }
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( ((state['isValid'] === null && isValid === false) || state['isValid'] !== null) && parentFunction !== null ) {
                parentFunction(itemKey, valueTmp !== null ? valueTmp.filter(o => o !== '').join(',') : null, isValid);
            }
            setState({
                isValid,
                'content': valueTmp !== null ? valueTmp.filter(o => o !== '').join(',') : null
            });
        }
    };

    let content;
    const {readonly, itemKey, name, value, parentFunction, helper, multiple, enums, required, lang, fgColorL1, backurl,
           config, label} = props;

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': value.split(',')
    });
    if (state['isValid'] === null && readonly === false ) {
        validateField(value !== '' ? value.split(',') : null );
    }

    // const [peopleFilter, setPeopleFilter] = useState<string>('');
    //
    // let personaSelect = 1;
    // let i = 0;
    //
    // const peopleTmp: IPersonaProps[] = [];
    // Object.keys(enums).sort((a,b) => enums[a].localeCompare(enums[b])).forEach(key => {
    //     let d = enums[key].split(' ');
    //
    //     if (peopleFilter.toLowerCase() !== '' && enums[key].toLowerCase().includes(peopleFilter) === true) {
    //         console.log(enums[key];)
    //
    //         peopleTmp.push({
    //             key: key,
    //             imageUrl: `${backurl}/persona/${enums[key]}.jpg`,
    //             imageInitials: `${d[0][0]}${d[d.length - 1][0]}`,
    //             text: enums[key],
    //             presence: 0
    //         });
    //
    //         if (value === key) {
    //             personaSelect = i
    //         } else {
    //             i += 1;
    //         }
    //     }
    // });
    //
    // const suggestionProps: IBasePickerSuggestionsProps = {
    //     suggestionsHeaderText: 'Suggested People',
    //     mostRecentlyUsedHeaderText: 'Suggested Contacts',
    //     noResultsFoundText: 'No results found',
    //     loadingText: 'Loading',
    //     showRemoveButtons: true,
    //     suggestionsAvailableAlertText: 'People Picker Suggestions available',
    //     suggestionsContainerAriaLabel: 'Suggested contacts',
    // };
    //
    // const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState<IPersonaProps[]>(peopleTmp);
    // const [peopleList, setPeopleList] = React.useState<IPersonaProps[]>(peopleTmp);
    //
    // const picker = React.useRef(null);
    //
    // const onFilterChanged = (
    //     filterText: string,
    //     currentPersonas: IPersonaProps[],
    //     limitResults?: number,
    // ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    //     console.log('toto');
    //     if (filterText) {
    //         let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);
    //
    //         filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
    //         filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
    //         return filterPromise(filteredPersonas);
    //     } else {
    //         return [];
    //     }
    // };
    //
    // const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    //     console.log('filterPersonasByText');
    //     return peopleList.filter(item => doesTextStartWith(item.text as string, filterText));
    // };
    //
    // const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
    //     console.log('toto1');
    //     return convertResultsToPromise(personasToReturn);
    // };
    //
    // const returnMostRecentlyUsed = (currentPersonas: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
    //     console.log('mostRecentlyUsed')
    //     return filterPromise(removeDuplicates(mostRecentlyUsed, currentPersonas));
    // };
    //
    // const onRemoveSuggestion = (item: IPersonaProps): void => {
    //     const indexPeopleList: number = peopleList.indexOf(item);
    //     const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);
    //
    //     console.log('chieuse');
    //
    //     if (indexPeopleList >= 0) {
    //       const newPeople: IPersonaProps[] = peopleList
    //         .slice(0, indexPeopleList)
    //         .concat(peopleList.slice(indexPeopleList + 1));
    //       setPeopleList(newPeople);
    //     }
    //
    //     if (indexMostRecentlyUsed >= 0) {
    //         const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed.slice(0, indexMostRecentlyUsed).concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
    //         setMostRecentlyUsed(newSuggestedPeople);
    //     }
    // };
    //
    // const renderItemWithSecondaryText = (props: IPeoplePickerItemSelectedProps) => {
    //     console.log('renderItemWithSecondaryText');
    //     const newProps = {
    //         ...props,
    //         item: {
    //             ...props.item,
    //             ValidationState: ValidationState.valid,
    //             showSecondaryText: true,
    //         },
    //     };
    //
    //     return <PeoplePickerItem {...newProps} />;
    // };
    //
    // const validateInput = (input: string): ValidationState => {
    //     console.log('validateInput');
    //     if (input.indexOf('@') !== -1) {
    //         return ValidationState.valid;
    //     } else if (input.length > 1) {
    //         return ValidationState.warning;
    //     } else {
    //         return ValidationState.invalid;
    //     }
    // }
    //
    // const doesTextStartWith = (text: string, filterText: string): boolean => {
    //     console.log('cacahuete');
    //     return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
    // }
    //
    // const removeDuplicates = (personas: IPersonaProps[], possibleDupes: IPersonaProps[]) => {
    //     console.log('removeDuplicates');
    //     return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
    // }
    //
    // const listContainsPersona = (persona: IPersonaProps, personas: IPersonaProps[]) => {
    //     console.log('pirouette');
    //     if (!personas || !personas.length || personas.length === 0) {
    //         return false;
    //     }
    //
    //     return personas.filter(item => item.text === persona.text).length > 0;
    // }
    //
    // const convertResultsToPromise = (results: IPersonaProps[]): Promise<IPersonaProps[]> => {
    //     console.log('ocnvertResultsToPromise');
    //     return new Promise<IPersonaProps[]>((resolve, reject) => setTimeout(() => resolve(results), 2000));
    // }
    //
    // const getTextFromItem = (persona: IPersonaProps): string => {
    //     console.log('getTextFromItem');
    //     return persona.text as string;
    // }
    //
    // const onInputChange = (input: string): string => {
    //     setPeopleFilter(input);
    //
    //     return input;
    // }

    const options = [];
    let selectedValuesText = [];

    Object.keys(enums).sort((a,b) => enums[a].toString().localeCompare(enums[b].toString())).forEach(key => {
        options.push({
            key: `${key}`,
            text: `${lang.getSelectOption(itemKey, enums[key])}`
        });
        if ( state['content'] !== null && state['content'].indexOf(key) !== -1 ){
            selectedValuesText.push(lang.getSelectOption(itemKey, enums[key]))
        }
    });


    if ( readonly === false) {
        // let text = lang.getSelectOption(itemKey, enums[state['content']]);
        // if ( text.indexOf('_undefined') !== -1 ) {
        //     text = lang.getText('opt_undefined');
        // }
        content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={false}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />
            <div className='mt-1'>
                <ComboBox
                    id={itemKey}
                    key={itemKey}
                    selectedKey={state['content'] as string[]}
                    aria-labelledby={name}
                    allowFreeform={true}
                    autoComplete={'on'}
                    onChange={(e, option, index, val) => validateField(typeof(option) !== 'undefined' ? option['key'] : null)}
                    options={options}
                    useComboBoxAsMenuWidth={true}

                    // onRenderOption={(item: IComboBoxOption) => {
                    //     return <AauMixinPersona
                    //         className={`text-left`}
                    //         displayName={item['text']}
                    //         showText={true}
                    //         config={config}
                    //     />;
                    // }}
                    text={state['content'].length < 1 ? lang.getText('opt_undefined') : selectedValuesText.join(',   ')}
                />
                {/*<NormalPeoplePicker*/}
                {/*    onResolveSuggestions={onFilterChanged}*/}
                {/*    onEmptyInputFocus={returnMostRecentlyUsed}*/}
                {/*    getTextFromItem={getTextFromItem}*/}
                {/*    pickerSuggestionsProps={suggestionProps}*/}
                {/*    className={'ms-PeoplePicker'}*/}
                {/*    key={'normal'}*/}
                {/*    onRemoveSuggestion={onRemoveSuggestion}*/}
                {/*    onRenderItem={renderItemWithSecondaryText}*/}
                {/*    onValidateInput={validateInput}*/}
                {/*    selectionAriaLabel={'Selected contacts'}*/}
                {/*    removeButtonAriaLabel={'Remove'}*/}
                {/*    // inputProps={{*/}
                {/*    //     onBlur: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onBlur called'),*/}
                {/*    //     onFocus: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onFocus called'),*/}
                {/*    //     'aria-label': 'People Picker',*/}
                {/*    // }}*/}
                {/*    componentRef={picker}*/}
                {/*    onInputChange={onInputChange}*/}
                {/*    resolveDelay={300}*/}
                {/*    styles={{*/}
                {/*        root: {*/}
                {/*            '.ms-BasePicker-text': {*/}
                {/*                borderColor: 'black',*/}
                {/*                borderTop: 'none',*/}
                {/*                borderLeft: 'none',*/}
                {/*                borderRight: 'none'*/}
                {/*            }*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    itemLimit={1}*/}
                {/*    onChange={(items) => {*/}
                {/*        console.log('rara');*/}
                {/*        validateField( items.length > 0 ? items[0].key : null);*/}
                {/*    }}*/}
                {/*/>*/}
            </div>
        </div>;
    } else {
        content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={false}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />
            <div className={`view-value pt-5 fg-${fgColorL1} text-left`}>
                <AauMixinPersona
                    className={`text-left`}
                    displayName={isNaN(+value) === true ? value : enums[value]}
                    showText={true}
                    config={config}
                />
            </div>
        </div>;
    }

    return content;
};
AauFormPeoplePicker.defaultProps = defaultProps;
