/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import axios from 'axios';


export default class AauToolsConfiguration {
    config: object;
    updatedAt: string;

    async retrieve() {
        localStorage.setItem('config_updated_at', Date.now().toString());

        let config: object = {};
        await axios({
            method: 'get',
            url: '/customer.json',
            headers: {
                'accept': 'application/json'
            }
        }).then( response => {
            config = response.data as {
                [key: string]: string
            };
            localStorage.setItem('config', JSON.stringify(config));
        });
    }

    isLoad() {
        return this.config === null ? false : true;
    }

    load() {
        this.config = JSON.parse(localStorage.getItem('config'));
        this.updatedAt = localStorage.getItem('config_updated_at');

        const tsUpdatedAt:number = parseInt(this.updatedAt, 10);

        const cacheTime = 30000;

        let needRefresh = false;
        if ( this.updatedAt === null || typeof(this.updatedAt) === 'undefined' || Date.now() - tsUpdatedAt > cacheTime ) {
            needRefresh = true;
        }

        if ( this.config === null || typeof(this.config) === 'undefined' || needRefresh === true ) {
            this.retrieve();
        }

        return this.config;
    }

    get(propertyName):any {
        let ret = propertyName;

        if ( this.load().hasOwnProperty(propertyName) ) {
            ret = this.load()[propertyName];
        }

        return ret;
    }

    getGlobal(propertyName): string{
        let ret = propertyName;

        this.load();
        if ( this.config === null ) {
            window.location.reload();
        }

        if ( this.config['global'].hasOwnProperty(propertyName) ) {
            ret = this.config['global'][propertyName];
        }

        return ret;
    }

    getColor(propertyName, darkMode):string{
        let ret = `${darkMode === true ? 'dark' : 'light'}_${propertyName}`;

        if ( this.load()['color'][darkMode === true ? 'dark' : 'light'].hasOwnProperty(propertyName) ) {
            ret = this.load()['color'][darkMode === true ? 'dark' : 'light'][propertyName];
        }

        return ret;
    }

    //To help custom theme: https://docs.microsoft.com/en-us/javascript/api/react-toggle/itogglestyles?view=office-ui-fabric-react-latest
    getTheme(darkMode):object{
        const themePalette = this.load()['color'][darkMode === true ? 'dark' : 'light']['fluent_ui_palette']['palette'];

        return {
            palette: themePalette,
            components: {
                Checkbox:{
                    styles: {
                        checkmark:{
                            color: themePalette['neutralPrimary']
                        },
                        label: {
                            color: themePalette['neutralPrimary']
                        }
                    }
                },
                Toggle: {
                    styles: {
                        thumb:{
                            color: themePalette['neutralPrimary'],
                            backgroundColor: themePalette['neutralPrimary']
                        }
                    }
                },
                ComboBox: {
                    styles: {
                        optionsContainer:{
                            backgroundColor: themePalette['neutralTertiaryAlt'],
                        },
                        root: {
                            background: 'transparent',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            ':after': {
                                border: `${themePalette['neutralLight']} 1px solid`,
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderRight: 'none'
                            }
                        },
                        input: {
                            background: 'transparent'
                        }
                    }
                },
                SearchBox: {
                    styles: {
                        root: {
                            background: 'transparent',
                            border: `${themePalette['neutralLight']} 1px solid`,
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none'
                        }
                    }
                },
                DatePicker: {
                    styles: {
                        root: {
                            '.ms-TextField-fieldGroup': {
                                background: 'transparent',
                                border: `${themePalette['neutralLight']} 1px solid`,
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderRight: 'none',
                            }
                        },
                        icon : {
                            color: themePalette['themePrimary']
                        }
                    }
                }
            }
        };
    }

    getParticle(propertyName):any {
        let ret = `particle_${propertyName}`;

        if ( this.load()['particle'].hasOwnProperty(propertyName) ) {
            ret = this.load()['particle'][propertyName];
        }

        return ret;
    }

    getWidth(propertyName):number {
        let ret = 0;

        if ( this.load()['width'].hasOwnProperty(propertyName) ) {
            ret = this.load()['width'][propertyName];
        }

        return ret;
    }

    getDefaultValues(propertyName):number {
        let ret = null;

        if ( this.load()['default_values'].hasOwnProperty(propertyName) ) {
            ret = this.load()['default_values'][propertyName];
        }

        return ret;
    }
}
