/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {AauMixinPageHeader} from '../aauMixinPageHeader/aauMixinPageHeader';

import {AauMixinFabView, AauMixinFabBack} from '../aauMixinFab/aauMixinFabPredined';
import axios from 'axios';
import Cookies from 'js-cookie';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { IComboBoxOption } from '@fluentui/react';

import {AauMixinFilters} from '../aauMixinFilters/aauMixinFilters';
import {toast} from 'react-toastify';
import {AauPageRecordHistoryCard} from "./aauPageRecordHistoryCard";


export interface AauPageRecordHistoryProps {
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL2?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode: boolean;
}

const defaultProps = {
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauPageRecordHistoryProps;

export const AauPageRecordHistory: FC<AauPageRecordHistoryProps> = props => {
    const {lang, config, bgColorL2, fgColorL1} = props;
    const {module, identifier, admin} = useParams();
    const [data, setData] = useState(null);

    const [filterAction, setFilterAction] = useState<string>('ALL');
    const [filterCreatedBy, setFilterCreatedBy] = useState<string>('ALLUSER');

    const history = useNavigate();

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/history/${identifier}`,
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            if ( err.response.status === unauthorizedError ) {
                Cookies.remove('JWT');
                Cookies.remove('user');
                Cookies.remove('rank');
                Cookies.remove('displayname');
                window.location.href = `/login?redirect=${window.location.pathname}`;
            }
        });
    };

    const rollBackData = async rowdId => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/rollback/${rowdId}`,
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            if (res.data.success === true) {
                const msgToast = lang.getNotification('rollback', true);
                toast.success(msgToast.replace('%s', `${res.data.info.name} vers la version ${res.data.info.logid})`));
                history(`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}/view/${res.data.info.id}`);
            } else {
                const msgToast = lang.getNotification('rollback', false);
                toast.error(msgToast.replace('%s', `${res.data.info.name} vers la version ${res.data.info.logid})`));
            }
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    const optionsAction: IComboBoxOption[] = [{
        key: `ALL`,
        text: `${lang.getAction('ALL')}`
    }];
    const optionsCreatedBy: IComboBoxOption[] = [{
        key: `ALLUSER`,
        text: `${lang.getSelectOption('user-filter', 'ALLUSER')}`
    }];


    const dataArray = [];
    if ( data !== null && data !== false ) {
        const optionsActionArray = [];
        const optionsCreatedByArray = [];

        Object(data['info']).forEach(row => {
            if ((filterAction === 'ALL' || row['action'] === filterAction) && (filterCreatedBy === 'ALLUSER' || row['createdBy'] === filterCreatedBy)) {
                dataArray.push({
                    key: row['id'],
                    action: row['action'],
                    delta: row['delta'],
                    user: row['createdBy'],
                    ts: `${row['createdAt'].split('.')[0]}`
                });
            }

            if ( !optionsActionArray.includes(row['action']) ) {
                optionsActionArray.push(row['action']);
                optionsAction.push({
                    key: `${row['action']}`,
                    text: `${row['action']}`
                });
            }
            if ( !optionsCreatedByArray.includes(row['createdBy']) ) {
                optionsCreatedByArray.push(row['createdBy']);
                optionsCreatedBy.push({
                    key: `${row['createdBy']}`,
                    text: `${row['createdBy']}`
                });
            }
        })
                // if ( row['id'] === rowIndex ) {
                //     confirmAlert({
                //         title: `${config.getGlobal('sitename')}`,
                //         message: lang['formErrorFix'],
                //         customUI: ({ onClose }) => {
                //             const closeHandler = () => {
                //                 setRowIndex(null);
                //                 onClose();
                //             };
                //             return (
                //                 <div className={`confirm-ui width-px-1000 fit-content p-10 bg-l4-grey fg-l3-white`}>
                //                     <h1 className={'text-center'}>{config.getGlobal('sitename')} (id: {row['id']})</h1>
                //                     <h2 className={'text-center'}>{data['name']}</h2>
                //                     <Pivot aria-label='Basic Pivot Example'>
                //                         <PivotItem headerButtonProps={{style: {color: knowITTheme['palette']['neutralPrimary']}}} headerText='Différence entres les enregistrements'>
                //                             <div className={'height-px-600-min height-px-600-max overflow-auto'}>
                //                                 <JSONPretty
                //                                     className='width-100 mx-auto mb-5'
                //                                     data={row['delta']}
                //                                     theme={theme}
                //                                 />
                //                             </div>
                //                         </PivotItem>
                //                         <PivotItem headerButtonProps={{style: {color: knowITTheme['palette']['neutralPrimary']}}} headerText='Nouveau Enregistrement'>
                //                             <div className={'height-px-600-min height-px-600-max overflow-auto'}>
                //                                 <AauComponentForm
                //                                     columns={data['columns']}
                //                                     item={row['newitem']}
                //                                     readonly={true}
                //                                     lang={lang}
                //                                     template={data['template_form']}
                //                                     className={'width-80-max'}
                //                                     config={config}
                //                                     fgColorL1={fgColorL1}
                //                                 />
                //                             </div>
                //                         </PivotItem>
                //                         <PivotItem headerButtonProps={{style: {color: knowITTheme['palette']['neutralPrimary']}}} headerText='Ancien Enregistrement'>
                //                             <div className={'height-px-600-min height-px-600-max overflow-auto'}>
                //                                 {
                //                                     row['olditem'].hasOwnProperty('createdBy') ? <div
                //                                         className={'height-px-600-min height-px-600-max overflow-auto'}>
                //                                         <AauComponentForm
                //                                             columns={data['columns']}
                //                                             item={row['olditem']}
                //                                             readonly={true}
                //                                             lang={lang}
                //                                             template={data['template_form']}
                //                                             className={'width-80-max'}
                //                                             config={config}
                //                                             fgColorL1={fgColorL1}
                //                                         />
                //                                     </div> : null
                //                                 }
                //                             </div>
                //                         </PivotItem>
                //                         <PivotItem headerButtonProps={{style: {color: knowITTheme['palette']['neutralPrimary']}}} headerText='Comparaison Ancien'>
                //                             <div className={'height-px-600-min height-px-600-max overflow-auto'}>
                //                                 <ReactDiffViewer
                //                                     oldValue={JSON.stringify(row['olditem'], null, 2)}
                //                                     newValue={JSON.stringify(row['newitem'], null, 2)}
                //                                     splitView={true}
                //                                     showDiffOnly={false}
                //                                     compareMethod={DiffMethod.WORDS}
                //                                     extraLinesSurroundingDiff={0}
                //                                     rightTitle={'Nouveau'}
                //                                     leftTitle={'Ancien'}
                //                                     useDarkTheme={darkMode}
                //                                 />
                //                             </div>
                //                         </PivotItem>
                //                         <PivotItem headerButtonProps={{style: {color: knowITTheme['palette']['neutralPrimary']}}} headerText='Comparaison Actuel'>
                //                             <div className={'height-px-600-min height-px-600-max overflow-auto'}>
                //                                 <ReactDiffViewer
                //                                     oldValue={JSON.stringify(data['item'], null, 2)}
                //                                     newValue={JSON.stringify(row['newitem'], null, 2)}
                //                                     splitView={true}
                //                                     showDiffOnly={false}
                //                                     compareMethod={DiffMethod.WORDS}
                //                                     extraLinesSurroundingDiff={0}
                //                                     rightTitle={'Historique'}
                //                                     leftTitle={'Actuel'}
                //                                     useDarkTheme={darkMode}
                //                                 />
                //                             </div>
                //                         </PivotItem>
                //                     </Pivot>
                //                     <AauMixinIconButton
                //                          variant='righticontext'
                //                          size='tiny'
                //                          bgColor='l3-green-dark'
                //                          fgColor='l3-white'
                //                          icon=''
                //                          className={`width-${data['can_rollback'] ? 80 : 50} pos-relative left-${data['can_rollback'] ? 10 : 25} text-bold`}
                //                          classContainer={`width-${data['can_rollback'] ? 49 : 100} mt-3 float-left`}
                //                          mouseClickHandler={closeHandler}
                //                          type='div'
                //                          tooltip={lang.getBtn('close')}
                //                     />
                //                     {
                //                         data['can_rollback'] ? <AauMixinIconButton
                //                             variant='righticontext'
                //                             size='tiny'
                //                             bgColor='l3-purple'
                //                             fgColor='l3-white'
                //                             icon=''
                //                             className='width-80 pos-relative left-10 text-bold'
                //                             classContainer='width-49 mt-3 float-left'
                //                             mouseClickHandler={() => {
                //                                 confirmAlert({
                //                                     title: `${config.getGlobal('sitename')}`,
                //                                     message: lang['formErrorFix'],
                //                                     customUI: ({onClose}) => {
                //                                         const subcloseHandler = () => {
                //                                             setRowIndex(null);
                //                                             onClose();
                //                                         };
                //                                         const rollBackHandler = () => {
                //                                             rollBackData(row['id']);
                //                                             setRowIndex(null);
                //                                             onClose();
                //                                         };
                //                                         return (
                //                                             <div
                //                                                 className={`confirm-ui width-px-1000 fit-content p-10 bg-l4-grey fg-l3-white`}>
                //                                                 <h1 className={'text-center'}>{config.getGlobal('sitename')} (id: {row['id']})</h1>
                //                                                 <h2 className={'text-center'}>{data['name']}</h2>
                //                                                 <p>Confirmez vous le rollback de cette enregistrement ?</p>
                //                                                  <AauMixinIconButton
                //                                                     variant='righticontext'
                //                                                     size='tiny'
                //                                                     bgColor='l3-green-dark'
                //                                                     fgColor='l3-white'
                //                                                     icon='checkmark'
                //                                                     className='width-90 text-bold'
                //                                                     classContainer='mx-auto mt-8 width-50 float-left'
                //                                                     mouseClickHandler={rollBackHandler}
                //                                                     type='div'
                //                                                     tooltip={lang.getBtn('confirm')}
                //                                                 />
                //                                                 <AauMixinIconButton
                //                                                     variant='righticontext'
                //                                                     size='tiny'
                //                                                     bgColor='l4-red'
                //                                                     fgColor='l3-white'
                //                                                     icon='checkmark'
                //                                                     className='width-95 float-right text-bold'
                //                                                     classContainer='mx-auto mt-8 width-50 float-left'
                //                                                     mouseClickHandler={subcloseHandler}
                //                                                     type='div'
                //                                                     tooltip={lang.getBtn('cancel')}
                //                                                 />
                //                                                 <div className='float-clear'></div>
                //                                             </div>
                //                                         );
                //                                     }
                //                                 });
                //                             }}
                //                             type='div'
                //                             tooltip={lang.getBtn('rollback')}
                //                         /> : null
                //                     }
                //                     <div className='float-clear'></div>
                //                 </div>
                //             );
                //         }
                //     });
                // }
        //     }
        // });

        // content = ;
        //
        // filterContent = ;
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }


    return (
        <div className='width-vw-99 height-vh-95-min'>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
                config={config}
                bgColorL2={bgColorL2}
                fgColorL1={fgColorL1}
            />
            <div className='pos-absolute top-px-87'>
                <AauMixinFilters
                    lang={lang}
                    filters={[
                        {component: 'select', name: 'action', 'width': 200, 'options': optionsAction, changeHandler: (option, value) => {
                            if (typeof (option) !== 'undefined' ) {
                                setFilterAction(option.toString());
                            }
                        }, value: `${lang.getAction(filterAction)}`},
                        {component: 'select', name: 'by', 'width': 200, 'options': optionsCreatedBy, changeHandler: (option, value) => {
                            if (typeof (option) !== 'undefined' ) {
                                setFilterCreatedBy(option.toString());
                            }
                        }, value: `${lang.getSelectOption('user-filter', filterCreatedBy)}`}
                    ]}
                />
            </div>
            <div className='p-5 pos-absolute width-92 top-px-150'>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{600: 1, 1200: 2, 1800: 3, 2400:4, 3000:5}}
                >
                    <Masonry gutter={20}>
                        {
                            dataArray.map((item: { key: string | number }) => (
                                <AauPageRecordHistoryCard
                                    key={item['id']}
                                    id={item['id']}
                                    user={item['user']}
                                    action={item['action']}
                                    delta={item['delta']}
                                    timestamp={item['ts']}
                                    config={config}
                                />
                            ))
                        }
                    </Masonry>
                </ResponsiveMasonry>
            </div>
            {
                data !== null && data !== false && data['can_view'] ?
                <AauMixinFabView lang={lang} config={config} url={`/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/view/${identifier}`} /> :
                null
            }
            <AauMixinFabBack lang={lang} config={config} url={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`} />
        </div>
    );
};
AauPageRecordHistory.defaultProps = defaultProps;
