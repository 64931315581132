/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';
import {AauMixinIcon} from '../aauMixinIcon/aauMixinIcon';
import {useBoolean} from "@fluentui/react-hooks";

import {Callout} from '@fluentui/react/lib/Callout';
import {Stack} from '@fluentui/react/lib/Stack';


export interface AauFormLabelProps {
    key: string;
    readonly: boolean;
    name: string;
    value: string;
    helper?: string;
    isValid: boolean;
    required: boolean;
    className?: string;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    value: null,
    readonly: false,
    isValid: true,
    helper: null,
    className: '',
    fgColorL1: 'l3-white' as aauToolsColorEnum,
} as AauFormLabelProps;

export const AauFormLabel: FC<AauFormLabelProps> = props => {
    const {readonly, name, value, isValid, required, className, fgColorL1, helper} = props;

    const [displayHelper, { toggle: toggleDisplayHelper}] = useBoolean(false); //setDisplayHelper] = useState(false);

    let content;

    if ( readonly === false) {
        content = <label className={`text-bold ${className === '' ? `fg-${fgColorL1}` : className}`} htmlFor={name}>
            <AauMixinIcon
                size={'inline'}
                icon={isValid === true ? null : 'cross'}
                color={isValid === true ? 'l4-green' : 'l4-red'}
                className='pr-1'
            />
            {value !== null ? value : name}
            {required === true? <span className='fg-l3-red'>&nbsp;&nbsp;*</span> : null}
            {
                !['', null].includes(helper) && <AauMixinIcon
                    id={`helper_button_${name}`}
                    size={'inline'}
                    icon={null}
                    iconazure={'Info'}
                    color={fgColorL1}
                    className='pr-1 ml-2'
                    mouseClickHandler={toggleDisplayHelper}
                />
            }
            {
                !['', null].includes(helper) && displayHelper && <Callout
                        target={`#helper_button_${name}`}
                        onDismiss={toggleDisplayHelper}
                        className={`bg-l3-white`}
                    >
                    <Stack horizontal verticalAlign="center" horizontalAlign="start" className={`p-3 bg-l3-white`}>
                        <div dangerouslySetInnerHTML={{__html: helper }}></div>
                    </Stack>
                </Callout>
            }
        </label>;
    } else {
        content = <label className={`text-bold ${className === '' ? `fg-${fgColorL1}` : className}`} htmlFor={name}>
            {value !== null ? value : name}
            {required === true? <span className='fg-l3-red'>&nbsp;&nbsp;*</span> : null}
        </label>;
    }

        return content;
};
AauFormLabel.defaultProps = defaultProps;
