/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC, useState} from 'react';
import axios from 'axios';

import Cookies from 'js-cookie';

import {AauMixinIconButton} from '../aauMixinIconButton/aauMixinIconButton';
import {aauSize} from '../aauMixinIcon/aauMixinIcon';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {confirmAlert} from 'react-confirm-alert';
import {toast} from 'react-toastify';
import format from 'string-format';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';


export interface AauMixinBtnGroupProps {
    btns: object;
    dataJson: object;
    size?: aauSize;
    align?: string;
    refreshTable?: () => void;
    columnid: string;
    rankField: string;
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    darkMode?: boolean;
    bgColorL1?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    optionalMode?: boolean;
}

const defaultProps = {
    size: 'tiny',
    align: 'left',
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    darkMode: false,
    optionalMode: true
} as AauMixinBtnGroupProps;

export const AauMixinBtnGroup: FC<AauMixinBtnGroupProps> = props => {
    const { btns, dataJson, size, align, refreshTable, columnid, lang, config, bgColorL1, fgColorL1, darkMode,
            optionalMode } = props;

    const [state, setState] = useState<boolean>(false);

    const btnsOptional = [];
    const btnsMandatory = [];

    Object.keys(btns).forEach((btnKey, key) => {
        const btn = btns[btnKey];

        const optional = btn['optional'];
        const c = btn['component'];

        const dk:string = darkMode === true ? 'dark' : 'light';
        const co:object = c.hasOwnProperty(dk) ? c[dk] : {};

        const btnActionName:string = btnKey;
        const confirm:boolean = c.hasOwnProperty('confirm') ? c.confirm : false;
        const displayRankField = c.hasOwnProperty('rank_record_field') ? c.rank_record_field : {};
        const displayRankFilters = c.hasOwnProperty('rank_filters') ? c.rank_filters : {};
        const displayState = c.hasOwnProperty('displayState') ? c.displayState : null;
        const displayField = c.hasOwnProperty('displayField') ? c.displayField : null;
        const displayFieldValue = c.hasOwnProperty('displayFieldValue') ? c.displayFieldValue : [];
        const formatStr = c.hasOwnProperty('formatStr') ? c.formatStr : '#';

        const admin = window.location.pathname.split('/')[1] === 'admin' ? 'admin/' : '';
        const module = window.location.href.split('/').reverse()[0];
        const url =  `/${admin}${module}/${btnActionName}/${dataJson[columnid]}`;

        let classContainer = 'm-0 float-left';
        let className = '';

        let variant = c.hasOwnProperty('variant') ? c['variant'] : 'outlined';
        if ( optional ) {
            variant = 'nooutlined_righticontext';
            classContainer = 'm-0';

            className = 'width-px-250 m-0 p-0 cursor-pointer';
        }

        let display = true;
        if (displayState !== null && dataJson.hasOwnProperty('state') && !displayState.includes(dataJson['state'])) {
            display = false;
        }

        if (display !== false && displayField !== null && dataJson.hasOwnProperty(displayField) && !displayFieldValue.includes(dataJson[displayField])) {
            display = false;
        }

        // console.log('-----------');
        // console.log(display);
        // console.log(displayRankField);
        // console.log(displayRankField !== null);
        // console.log(dataJson.hasOwnProperty(displayRankField));
        // console.log(dataJson[displayRankField]);
        // console.log(displayRankFilters.hasOwnProperty(dataJson[displayRankField]));
        // console.log(displayState);
        // console.log(dataJson.hasOwnProperty('state'));
        // console.log(!displayState.includes(dataJson['state']));
        // console.log('-----------');

        if (display !== false && displayRankField !== null
            && dataJson.hasOwnProperty(displayRankField)
            && ((
                    displayRankFilters.hasOwnProperty(dataJson[displayRankField])
                    && displayRankFilters[dataJson[displayRankField]] === false
                ) || !displayRankFilters.hasOwnProperty(dataJson[displayRankField]))
        ) {
            display = false;
        }

        let ret = null;
        if ( display === true ) {
            if ( btn['mixin'] === 'btn_link_with_url' ) {
                ret = <AauMixinIconButton
                        key={`${btn['name']}_${dataJson[columnid]}`}
                        size={size}
                        icon={c.hasOwnProperty('icon') ? c['icon'] : 'windows'}
                        iconazure={c.hasOwnProperty('iconazure') ? c['iconazure'] : ''}
                        variant={optionalMode?variant:'onlyicon'}
                        bgColor={co.hasOwnProperty('bg_color') ? co['bg_color'] : 'l3-grey'}
                        fgColor={co.hasOwnProperty('fg_color') ? co['fg_color'] : 'l3-white'}
                        tooltip={btn['label']}
                        type={'a'}
                        link={format(formatStr, dataJson)}
                        className={className}
                        classContainer={classContainer}
                    />;
            } else {
                if (confirm === true) {
                    ret = <AauMixinIconButton
                        key={`${btn['name']}_${dataJson[columnid]}`}
                        size={size}
                        icon={c.hasOwnProperty('icon') ? c['icon'] : 'windows'}
                        iconazure={c.hasOwnProperty('iconazure') ? c['iconazure'] : ''}
                        variant={optionalMode?variant:'onlyicon'}
                        bgColor={co.hasOwnProperty('bg_color') ? co['bg_color'] : 'l3-grey'}
                        fgColor={co.hasOwnProperty('fg_color') ? co['fg_color'] : 'l3-white'}
                        tooltip={btn['label']}
                        type={btn['mixin'] === 'btn_link_page_id' ? 'link' : 'div'}
                        className={className}
                        classContainer={classContainer}
                        mouseClickHandler={e => {
                            confirmAlert({
                                title: `${config.getGlobal('sitename')}`,
                                message: lang['formErrorFix'],
                                customUI: ({onClose}) => {
                                    const closeHandler = () => {
                                        onClose();
                                        axios.get(
                                            `/rest${url}`,
                                            {
                                                baseURL: `${config.getGlobal('back')}`,
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                                                }
                                            }
                                        ).then(res => {
                                            const msgToast = res.data.action_return;
                                            if (res.data.success === true) {
                                                toast.success(msgToast.replace('%s', res.data.name));
                                            } else {
                                                toast.error(msgToast.replace('%s', res.data.name));
                                            }
                                            refreshTable();
                                        }).catch(err => {
                                            onClose();
                                            const unauthorizedError = 401;
                                            if (err.response.data['code'] === unauthorizedError) {
                                                Cookies.remove('JWT');
                                                Cookies.remove('user');
                                                Cookies.remove('rank');
                                                Cookies.remove('displayname');
                                                window.location.href = `/login?redirect=${window.location.pathname}`;
                                            } else {
                                                const msgToast = lang.getError(err.response.data['code']);
                                                toast.error(msgToast.replace('%s', ''));
                                            }
                                        });
                                    };

                                    const itemIdentifier = dataJson.hasOwnProperty('name') ? dataJson['name'] : '';
                                    const msgDialog = `${btn['dialog']} de ${itemIdentifier} (${dataJson[columnid]})`;

                                    return <div className={`confirm-ui fit-content width-px-400-min p-10 bg-l4-grey fg-l3-white`}>
                                        <h1 className={'text-center'}>{config.getGlobal('sitename')}</h1>
                                        <p className={'text-center'}>{msgDialog}</p>
                                        <p>{lang.getText('dialog_confirm_text')}</p>
                                        <AauMixinIconButton
                                            variant='righticontext'
                                            size='tiny'
                                            bgColor='l3-green-dark'
                                            fgColor='l3-white'
                                            icon='checkmark'
                                            className='width-95 text-bold'
                                            classContainer='mx-auto mt-8 width-50 float-left'
                                            mouseClickHandler={closeHandler}
                                            type='div'
                                            tooltip={lang.getBtn('confirm')}
                                        />
                                        <AauMixinIconButton
                                            variant='righticontext'
                                            size='tiny'
                                            bgColor='l4-red'
                                            fgColor='l3-white'
                                            icon='checkmark'
                                            className='width-95 float-right text-bold'
                                            classContainer='mx-auto mt-8 width-50 float-left'
                                            mouseClickHandler={onClose}
                                            type='div'
                                            tooltip={lang.getBtn('cancel')}
                                        />
                                        <div className='float-clear'></div>
                                    </div>;
                                }
                            });
                        }}
                    />;
                } else if (display === true && confirm === false) {
                    ret = <AauMixinIconButton
                        key={`${btn['name']}_${dataJson[columnid]}`}
                        size={size}
                        icon={c.hasOwnProperty('icon') ? c['icon'] : 'windows'}
                        iconazure={c.hasOwnProperty('iconazure') ? c['iconazure'] : ''}
                        variant={optionalMode?variant:'onlyicon'}
                        bgColor={co.hasOwnProperty('bg_color') ? co['bg_color'] : 'l3-grey'}
                        fgColor={co.hasOwnProperty('fg_color') ? co['fg_color'] : 'l3-white'}
                        type={btn['mixin'] === 'btn_link_page_id' ? 'link' : 'div'}
                        link={url}
                        className={className}
                        classContainer={classContainer}
                        tooltip={btn['label']}
                    />;
                } else {
                    //NOSONAR
                }
            }
        }

        if ( ret !== null ) {
            if (optionalMode && optional) {
                btnsOptional.push(ret);
            } else {
                btnsMandatory.push(ret);
            }
        }
    });

    const showMoreContent = <div className={`float-left`}>
        <AauMixinIconButton
            key={`btn_showmore_${dataJson[columnid]}`}
            size={size}
            iconazure='More'
            variant={'onlyicon'}
            fgColor={fgColorL1}
            type='div'
            classContainer='mb-0 float-left'
            tooltip={lang.getBtn('showmoreaction')}
            mouseClickHandler={e => setState(true)}
            mouseLeaveHandler={e => setState(false)}
        />
        {
            state ? <div
                key={`btn_showmore_${dataJson[columnid]}_list`}
                className={`pos-absolute width-px-250 bg-${bgColorL1} fg-${fgColorL1} bd-2 bd-${fgColorL1} z-100 right-px-20 p-2`}
                onMouseEnter={e => setState(true)}
                onMouseLeave={e => setState(false)}
            >
                {
                    btnsOptional.map(btn => btn)
                }
            </div> : null
        }
    </div>;

    return <div className={`pos-relative fit-content float-${align} m${align === 'left' ? 'l' : 'r'}-2`}>
        {
            btnsMandatory.map(btn => btn)
        }
        {btnsOptional.length > 0 ? showMoreContent : null}
    </div>;
};
AauMixinBtnGroup.defaultProps = defaultProps;
