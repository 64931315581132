
/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';
import {AauMixinPageHeader} from "../aauMixinPageHeader/aauMixinPageHeader";


export interface AauPageNotFoundProps {
    config: AauToolsConfiguration;
    bgColorL2?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
} as AauPageNotFoundProps;

export const AauPageNotFound: FC<AauPageNotFoundProps> = props => {
    const {config, bgColorL2, fgColorL1} = props;

    return (
        <div className='width-vw-99 height-95-min'>
            <AauMixinPageHeader
                pageTitle={"NofFound"}
                pageHelp={"NotFound"}
                config={config}
                bgColorL2={bgColorL2}
                fgColorL1={fgColorL1}
            />
            <div className='p-5 pl-7 pos-absolute width-95 height-vh-90 top-px-87'>
                <div className={`width-px-600 mx-auto text-center`}>
                    <img alt='Forbidden Logo' className={`mt-10 width-px-600 height-px-600`} src={config.getGlobal('sitenotfoundurl')} />
                </div>
            </div>
        </div>
    );
};
AauPageNotFound.defaultProps = defaultProps;
