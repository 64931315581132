/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

//TODO (vincent.candeau): Manage List key item

import React, {useState, FC} from 'react';

import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauFormLabel} from '../aauFormLabel/aauFormLabel';

import { Slider } from '@fluentui/react';
import {TextField} from "@fluentui/react/lib/TextField";


export interface AauFormSliderProps {
    readonly: boolean;
    itemKey: string;
    key: string;
    name: string;
    label: string;
    value: number;
    helper?: string | null;
    validator: object;
    parentFunction?: any;
    step?: number;
    min?: number;
    max?: number;
    required?: boolean | null;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    label: null,
    readonly: false,
    validator: {
        'required': true
    },
    parentFunction: null,
    value: 0,
    helper: null,
    step: 1,
    min: 1,
    max: 1,
    required: false,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauFormSliderProps;

export const AauFormSlider: FC<AauFormSliderProps> = props => {
    const validateField = (validateValue):void => {
        let isValid = true;

        if (required === true && (validateValue === '' || validateValue === null)) {
            isValid = false;
        }

        if (validateValue < min) {
            isValid = false
        } else if (validateValue > max) {
            isValid = false
        } else if ( validateValue % step !== 0 ) {
            validateValue = Math.round(validateValue / step) * step;
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( ((state['isValid'] === null && isValid === false) || state['isValid'] !== null) && parentFunction !== null ) {
                parentFunction(itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                'content': validateValue
            });
        }
    };


    let content;
    const {readonly, itemKey, name, value, parentFunction, helper, step, min, max, required, fgColorL1, label} = props;

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': value
    });
    if (state['isValid'] === null && readonly === false ) {
        validateField(value);
    }

    if ( readonly === false) {
        content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={false}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />
            <Slider
                id={itemKey}
                key={`${itemKey}-ctrl`}
                aria-labelledby={name}
                onChange={val => validateField(Number(val))}
                value={state['content']}
                showValue={true}
                snapToStep={false}
                step={step}
                min={min}
                max={max}
            />
            <TextField
                defaultValue={state['content']}
                value={state['content']}
                borderless={true}
                className={`pos-absolute right-0 bottom-26 width-px-65 p-0 bd-0 ${fgColorL1}`}
                onChange={(e, val) => validateField(Number(val))}
            />
        </div>;
    } else {
       content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={true}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />
            <div className='view-value pt-5'>
                <Slider
                    id={itemKey}
                    key={`${itemKey}-ctrl`}
                    aria-labelledby={name}
                    value={state['content']}
                    showValue={true}
                    snapToStep={true}
                    step={step}
                    min={min}
                    max={max}
                />
            </div>
        </div>;
    }

    return content;
};
AauFormSlider.defaultProps = defaultProps;
