/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauFormLabel} from '../aauFormLabel/aauFormLabel';

import { Toggle } from '@fluentui/react/lib/Toggle';


export interface AauFormBooleanProps {
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    label: string;
    value?: true | false | 1 | 0;
    validator?: object;
    parentFunction?: any;
    helper?: string | null;
    required?: boolean | null;
    lang: AauToolsLang;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    label: null,
    readonly: false,
    value: true,
    validator: {
        'required': true
    },
    helper: null,
    parentFunction: null,
    required: false,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauFormBooleanProps;

export const AauFormBoolean: FC<AauFormBooleanProps> = props => {
    const validateField = (validateValue):void => {
        const isValid = true;

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( parentFunction !== null ) {
                parentFunction(itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                content: validateValue
            });
        }
    };

    const {readonly, itemKey, name, value, parentFunction, helper, required, lang, fgColorL1, label} = props;

    const valDict = {
        'int': {
            'false': 0,
            'true': 1
        },
        'bool': {
            'false': false,
            'true': true
        }
    };

    const valType = typeof(value) === 'number' ? 'int' : 'bool';

    const labelTrue = lang.getBool(name, 'true');
    const labelFalse = lang.getBool(name, 'false');

    const [state, setState] = useState<object>({
        isValid: true,
        content: value
    });
    if (state['isValid'] === null && readonly === false ) {
        validateField(value);
    }

    return <div className='mb-1 width-100 clear-both' key={`${name}-formcontrol`}>
         <div className='width-100 pos-relative'>
            <div>
                <AauFormLabel
                    key={`${name}-label`}
                    isValid={state['isValid']}
                    name={name}
                    value={label}
                    readonly={false}
                    required={required}
                    className={`fg-${fgColorL1}`}
                    fgColorL1={fgColorL1}
                    helper={helper}
                />
                {
                    readonly === false ?
                    <Toggle
                        id={itemKey}
                        key={`${itemKey}-ctrl`}
                        aria-labelledby={name}
                        checked={state['content'] === 1 || state['content'] === true}
                        offText={labelFalse}
                        onText={labelTrue}
                        role='switch'
                        onChange={() => { validateField(state['content'] === valDict[valType]['false'] ? valDict[valType]['true'] : valDict[valType]['false']); }}
                    /> :
                    <Toggle
                        id={itemKey}
                        key={`${itemKey}-ctrl`}
                        aria-labelledby={name}
                        checked={state['content'] === 1 || state['content'] === true}
                        offText={labelFalse}
                        onText={labelTrue}
                        role='switch'
                    />
                }
            </div>
        </div>
    </div>;
};
AauFormBoolean.defaultProps = defaultProps;
