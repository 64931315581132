/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {ComboBox, IComboBoxOption} from '@fluentui/react';
import {SearchBox} from '@fluentui/react/lib/SearchBox';
import {Toggle} from '@fluentui/react/lib/Toggle';
import {aauToolsColorEnum} from "../aauToolsColor/aauToolsColor";
import {AauMixinPageHeader, AauMixinPageHeaderProps} from "../aauMixinPageHeader/aauMixinPageHeader";


export interface AauMixinBadgeVersionProps {
    className?: string;
    text: string;
    state: string;
}

const defaultProps = {
    className: 'font-10 p-1',
    text: null,
    state: null
} as AauMixinBadgeVersionProps;


export const AauMixinBadgeVersion: FC<AauMixinBadgeVersionProps> = props => {
    const { text, state, className} = props;

    let bgcolor = 'l4-grey-light';
    if ( state === 'old' ) {
        bgcolor = 'l3-red-dark';
    } else if ( state === 'new' ) {
        bgcolor = 'l3-green-dark';
    } else if ( ['dev', 'wip'].includes(state) ) {
        bgcolor = 'l3-blue';
    }

    return <span key='module_version' className={`${className} bd-radius-3 bg-${bgcolor}`}>
        {text}
    </span>;
};
AauMixinBadgeVersion.defaultProps = defaultProps;