/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauFormLabel} from '../aauFormLabel/aauFormLabel';
import {TextField} from '@fluentui/react/lib/TextField';
import nextId from 'react-id-generator';

export interface AauFormInputProps {
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    label: string;
    value?: string | number;
    parentFunction?: any;
    helper?: string | null;
    prefix?: string | null;
    suffix?: string | null;
    isInteger?: boolean;
    required?: boolean | null;
    pattern?: string | null;
    min?: number | null;
    max?: number | null;
    type?: string;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    readonly: false,
    parentFunction: null,
    value: '',
    label: null,
    helper: null,
    prefix: null,
    suffix: null,
    isInteger: false,
    required: false,
    pattern: null,
    min: null,
    max: null,
    type: 'text',
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauFormInputProps;

export const AauFormInput: FC<AauFormInputProps> = props => {
    const validateField = (validateValue):void => {
        let isValid = true;

        if (required === true && (validateValue === '' || validateValue === null)) {
            isValid = false;
        }

        if ( pattern !== null && (validateValue !== null && validateValue !== '') ) {
            let reg = pattern;
            if ( reg === 'url' ) {
                // Line by Line : Protocol, Domaine Name, or IPV4 addresse, Port and Path, query string
                reg = '^(http[s]{0,1}?:\\/\\/)?'+
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+
                    '(\\#[-a-z\\d_]*)?$';
            } else if ( reg === 'ipv4' ) {
                reg = '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$';
            } else if ( reg === 'ipv4-cidr' ) {
                reg = '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\\/(?:[0-9]|[0-9][0-9]:?)?){0,1}$';
            } else if ( reg === 'cidr' ) {
                reg = '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\\/(?:[0-9]|[0-9][0-9]:?)?){1}$';
            } else if ( reg === 'ipv6' ) {
                reg = '^(([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))$';
            } else {
                //NOSONAR
            }


            isValid = RegExp(reg, 'g').test(validateValue);
        }

        if ( min !== null && validateValue < min ) {
            isValid = false;
        }

        if ( max !== null && validateValue > max ) {
            isValid = false;
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( parentFunction !== null ) {
                parentFunction(itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                'content': validateValue
            });
        }
    };

    const {readonly, itemKey, name, value, parentFunction, helper, prefix, suffix, isInteger, required, pattern, min,
        max, type, fgColorL1, label} = props;

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': isInteger === true ? Number(value) : value
    });
    if (state['isValid'] === null && readonly === false ) {
        validateField((isInteger === true ? Number(value) : value));
    }

    const cssClassName = `bd-1 bd-l3-black bd-top-none bd-left-none bd-right-none`;
    let content = null;
    if ( prefix !== null && suffix !== null ) {
        content = <TextField
            id={itemKey}
            key={`${itemKey}-ctrl`}
            name={name}
            aria-labelledby={name}
            defaultValue={state['content']}
            //type={type}
            onChange={ (e, val) => validateField((isInteger === true ? Number(val) : val))}
            prefix={prefix !== null ? prefix : null}
            suffix={suffix !== null ? suffix : null}
            inputClassName={cssClassName}
            type={type}
            canRevealPassword={true}
            borderless
        />;
    } else if ( prefix !== null ) {
        content = <TextField
            id={itemKey}
            key={`${itemKey}-ctrl`}
            name={name}
            aria-labelledby={name}
            defaultValue={state['content']}
            //type={type}
            onChange={ (e, val) => validateField((isInteger === true ? Number(val) : val))}
            prefix={prefix !== null ? prefix : null}
            inputClassName={cssClassName}
            type={type}
            canRevealPassword={true}
            borderless
        />;
    } else if ( suffix !== null ) {
        content = <TextField
            id={itemKey}
            key={`${itemKey}-ctrl`}
            name={name}
            aria-labelledby={name}
            defaultValue={state['content']}
            //type={type}
            onChange={ (e, val) => validateField((isInteger === true ? Number(val) : val))}
            suffix={suffix !== null ? suffix : null}
            inputClassName={cssClassName}
            type={type}
            canRevealPassword={true}
            borderless
        />;
    } else {
        content = <TextField
            id={itemKey}
            key={ itemKey.indexOf('|') !== -1 ? nextId() : `${itemKey}-ctrl`}
            name={name}
            aria-labelledby={name}
            defaultValue={state['content']}
            //type={type}
            onChange={ (e, val) => validateField((isInteger === true ? Number(val) : val))}
            inputClassName={cssClassName}
            type={type}
            canRevealPassword={true}
            borderless
        />;
    }

    if ( readonly === false) {
        content = <div className='mb-5 width-100'>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={false}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />
            <div className='mt-1'>
                {content}
            </div>
        </div>;
    } else {
       content = <div className='mb-5 width-100 view-block-input'>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={true}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />
            <div className={`view-value pt-5 fg-${fgColorL1}`}>
                {prefix !== null ? `${prefix} ` : ''}
                {state['content']}
                {suffix !== null ? ` ${suffix}` : ''}
            </div>
        </div>;
    }

    return content;
};
AauFormInput.defaultProps = defaultProps;
