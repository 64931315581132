/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauFormLabel} from '../aauFormLabel/aauFormLabel';
import {AauFormHelper} from '../aauFormHelper/aauFormHelper';

import {AauDynamicAccordionItem} from './aauDynamicAccordionItem';
import {AauMixinIconButton} from '../aauMixinIconButton/aauMixinIconButton';
import nextId from 'react-id-generator';


export interface AauDynamicAccordionProps {
    readonly?: boolean;
    itemKey: string;
    name: string;
    columns: object[];
    value: object[];
    label: string;
    helper?: string | null;
    parentFunction?: any; // NOSONAR
    required?: boolean;
    substate?: boolean;
    titleStr?:  string;
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    label: null,
    readonly: false,
    columns: [],
    value: [],
    required: false,
    parentFunction: null,
    helper: null,
    substate: false,
    titleStr: '',
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauDynamicAccordionProps;

export const AauDynamicAccordion: FC<AauDynamicAccordionProps> = props => {

    const numeric2 = 2;

    const btnSize = 'tinyiest';

    const subStateClick = (index:string):void => {
        const stateTmp = JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')));
        stateTmp[index]['substate'] = stateTmp[index]['substate'] === 0 ? 1 : 0;
        setState({
            isValid: state['isValid'],
            content: stateTmp,
            expanded: state['expanded'],
            componentId: nextId()
        });
        if ( parentFunction !== null ) {
            const ret = {};
            ret[itemKey] = stateTmp;
            parentFunction(itemKey, ret, state['isValid'].filter(o => o !== true).length < 1 );
        }
    };

    const subDeleteClick = (index:string):void => {
        const stateTmp = JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')));
        const validTmp = JSON.parse(JSON.stringify(state['isValid'].filter(o => typeof (o) !== 'undefined')));

        setState({
            isValid: validTmp.filter(o => o !== validTmp[index]),
            content: stateTmp.filter(o => o !== stateTmp[index]),
            expanded: state['expanded'],
            componentId: nextId()
        });
        if ( parentFunction !== null ) {
            const ret = {};
            ret[itemKey] = stateTmp.filter(o => o !== stateTmp[index]);
            parentFunction(itemKey, ret, state['isValid'].filter(o => o !== true).length < 1 );
        }
    };

    const subCopyClick = (index:string, item:object):void => {
        const stateTmp = JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')));
        const validTmp = JSON.parse(JSON.stringify(state['isValid'].filter(o => typeof (o) !== 'undefined')));

        item['content']['substate'] = 0;
        stateTmp.push(item['content']);
        validTmp.push(validTmp[index]);

        setState({
            isValid: validTmp,
            content: stateTmp,
            expanded: state['expanded'],
            componentId: nextId()
        });

        if ( parentFunction !== null ) {
            const ret = {};
            ret[itemKey] = stateTmp;
            parentFunction(itemKey, ret, state['isValid'].filter(o => o !== true).length < 1 );
        }
    };

    const subModeDownClick = (index:string):void => {
        const j = JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')));
        const jv = JSON.parse(JSON.stringify(state['isValid'].filter(o => typeof (o) !== 'undefined')));

        let stateTmp = [];
        let validTmp = [];
        if ( Number(index) === 0 ) {
            stateTmp.push(j[1]);
            stateTmp.push(j[0]);
            stateTmp = stateTmp.concat(j.slice(numeric2));

            validTmp.push(jv[1]);
            validTmp.push(jv[0]);
            validTmp = validTmp.concat(jv.slice(numeric2));
        } else {
            stateTmp = j.slice(0, Number(index));
            stateTmp.push(j[Number(index)+1]);
            stateTmp.push(j[Number(index)]);
            stateTmp = stateTmp.concat(j.slice(Number(index)+numeric2));

            validTmp = jv.slice(0, Number(index));
            validTmp.push(jv[Number(index)+1]);
            validTmp.push(jv[Number(index)]);
            validTmp = validTmp.concat(jv.slice(Number(index)+numeric2));
        }

        setState({
            isValid: validTmp,
            content: stateTmp,
            expanded: state['expanded'],
            componentId: nextId()
        });
        if ( parentFunction !== null ) {
            const ret = {};
            ret[itemKey] = stateTmp;
            parentFunction(itemKey, ret, state['isValid'].filter(o => o !== true).length < 1 );
        }
    };

    const subModeUpClick = (index:string):void => {
        const j = JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')));
        const jv = JSON.parse(JSON.stringify(state['isValid'].filter(o => typeof (o) !== 'undefined')));

        let stateTmp = [];
        let validTmp = [];
        if ( Number(index) === j.length-1 ) {
            stateTmp = stateTmp.concat(j.slice(0, j.length-numeric2));
            stateTmp.push(j[j.length-1]);
            stateTmp.push(j[j.length-numeric2]);

            validTmp = validTmp.concat(jv.slice(0, jv.length-numeric2));
            validTmp.push(jv[jv.length-1]);
            validTmp.push(jv[jv.length-numeric2]);
        } else {
            stateTmp = j.slice(0, Number(index)-1);
            stateTmp.push(j[Number(index)]);
            stateTmp.push(j[Number(index)-1]);
            stateTmp = stateTmp.concat(j.slice(Number(index)+1));

            validTmp = jv.slice(0, Number(index)-1);
            validTmp.push(jv[Number(index)]);
            validTmp.push(jv[Number(index)-1]);
            validTmp = validTmp.concat(jv.slice(Number(index)+1));
        }
        setState({
            isValid: validTmp,
            content: stateTmp,
            expanded: state['expanded'],
            componentId: nextId()
        });
        if ( parentFunction !== null ) {
            const ret = {};
            ret[itemKey] = stateTmp;
            parentFunction(itemKey, ret, state['isValid'].filter(o => o !== true).length < 1 );
        }
    };

    const subSetData = (index: number, item: object, valid: boolean): void => {
        const stateTmp = JSON.parse(JSON.stringify(state['content']));
        const validTmp = JSON.parse(JSON.stringify(state['isValid']));

        stateTmp[index] = item;
        validTmp[index] = valid;

        if ( (state['isValid'][index] === true && valid === false) || JSON.stringify(state['content'][index]) !== JSON.stringify(item) ) {
            if ( parentFunction !== null ) {
                const ret = {};
                ret[itemKey] = stateTmp;
                parentFunction(itemKey, ret, validTmp.filter(o => o !== true).length < 1 );
            }
            setState({
                isValid: validTmp,
                content: stateTmp,
                expanded: state['expanded'],
                componentId: state['componentId']
            });
        }
    };

    const subExpandedClick = (index:string):void => {
        let expandedTmp:string[] = state['expanded'];

        if ( expandedTmp.includes(index) ) {
            expandedTmp = expandedTmp.filter(i => i !== index);
        } else {
            expandedTmp.push(index);
        }

        setState({
            isValid: state['isValid'],
            content: state['content'],
            expanded: expandedTmp,
            componentId: state['componentId']
        });
    };

    const {titleStr, readonly, itemKey, columns, value, parentFunction, name, required, helper, substate, lang, config, fgColorL1, label} = props;

    const item = {
        isValid: (new Array(value.length)).fill(true),
        content: value,
        expanded: [],
        componentId: `${nextId()}`
    };
    const [state, setState] = useState<object>(item);

    return <div className='mb-5 width-100' key={`${name}-formcontrol`}>
        <div>
            <div className='float-left'>
                <AauFormLabel
                    key={`${name}-label`}
                    isValid={state['isValid'].filter(o => o !== true).length < 1}
                    name={name}
                    value={label}
                    readonly={readonly}
                    required={required}
                    fgColorL1={fgColorL1}
                />
            </div>
            {
                readonly ? null :
                <AauMixinIconButton
                    variant='outlined'
                    size={btnSize}
                    icon='plus'
                    bgColor='l3-yellow'
                    className='ml-2 top-px-5-minus float-left'
                    tooltip={lang.getBtn('subform_add')}
                    mouseClickHandler={e => {
                        const stateTmp = JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')));
                        const validTmp = JSON.parse(JSON.stringify(state['isValid'].filter(o => typeof (o) !== 'undefined')));

                        const item = {};
                        if ( substate ) {
                            item['substate'] = 0;
                        }

                        Object.keys(columns).forEach(cid => {
                            const c: object = columns[cid];
                            item[c['name']] = c.hasOwnProperty('default') ? c['default'] : null;
                        });

                        stateTmp.push(item);

                        setState({
                            isValid: validTmp,
                            content: stateTmp,
                            expanded: state['expanded'],
                            componentId: nextId()
                        });
                    }}
                />
            }
            <div className='float-clear'></div>
        </div>
        <div className='mt-3 clear-both'>
            {
                Object.keys(JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')))).map(did => {
                    return <AauDynamicAccordionItem
                        key={`${name}_${state['componentId']}_${did}-accordion`}
                        dynamicKey={`${name}-${state['componentId']}_${did}`}
                        readonly={readonly}
                        columns={columns}
                        value={JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')))[did]}
                        expanded={state['expanded'].includes(did)}
                        substate={substate}
                        index={did.toString()}
                        isValid={JSON.parse(JSON.stringify(state['isValid'].filter(o => typeof (o) !== 'undefined')))[did]}
                        titleStr={titleStr}
                        subStateClick={subStateClick}
                        subDeleteClick={subDeleteClick}
                        subCopyClick={subCopyClick}
                        subModeDownClick={subModeDownClick}
                        subModeUpClick={subModeUpClick}
                        subExpandedClick={subExpandedClick}
                        parentFunction={subSetData}
                        itemsCount={state['content'].length}
                        lang={lang}
                        config={config}
                        fgColorL1={fgColorL1}
                    />;
                })
            }
        </div>
        <AauFormHelper
            key={`${name}-helper`}
            name={name}
            helper={helper}
            lang={lang}
            fgColorL1={fgColorL1}
        />
    </div>;
};
AauDynamicAccordion.defaultProps = defaultProps;
