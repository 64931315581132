/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import './aauMixinKey.css';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';


export interface AauMixinKeyProps {
    keytouch: string;
    description: string;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {

    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauMixinKeyProps;

export const AauMixinKey: FC<AauMixinKeyProps> = props => {
    const { keytouch, description, fgColorL1 } = props;

    return <span className={`fg-${fgColorL1}`}>
        <kbd>{keytouch}</kbd>&nbsp;{description}
    </span>;
};
AauMixinKey.defaultProps = defaultProps;
