/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../../Components/aauToolsConfiguration/aauToolsConfiguration';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';

import axios from 'axios';
import Cookies from 'js-cookie';
import {aauToolsColorEnum} from '../../Components/aauToolsColor/aauToolsColor';

import {useParams} from "react-router-dom";
import {AauMixinFabBack} from "../../Components/aauMixinFab/aauMixinFabPredined";
import {AauMixinStrYaml} from "../../Components/aauMixinStrYaml/aauMixinStrYaml";
import ReactDiffViewer, {DiffMethod} from '@terran-one/react-diff-viewer';


export interface AauPageYamlViewProps {
    action: string;
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorL2?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode?: boolean;
}

const defaultProps = {
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    darkMode: false
} as AauPageYamlViewProps;

export const AauPageYamlView: FC<AauPageYamlViewProps> = props => {
    const {lang, action, config, bgColorL2, fgColorL1, darkMode} = props;
    const {admin, module, identifier} = useParams();

    const [data, setData] = useState(null);

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/${action}/${identifier}`,
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    let content = <AauMixinLoader />;

    if ( data !== null && data !== false ) {
        if ( data['info'].hasOwnProperty('value2') > 0 ) {
            content =         content = <ReactDiffViewer
                oldValue={data['info']['value1']}
                newValue={data['info']['value2']}
                splitView={true}
                showDiffOnly={false}
                compareMethod={DiffMethod.WORDS}
                extraLinesSurroundingDiff={0}
                leftTitle={"Kubernetes item build from BDD"}
                rightTitle={"Kubernetes item extract"}
                useDarkTheme={darkMode}
            />;
        } else {
            content = <div>
                <div className={`p-2`}>
                    <AauMixinStrYaml value={data['info']['value1']} />;
                </div>
            </div>;
        }
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <div className='width-vw-99 height-vh-95-min'>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : 'de'}
                pageHelp={data !== null && data !== false ? data['page_help'] : 'de'}
                config={config}
                bgColorL2={bgColorL2}
                fgColorL1={fgColorL1}
            />
            <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-150'>
                {content}
            </div>
            <AauMixinFabBack lang={lang} config={config} url={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`} />
        </div>
    );
};
AauPageYamlView.defaultProps = defaultProps;
