/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import dayjs from 'dayjs';


export interface AauMixinDateProps {
    value: string;
    mode: 'display' | 'compare';
    less?: number;
    greater?: number;
    date_format?: string;
}

const defaultProps = {
    mode: 'display',
    less: 40,
    greater: 70,
    date_format: 'DD/MM/YYYY H:m:s'
} as AauMixinDateProps;

export const AauMixinDate: FC<AauMixinDateProps> = props => {
    const { value, mode, less, greater, date_format } = props;

    const dateNow = new Date();
    const dateObj = new Date(value);
    const dateString = dayjs(dateObj).format(date_format);
    const dateMs = dayjs(dateNow).unix() - dayjs(dateObj).unix();

    let ret = null;
    if ( mode === 'compare' ) {
        let color;

        if (dateMs > greater) {
            color = 'l4-red';
        } else if (dateMs < less) {
            color = 'l3-green-dark';
        } else {
            color = 'l3-orange-dark';
        }

        ret = <div className={`mixin-badge width-100 mx-auto bg-${color}`}>
            {value === null ? '' : dateString}
        </div>;
    } else {
        ret = <div className='mixin-badge width-100 mx-auto'>
            {value === null ? '' : dateString}
        </div>;
    }

    return ret;
};
AauMixinDate.defaultProps = defaultProps;
