/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';
import {aauMixinIconsEnum} from '../aauMixinIcon/aauMixinIcon';

import nextId from 'react-id-generator';
import {AauMixinIconButton, aauMixinIconButtonVariant} from '../aauMixinIconButton/aauMixinIconButton';
import {confirmAlert} from "react-confirm-alert";
import axios from "axios";
import {toast} from "react-toastify";
import AauToolsLang from "../aauToolsLang/aauToolsLang";
import AauToolsConfiguration from "../aauToolsConfiguration/aauToolsConfiguration";

export interface AauMixinFabProps {
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    tooltip: string;
    variant?: aauMixinIconButtonVariant;
    pos_px_bottom: number;
    hidden?: boolean;
    icon?: aauMixinIconsEnum;
    iconazure?: string;
    bgColor?: aauToolsColorEnum;
    fgColor?: aauToolsColorEnum;
    mouseClickHandler?: ()=>void;
    refHandler?: any;
    confirm?: boolean;

}

const defaultProps = {
    tooltip: 'undefined',
    fgColor: 'l3-black',
    icon: 'windows',
    variant: 'full',
    hidden: false,
    iconazure: '',
    confirm: false
} as AauMixinFabProps;

export const AauMixinFab: FC<AauMixinFabProps> = props => {
    const {tooltip, mouseClickHandler, hidden, variant, refHandler, pos_px_bottom, bgColor, fgColor,
        icon, iconazure, confirm, lang, config} = props;

    let ret = null;
    if ( confirm === true ) {
        ret = <AauMixinIconButton
            key={nextId()}
            size='tiny'
            icon={icon}
            iconazure={iconazure}
            variant={variant}
            bgColor={bgColor}
            fgColor={fgColor}
            tooltip={tooltip}
            type='div'
            classContainer={`mb-0 float-left pos-fixed right-px-10 z-900 bottom-px-${pos_px_bottom} ${hidden ? 'd-none' : 'd-block'}`}
            mouseClickHandler={e => {
                confirmAlert({
                    title: `${config.getGlobal('sitename')}`,
                    message: lang['formErrorFix'],
                    customUI: ({onClose}) => {
                        const closeHandler = () => {
                            onClose();
                            mouseClickHandler();
                        };

                        return <div className={`confirm-ui fit-content width-px-400-min p-10 bg-l4-grey fg-l3-white`}>
                            <h1 className={'text-center'}>{config.getGlobal('sitename')}</h1>
                            <p>{lang.getText('dialog_confirm_text')}</p>
                            <AauMixinIconButton
                                variant='righticontext'
                                size='tiny'
                                bgColor='l3-green-dark'
                                fgColor='l3-white'
                                icon='checkmark'
                                className='width-95 text-bold'
                                classContainer='mx-auto mt-8 width-50 float-left'
                                mouseClickHandler={closeHandler}
                                type='div'
                                tooltip={lang.getBtn('confirm')}
                            />
                            <AauMixinIconButton
                                variant='righticontext'
                                size='tiny'
                                bgColor='l4-red'
                                fgColor='l3-white'
                                icon='checkmark'
                                className='width-95 float-right text-bold'
                                classContainer='mx-auto mt-8 width-50 float-left'
                                mouseClickHandler={onClose}
                                type='div'
                                tooltip={lang.getBtn('cancel')}
                            />
                            <div className='float-clear'></div>
                        </div>;
                    }
                });
            }}
            fab={true}
            refHandler={refHandler}
        />;
    } else {
        ret = <AauMixinIconButton
            key={nextId()}
            size='tiny'
            icon={icon}
            iconazure={iconazure}
            variant={variant}
            bgColor={bgColor}
            fgColor={fgColor}
            tooltip={tooltip}
            type='div'
            classContainer={`mb-0 float-left pos-fixed right-px-10 z-900 bottom-px-${pos_px_bottom} ${hidden ? 'd-none' : 'd-block'}`}
            mouseClickHandler={mouseClickHandler}
            fab={true}
            refHandler={refHandler}
        />;
    }

    return ret;
};
AauMixinFab.defaultProps = defaultProps;
