/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';

import {Link} from '@fluentui/react';
import {AauMixinPersona} from "../aauMixinPersona/aauMixinPersona";


export interface AauPageRecordHistoryCardProps {
    id: string,
    user: string,
    action: string,
    delta: object,
    timestamp: string,
    config: AauToolsConfiguration;
}

const defaultProps = {
} as AauPageRecordHistoryCardProps;

export const AauPageRecordHistoryCard: FC<AauPageRecordHistoryCardProps> = props => {
    const {id, user, action, delta, timestamp, config} = props;

    const content = <div>
        <div className={`pos-absolute width-px-40 p-2`}>
            <AauMixinPersona
                displayName={user}
                config={config}
            />
        </div>
        <div className={`float-left mt-0 pl-14 width-100`}>
            <span className={`d-block mt-2 font-11 fg-l3-grey`}><span className={`text-bold fg-l3-grey`}>{user}</span> le <span className={`text-bold`}>{timestamp}</span></span>
            <span className={`d-block mt-2 font-14`}><span className={`text-bold`}>{action}</span>&nbsp;<span className={`fg-l3-white`}></span></span>
        </div>
    </div>;

    const className = `pos-relative bd-1 height-px-60-min width-100`

    return delta !== null ?
        <Link key={id} className={className}>{content}</Link> :
        <div key={id} className={className}>{content}</div>;
};
AauPageRecordHistoryCard.defaultProps = defaultProps;
