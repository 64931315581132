/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState} from 'react';

import {ThemeProvider, PartialTheme} from '@fluentui/react/lib/Theme';
import {Routes, BrowserRouter, Route} from 'react-router-dom';

import Cookies from 'js-cookie';
import {aauToolsColorEnum} from './Components/aauToolsColor/aauToolsColor';
import AauToolsLang from './Components/aauToolsLang/aauToolsLang';
import AauToolsConfiguration from './Components/aauToolsConfiguration/aauToolsConfiguration';

import './Components/aauToolsColor/aau-colors.scss';
import './Components/aauToolsTypography/typography.scss';
import './Components/aauToolsSizing/sizing.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import axios from 'axios';

import {AauMixinIcon} from './Components/aauMixinIcon/aauMixinIcon';
import {AauMixinLoader} from './Components/aauMixinLoader/aauMixinLoader';

import {Persona, PersonaSize, PersonaPresence} from '@fluentui/react/lib/Persona';

import {ToastContainer, Slide} from 'react-toastify';

import {AauComponentMenuHorizontal} from './Components/aauComponentMenuHorizontal/aauComponentMenuHorizontal';
import {AauPageForbidden} from "./Components/aauPageForbidden/aauPageForbidden";
import {AauPageLogin} from './Components/aauPageLogin/aauPageLogin';
import {AauPageList} from './Components/aauPageList/aauPageList';
import {AauPageForm} from './Components/aauPageForm/aauPageForm';
import {AauPageRecordHistory} from './Components/aauPageRecordHistory/aauPageRecordHistory';
import {AauPageLog} from './Pages/aauPageLog/aauPageLog';
import {AauPageLogHistory} from './Pages/aauPageLogHistory/aauPageLogHistory';
import {AauPageDatabase} from './Pages/aauPageDatabase/aauPageDatabase';
import {AauPageTestFront} from './Pages/aauPageTestFront/aauPageTestFront';
import {AauPageDiffFile} from './Pages/aauPageDiffFile/aauPageDiffFile';
import {AauPageCartoAudience} from './Pages/aauPageCartoAudience/aauPageCartoAudience';
import {AauPageCartoKubeDeployment} from './Pages/aauPageCartoKubeDeployment/aauPageCartoKubeDeployment';
import {AauPageCartoKubePod} from './Pages/aauPageCartoKubePod/aauPageCartoKubePod';
import {AauPageCartoKubeSvc} from './Pages/aauPageCartoKubeSvc/aauPageCartoKubeSvc';
import {AauPageCartoDns} from './Pages/aauPageCartoDns/aauPageCartoDns';
import {AauMixinFabGoToDown, AauMixinFabGoToUp} from './Components/aauMixinFab/aauMixinFabPredined';
import {AauMixinIconButton} from './Components/aauMixinIconButton/aauMixinIconButton';
import {AauPageAIO} from './Pages/aauPageAIO/aauPageAIO';
import {AauPageDockerInfo} from './Pages/aauPageDockerInfo/aauPageDockerInfo';
import {AauPageKubeView} from './Pages/aauPageKubeView/aauPageKubeView';
import {AauPageViewDetailList} from './Pages/aauPageViewDetailList/aauPageViewDetailList';
import {Toggle} from '@fluentui/react/lib/Toggle';
import {SearchBox} from '@fluentui/react/lib/SearchBox';

import {AauPageKubeNode} from './Pages/aauPageKubeNode/aauPageKubeNode';
import {AauPageSanityCheck} from './Pages/aauPageSanityCheck/aauPageSanityCheck';
import {AauComponentMenuHorizontalItem} from './Components/aauComponentMenuHorizontal/aauComponentMenuHorizontalItem';
import {AauPageYamlView} from "./Pages/aauPageYamlView/aauPageYamlView";
import {AauPageSearch} from "./Components/aauPageSearch/aauPageSearch";
import {AauPageNotFound} from "./Components/aauPageNotFound/aauPageNotFound";


function App() {
    let menuContent = null;
    let content;
    let filterContent = null;

    const retrieveMenu = async (configTmp:AauToolsConfiguration) => {
        await axios.get(
            `/rest/menu_with_persona`,
            {
                baseURL: `${configTmp.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(typeof(Cookies.get('JWT')) !== 'undefined' ? Cookies.get('JWT') : null)
                }
            }
        ).then(res => {
            setDmenu(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            if ( err.response.status === unauthorizedError ) {
                Cookies.remove('JWT');
                Cookies.remove('user');
                Cookies.remove('rank');
                Cookies.remove('displayname');
                window.location.href = `/login?redirect=${window.location.pathname}`;
            }
        });
    };

    const config = new AauToolsConfiguration();
    const lang = new AauToolsLang();

    config.load();
    lang.load();

    const [dmenu, setDmenu] = useState(null);
    const [personaMenu, setPersonaMenu] = useState<boolean>(false);
    const [knowitMenu, setKnowitMenu] = useState<boolean>(false);
    const [data, setData] = useState(null);
    const [search, setSearch] = useState(null);

    let darkMode = true;
    let knowITTheme: PartialTheme = config.getTheme(darkMode);
    let bgColorL1 = config.getColor('bg_color_l1', darkMode) as aauToolsColorEnum;

    if ( lang.isLoad() || config.isLoad() ){
        var qs = require('qs'); // SONAR
        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        const isLogged = typeof(Cookies.get('JWT')) !== 'undefined' && localStorage.getItem(Cookies.get('user')) !== null  ? true : false;
        const profil = isLogged ? JSON.parse(localStorage.getItem(Cookies.get('user'))) : {};

        if ( isLogged && params.hasOwnProperty('redirect') ) {
            window.location.href = `${params.redirect}`;
        } else if ( isLogged && window.location.pathname === '/login' ) {
            window.location.href = `/`;
        }

        darkMode = profil.hasOwnProperty('darkMode') ? profil['darkMode'] : config.getGlobal('dark_mode');
        bgColorL1 = config.getColor('bg_color_l1', darkMode) as aauToolsColorEnum;
        const bgColorL2 = config.getColor('bg_color_l2', darkMode) as aauToolsColorEnum;
        const bgColorMain = config.getColor('bg_color_main', darkMode) as aauToolsColorEnum;
        const fgColorL1 = config.getColor('fg_color_l1', darkMode) as aauToolsColorEnum;
        knowITTheme = config.getTheme(darkMode);

        if ( isLogged ) {
            if ( dmenu !== null && dmenu !== false && dmenu !== "waiting" ) {
                let menuObject = JSON.parse(JSON.stringify(dmenu['menu']));
                delete menuObject['knowit'];
                menuContent = <div className={`width-100 pos-fixed z-40 top-px-0`}>
                    <div className={`height-px-48 bg-${bgColorL2}`}>
                        <div className='float-left width-px-900-min'>
                            <AauComponentMenuHorizontal
                                data={menuObject}
                                itemFirstLevelClassName='p-3 mt-1'
                                itemOtherLevelClassName='p-2'
                                lang={lang}
                                config={config}
                                bgColorL1={bgColorL1}
                                bgColorL2={bgColorL2}
                                bgColorMain={bgColorMain}
                                fgColorL1={fgColorL1}
                                darkMode={darkMode}
                            />
                        </div>
                        <div className='float-right width-px-450-min'>
                            {
                                dmenu['menu'].hasOwnProperty('knowit') && <AauMixinIconButton
                                    key={`btn_admin_config`}
                                    size='small'
                                    iconazure='Settings'
                                    variant={'onlyicon'}
                                    fgColor={fgColorL1}
                                    type='div'
                                    classContainer='pos-relative left-px-0 top-px-5'
                                    tooltip='admin_configuration'
                                    mouseOverHandler={e => setKnowitMenu(true)}
                                    mouseOutHandler={e => setKnowitMenu(false)}
                                />
                            }
                            {
                                dmenu['menu'].hasOwnProperty('knowit') && <div
                                    className={`pos-absolute width-px-200 right-px-242 top-px-40 bg-${bgColorL2} p-1 bd-1 z-200 ${knowitMenu === true ? 'd-block' : 'd-none'}`}
                                    onMouseOver={e => setKnowitMenu(true)}
                                    onMouseOut={e => setKnowitMenu(false)}
                                >
                                    <AauComponentMenuHorizontalItem
                                        data={dmenu['menu']['knowit']}
                                        level={2}
                                        itemFirstLevelClassName={`p-1 mt-1 bg-${bgColorL2}`}
                                        itemOtherLevelClassName={`p-1 bg-${bgColorL2}`}
                                        linkClassName={`text-nodecoration text-bold cursor-pointer fg-${fgColorL1}`}
                                        lang={lang}
                                        config={config}
                                        bgColorL1={bgColorL1}
                                        bgColorL2={bgColorL2}
                                        bgColorMain={bgColorMain}
                                        fgColorL1={fgColorL1}
                                        darkMode={darkMode}
                                    />
                                </div>
                            }
                            <SearchBox
                                id={`global_search`}
                                key={`global_search`}
                                name={`global_search`}
                                role='search'
                                placeholder={lang.getText('global_search')}
                                onSearch={newValue => {
                                    if ( newValue === '' ) {
                                        setData(null);
                                    } else {
                                        setSearch(newValue);
                                    }
                                }}
                                onClear={e => setData(null)}
                                className={`float-left width-px-175 mr-4 mt-2`}
                                styles={{
                                    root: {
                                        borderColor: config.getColor('fluent_ui_palette' , darkMode)['palette']['neutralTertiary']
                                    }
                                }}
                            />
                            <Persona
                                className={'mt-2 width-px-185'}
                                size={PersonaSize.size32}
                                presence={PersonaPresence.none}
                                imageAlt=''
                                imageUrl={`${config.getGlobal('back')}/persona/${dmenu['persona']['displayName']}.jpg`}
                                imageInitials={dmenu['persona']['initials']}
                                text={dmenu['persona']['displayName']}
                                secondaryText={dmenu['persona']['title']}
                                showSecondaryText={true}
                                onMouseOver={e => setPersonaMenu(true)}
                                onMouseOut={e => setPersonaMenu(false)}
                            />
                            <div
                                key='menu-persona'
                                className={`pos-absolute width-px-200 right-px-10 bg-${bgColorL2} p-2 bd-1 z-200 ${personaMenu === true ? 'd-block' : 'd-none'}`}
                                onMouseOver={e => setPersonaMenu(true)}
                                onMouseOut={e => setPersonaMenu(false)}
                            >
                                <div>
                                    <div className='mx-auto fit-content'>
                                        <Toggle
                                            id='switch_dark_mode'
                                            key='switch_dark_mode'
                                            aria-labelledby='switch_dark_mode'
                                            checked={darkMode === true ? true : false}
                                            offText='Light'
                                            onText='Dark'
                                            role='switch'
                                            onChange={() => {
                                                profil['darkMode'] = darkMode === true ? false : true;
                                                localStorage.setItem(Cookies.get('user'), JSON.stringify(profil));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className='width-100 cursor-pointer pt-2 pb-2 text-center bd-1 bd-left-none bd-right-none bd-bottom-none'
                                    onClick={e => {
                                       window.location.href = '/logout';
                                    }}
                                >
                                    <AauMixinIcon
                                        size={'tiny'}
                                        icon='key'
                                        color={fgColorL1}
                                        className='pr-2'
                                    />
                                    Logout
                                </div>
                                <div className='float-clear' />
                            </div>
                            <AauMixinIconButton
                                key={`btn_help_ticket`}
                                size='small'
                                iconazure='Headset'
                                variant={'onlyicon'}
                                fgColor={fgColorL1}
                                type='a'
                                classContainer='pos-absolute right-px-5 top-px-5'
                                tooltip={lang.getBtn('create_support_ticket')}
                                link='https://epmakers.atlassian.net/servicedesk/customer/portal/12/group/32/create/161'
                            />
                        </div>
                    </div>
                </div>;
            } else if ( dmenu !== false ) {
                setDmenu(false);
                retrieveMenu(config);
            } else {
                // NO// SONAR
            }
        } else if ( window.location.pathname !== '/login' && window.location.pathname !== '/logout' ) {
            window.location.href = `/login?redirect=${window.location.pathname}`;
        } else if ( window.location.pathname !== '/login' ) {
            window.location.href = '/login';
        } else {
            // NO// SONAR
        }

        const AauPageListItem = <AauPageList
            lang={lang}
            config={config}
            darkMode={darkMode}
            bgColorL1={bgColorL1}
            bgColorL2={bgColorL2}
            bgColorMain={bgColorMain}
            fgColorL1={fgColorL1}
        />;

        const AauPageFormItem = <AauPageForm
            lang={lang}
            config={config}
            bgColorL1={bgColorL1}
            bgColorL2={bgColorL2}
            bgColorMain={bgColorMain}
            fgColorL1={fgColorL1}
        />;

        const AauPageRecordHistoryItem = <AauPageRecordHistory
            lang={lang}
            config={config}
            bgColorL2={bgColorL2}
            darkMode={darkMode}
            fgColorL1={fgColorL1}
        />;

        const AauPageDiffFileItem = <AauPageDiffFile
            action='difffile'
            lang={lang}
            config={config}
            darkMode={darkMode}
            bgColorL2={bgColorL2}
            fgColorL1={fgColorL1}
        />;

        const AauPageDiffFileWithVarItem = <AauPageDiffFile
            action='difffilewithvar'
            lang={lang}
            config={config}
            darkMode={darkMode}
            bgColorL2={bgColorL2}
            fgColorL1={fgColorL1}
        />;

        const AauPageLogItem = <AauPageLog
            lang={lang}
            config={config}
            darkMode={darkMode}
            bgColorL1={bgColorL1}
            bgColorL2={bgColorL2}
            fgColorL1={fgColorL1}
        />;

        const AauPageViewDetailListItem = <AauPageViewDetailList
            lang={lang}
            config={config}
            darkMode={darkMode}
            bgColorL1={bgColorL1}
            bgColorL2={bgColorL2}
            fgColorL1={fgColorL1}
        />;

        const AauPageYamlViewItem = <AauPageYamlView
            action='getyaml'
            lang={lang}
            config={config}
            darkMode={darkMode}
            bgColorL1={bgColorL1}
            bgColorL2={bgColorL2}
            fgColorL1={fgColorL1}
        />;

        if ( search !== null ) {
            window.location.href = `/search/${search}`;
        } else if ( data === false ) {
            content = <div className={`width-100 height-vh-100-min bg-l3-black`}>
                <AauMixinLoader />
            </div>;
        } else {
            var qs = require('qs'); // SONAR
            const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
            content = (isLogged === false || (isLogged === true && dmenu !== false))
            ? <Routes>
                <Route path='/forbidden' element={
                    <AauPageForbidden
                        msg={params.hasOwnProperty("msg") ? params.msg : 'unknow'}
                        config={config}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                }/>
                <Route path='/notfound' element={
                    <AauPageNotFound
                        config={config}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                }/>
                <Route path='/' />
                <Route path='/login' element={
                    <AauPageLogin
                        login_action={true}
                        lang={lang}
                        config={config}
                        darkMode={darkMode}
                        bgColorL1={bgColorL1}
                        bgColorL2={bgColorL2}
                        bgColorMain={bgColorMain}
                        fgColorL1={fgColorL1}
                    />
                }/>
                <Route path='/logout' element={
                    <AauPageLogin
                        login_action={false}
                        lang={lang}
                        config={config}
                        darkMode={darkMode}
                        bgColorL1={bgColorL1}
                        bgColorL2={bgColorL2}
                        bgColorMain={bgColorMain}
                        fgColorL1={fgColorL1}
                    />
                }/>
                <Route path='/search/:search' element={
                    <AauPageSearch
                        lang={lang}
                        config={config}
                        darkMode={darkMode}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                }/>
                <Route path='/database' element={
                    <AauPageDatabase
                        lang={lang}
                        config={config}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                }/>
                <Route path='/admin/aio' element={
                    <AauPageAIO
                        lang={lang}
                        config={config}
                        darkMode={darkMode}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                } />
                <Route path='/admin/kubenode' element={
                    <AauPageKubeNode
                        lang={lang}
                        config={config}
                        darkMode={darkMode}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                } />
                <Route path='/admin/backup/view/' element={
                    <AauPageKubeNode
                        lang={lang}
                        config={config}
                        darkMode={darkMode}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                } />
                <Route path='/:admin/linksdirectory/cartonetwork' element={
                    <AauPageCartoAudience
                        lang={lang}
                        config={config}
                        darkMode={darkMode}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                } />
                <Route path='/:admin/kubedeployment/carto' element={
                    <AauPageCartoKubeDeployment
                        lang={lang}
                        config={config}
                        darkMode={darkMode}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                } />
                <Route path='/kubepod/carto' element={
                    <AauPageCartoKubePod
                        lang={lang}
                        config={config}
                        darkMode={darkMode}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                } />
                <Route path='/kubepod/view' element={
                    <AauPageKubeView
                        lang={lang}
                        config={config}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                } />
                <Route path='/kubesvc/carto' element={
                    <AauPageCartoKubeSvc
                        lang={lang}
                        config={config}
                        darkMode={darkMode}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                } />
                <Route path='/admin/sanitycheck' element={
                    <AauPageSanityCheck
                        lang={lang}
                        config={config}
                        bgColorL1={bgColorL1}
                        bgColorL2={bgColorL2}
                        bgColorMain={bgColorMain}
                        fgColorL1={fgColorL1}
                    />
                } />
                <Route path='/:admin/docker/dockerinfo/:identifier' element={
                    <AauPageDockerInfo
                        lang={lang}
                        config={config}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                } />
                <Route path='/:admin/dns/carto' element={
                    <AauPageCartoDns
                        lang={lang}
                        config={config}
                        darkMode={darkMode}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                } />
                <Route path='/:admin/:module/getyaml/:identifier' element={AauPageYamlViewItem} />
                <Route path='/:admin/:module/history/:identifier' element={AauPageRecordHistoryItem} />
                <Route path='/:admin/:module/cloudlog/:identifier' element={AauPageLogItem} />
                <Route path='/:admin/:module/cloudloghistory/:identifier' element={
                    <AauPageLogHistory
                        lang={lang}
                        config={config}
                        darkMode={darkMode}
                        bgColorL1={bgColorL1}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                }/>
                <Route path='/:admin/:module/difffilewithvar/:identifier' element={AauPageDiffFileWithVarItem} />
                <Route path='/:admin/:module/viewdetaillist/:identifier' element={AauPageViewDetailListItem} />
                <Route path='/:admin/:module/difffile/:identifier' element={AauPageDiffFileItem} />
                <Route path='/:admin/:module/:action/:identifier' element={AauPageFormItem} />
                <Route path='/admin/testfront/view/:identifier' element={
                    <AauPageTestFront
                        lang={lang}
                        config={config}
                        bgColorL2={bgColorL2}
                        fgColorL1={fgColorL1}
                    />
                }/>
                <Route path='/:module/cloudlog/:identifier' element={AauPageLogItem} />
                <Route path='/:module/history/:identifier' element={AauPageRecordHistoryItem} />
                <Route path='/:module/:action/:identifier' element={AauPageFormItem} />
                <Route path='/:admin/:module' element={AauPageListItem} />
                <Route path='/:module' element={AauPageListItem} />
            </Routes>
            : <AauMixinLoader />;
        }
    } else {
        content = <div className={`width-100 height-vh-100-min bg-l3-black`}>
            <AauMixinLoader />
        </div>;
        setTimeout(() => window.location.reload(), 2000);
    }

     return <ThemeProvider theme={knowITTheme}>
        <div className={`width-100 height-vh-100-min bg-${bgColorL1}`}>
            <BrowserRouter>
                 {menuContent}
                 <ToastContainer
                     position='bottom-center'
                     hideProgressBar
                     autoClose={3000}
                     transition={Slide}
                     theme='dark'
                 />
                 {filterContent}
                 {content}
             </BrowserRouter>
             { ['/login', 'logout', '/'].includes(window.location.pathname)  ? null :  <AauMixinFabGoToUp lang={lang} config={config} />}
             { ['/login', 'logout', '/'].includes(window.location.pathname)  ? null :  <AauMixinFabGoToDown lang={lang}  config={config} />}
            <div className={"float-clear"} />
        </div>
    </ThemeProvider>;
};

export default App;


