/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

//https://particles.js.org/samples/index.html#growing

import React, {FC, useState} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';

// import Particles from 'react-particles-js';

// import {isBrowser, isMobile} from 'react-device-detect';

import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';
import {AauMixinIconButton} from '../aauMixinIconButton/aauMixinIconButton';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {TextField} from '@fluentui/react/lib/TextField';

export interface AauComponentLoginProps {
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorL2?: aauToolsColorEnum;
    bgColorMain?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode?: boolean;
    processLogin: any;
}

const defaultProps = {
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    bgColorMain: 'l3-blue' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    darkMode: false
} as AauComponentLoginProps;

export const AauComponentLogin: FC<AauComponentLoginProps> = props => {

    const {processLogin, lang, config, bgColorL1, bgColorL2, bgColorMain, fgColorL1} = props;

    const [state, setState] = useState({login: '', password: ''});

    const updateDataLogin = (key: string, value): void => {
        const dataTmp = JSON.parse(JSON.stringify(state));
        dataTmp[key] = value;
        setState(dataTmp);
    };

    let sitename = null;
    if ( config.getGlobal('sitelogourl') !== '' ) {
        sitename = <img className={`height-px-70 mx-auto mb-4`} alt={`Site logo`} src={config.getGlobal('sitelogourl')} />;
    } else {
        sitename = config.getGlobal('sitename');
    }

    return <div className={`bg-${bgColorL1}`} key='aauComponentLogin'>
        <div className={`center-screen width-px-300 height-px-300 pos-absolute p-10 bg-${bgColorL2}`}>
            <div className={`p-3 pl-8 pr-8`}>
                <a className={``} href='/'>
                    {sitename}
                </a>
            </div>
            <TextField
                id='login'
                key={`login-ctrl`}
                prefix={lang.getText('login')}
                className={`mb-5 bd-1 bd-l3-white bd-top-none bd-left-none bd-right-none`}
                onChange={(e, val) => updateDataLogin('login', val) }
                borderless
            />
            <TextField
                id='paswword'
                key={`paswword-ctrl`}
                type='password'
                prefix={lang.getText('paswword')}
                canRevealPassword={true}
                className={`mb-5 bd-1 bd-l3-white bd-top-none bd-left-none bd-right-none`}
                onKeyPress={e => { if ( e.charCode === 13 ) { processLogin(state); return false; }}}
                onChange={(e, val) => updateDataLogin('password', val)}
                borderless
            />
            <AauMixinIconButton
                variant='righticontext'
                size='tiny'
                bgColor={bgColorMain}
                fgColor={fgColorL1}
                icon='key'
                className='width-100'
                classContainer='mx-auto'
                tooltip={lang.getBtn('login')}
                mouseClickHandler={e => {
                    processLogin(state);
                }}
                type='div'
            />
        </div>
        <div className='float-clear'></div>
    </div>;
};
AauComponentLogin.defaultProps = defaultProps;
