/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../../Components/aauToolsConfiguration/aauToolsConfiguration';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';

import axios from 'axios';
import Cookies from 'js-cookie';
import {aauToolsColorEnum} from '../../Components/aauToolsColor/aauToolsColor';

import {AauComponentTable} from '../../Components/aauComponentTable/aauComponentTable';
import {useParams} from "react-router-dom";
import {AauMixinFabBack} from "../../Components/aauMixinFab/aauMixinFabPredined";


export interface AauPageViewDetailListProps {
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorL2?: aauToolsColorEnum;
    bgColorMain?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode?: boolean;
}

const defaultProps = {
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    darkMode: false
} as AauPageViewDetailListProps;

export const AauPageViewDetailList: FC<AauPageViewDetailListProps> = props => {
    const {lang, config, bgColorL1, bgColorL2, bgColorMain, fgColorL1, darkMode} = props;

    const {module, identifier, admin} = useParams();
    const [data, setData] = useState(null);

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/viewdetaillist/${identifier}`,
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    let content = <AauMixinLoader />;

    if ( data !== null && data !== false ) {
        content = <AauComponentTable
            columns={data['info']['columns']}
            data={data['info']['data']}
            columnid={data['info']['columnid']}
            displayRows={data.hasOwnProperty('options') ? data['options']['showEntries'] : config.getDefaultValues('display_rows')}
            displayRowsList={data.hasOwnProperty('options') ? data['options']['showEntriesList'] : config.getDefaultValues('display_rows_list')}
            refreshTable={retrieveData}
            lang={lang}
            config={config}
            bgColorL1={bgColorL1}
            bgColorMain={bgColorMain}
            fgColorL1={fgColorL1}
            canEdit={false}
            canView={false}
            darkMode={darkMode}
        />


    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <div className='width-vw-99 height-vh-95-min'>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
                config={config}
                bgColorL2={bgColorL2}
                fgColorL1={fgColorL1}
            />
            <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-150'>
                {content}
            </div>
            <AauMixinFabBack lang={lang} config={config} url={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`} />
        </div>
    );
};
AauPageViewDetailList.defaultProps = defaultProps;
