/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */


import axios from 'axios';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';


export default class AauToolsLang {
    lang: object;
    updatedAt: string;
    config: AauToolsConfiguration;

    async retrieve() {
        localStorage.setItem('lang_updated_at', Date.now().toString());

        let lang: object = {};
        await axios({
            method: 'get',
            url: `${this.config.getGlobal('back')}/rest/admin/knowittranslate/file?localName=fr`,
            headers: {
                'accept': 'application/json'
            }
        }).then( response => {
            lang = response.data as {
                [key: string]: string
            };
            localStorage.setItem('lang', JSON.stringify(lang));
        });
    }

    isLoad() {
        return this.lang === null ? false : true;
    }

    load() {
        this.config = new AauToolsConfiguration();
        this.lang = JSON.parse(localStorage.getItem('lang'));
        this.updatedAt = localStorage.getItem('lang_updated_at');

        const tsUpdatedAt:number = parseInt(this.updatedAt, 10);

        const cacheTime = 300000;

        let needRefresh = false;
        if ( this.updatedAt === null || typeof(this.updatedAt) === 'undefined' || Date.now() - tsUpdatedAt > cacheTime ) {
            needRefresh = true;
        }

        if ( this.lang === null || typeof(this.lang) === 'undefined' || needRefresh === true ) {
            this.retrieve();
        }

        return this.lang;
    }

    getText(text='', prefix:string=null, defaultValue = '_____no_____') {

        let ret = '';

        if ( text !== null ) {
            if (prefix !== null) {
                text = `${prefix}_${text}`;
            }

            ret = `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
            if (this.load().hasOwnProperty(text)) {
                ret = this.load()[text];
            } else if (defaultValue !== '_____no_____') {
                ret = defaultValue;
            } else {
                //NOSONAR
            }
        }

        return ret;
    }

    getBtn(actionName=''){
        return this.getText(actionName, 'btn');
    }

    getAction(actionName=''){
        return this.getText(actionName, 'action');
    }

    getError(actionName=''){
        return this.getText(actionName, 'error');
    }

    getForbidden(actionName=''){
        return this.getText(actionName, 'forbidden');
    }

    // getModuleTitle(moduleName=''){
    //     return this.getText(moduleName, 'title');
    // }

    // getActionTitle(actionName=''){
    //     return this.getText(actionName, 'action');
    // }
    //
    // getModuleMenu(moduleName=''){
    //     return this.getText(moduleName, 'menu');
    // }

    getHelp(moduleName='', defaultValue='_____no_____' ){
        return this.getText(moduleName, 'help', defaultValue);
    }

    getNotification(actionName='', success=true){
        return this.getText(`${actionName}${success === true ? 'success' : 'error'}`);
    }

    getHelper(textHelper: string, fieldName: string){
        let ret = this.getText(textHelper, 'helper', null);

        if ( ret === null ) {
            ret = this.getText(fieldName, 'helper', '');
        }

        return ret;
    }

    getBool(fieldName: string, boolValue: string) {
        return this.getText(`${fieldName}_${boolValue}`, 'bool', '');
    }

    getSelectOption(fieldName: string, itemOption: string) {
        let ret = this.getText(`${fieldName}_${itemOption}`, 'opt', itemOption);

        if ( ret === null ) {
            ret = this.getText(`${itemOption}`, 'opt', itemOption);
        }

        return ret;
    }

    getFilter(fieldName: string, filterOption: string ) {
        return this.getText(`${fieldName}_${filterOption}`, 'filter');
    }

    // getMenu(menuName: string) {
    //     return this.getText(menuName.toLowerCase(), 'menu', menuName);
    // }
}
