/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';
import nextId from 'react-id-generator';


export interface AauKubeNodeRowProps {
    label: string;
    val: string;
    col?: number
}

const defaultProps = {
    col: 2
} as AauKubeNodeRowProps;

export const AauKubeNodeRow: FC<AauKubeNodeRowProps> = props => {
    const {label, val, col} = props;

    return <div
        className='ms-Grid-row'
        key={nextId()}
    >
        <div className={`ms-Grid-col ${val === '' ? 'pt-3 text-underline text-bold' : ''} ${col === 2 ? 'ms-sm4' : 'ms-sm4 text-right pr-10 text-bold' } float-left`}>{label}</div>
        <div className={`ms-Grid-col ${val === '' ? 'pt-3' : ''} ${col === 2 ? 'ms-sm8' : 'ms-sm8' } float-left`}>{val}</div>
    </div>;
};
AauKubeNodeRow.defaultProps = defaultProps;
