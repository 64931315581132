/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import {AauMixinListText} from '../aauMixinListText/aauMixinListText';
import {AauMixinStrAmount, MoneyType} from '../aauMixinStrAmount/aauMixinStrAmount';
import {AauMixinStrUnit} from '../aauMixinStrUnit/aauMixinStrUnit';
import {AauMixinStrMask} from '../aauMixinStrMask/aauMixinStrMask';
import {AauMixinBadge} from '../aauMixinBadge/aauMixinBadge';
import {AauMixinDate} from '../aauMixinDate/aauMixinDate';
import {AauMixinTimestamp} from '../aauMixinTimestamp/aauMixinTimestamp';
import {AauMixinBtnBool} from '../aauMixinBtnBool/aauMixinBtnBool';
import {AauMixinBtnGroup} from '../aauMixinBtnGroup/aauMixinBtnGroup';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';
import {AauMixinPersona} from '../aauMixinPersona/aauMixinPersona';
import {AauMixinSwitchBool} from '../aauMixinSwitchBool/aauMixinSwitchBool';
import {AauMixinIcon} from '../aauMixinIcon/aauMixinIcon';
import {AauMixinRating} from '../aauMixinRating/aauMixinRating';
import {AauMixinStrToIcon} from '../aauMixinStrToIcon/aauMixinStrToIcon';
import {AauMixinProgress} from '../aauMixinProgress/aauMixinProgress';
import {AauMixinJson} from '../aauMixinJson/aauMixinJson';
import {AauMixinStrCompare} from "../aauMixinStrCompare/aauMixinStrCompare";
import {AauMixinIconButton} from '../aauMixinIconButton/aauMixinIconButton';
import {AauMixinStrLink, aauMixinStrLinkScheme} from "../aauMixinStrLink/aauMixinStrLink";

import {confirmAlert} from 'react-confirm-alert';
import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import axios from 'axios';
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import {AauComponentForm} from '../aauComponentForm/aauComponentForm';
import {AauMixinTimeAgo} from "../aauMixinTimeAgo/aauMixinTimeAgo";


export interface AauComponentTableBodyColumnProps {
    rowKey: string;
    value: object;
    mixin: string;
    component: object;
    dataJson: object;
    refreshTable?: () => void;
    columnid: string
    columnForm: object[],
    canEdit: boolean;
    canView: boolean;
    canSaveAndAction: string|null;
    saveAndActionLabel?: string;
    viewAction?: string;
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorMain?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode?: boolean;
    handlerPanelInfo?: (url: string) => void;
}

export const AauComponentTableBodyColumnPropsDefault = {
    columnForm: null,
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    canEdit: false,
    canView: false,
    canSaveAndAction: null,
    saveAndActionLabel: 'Save and Action',
    viewAction: 'view',
    darkMode: false
} as AauComponentTableBodyColumnProps;

export const AauComponentTableBodyColumn: FC<AauComponentTableBodyColumnProps> = props => {
    const { rowKey, value, mixin, component, canEdit, canView, canSaveAndAction, saveAndActionLabel, viewAction,
            dataJson, refreshTable, columnid, lang, config, columnForm, bgColorL1, bgColorMain, fgColorL1, darkMode,
            handlerPanelInfo} = props;

    let ret:any;
    let align:string;
    const history = useNavigate();

    const [displayEditIcon, setDisplayEditIcon] = useState<boolean>(false);

    const submitHandler = async (formData, identifier) => {
        await axios.post(
            `/rest/${window.location.pathname}/save`,
            JSON.stringify({
                identifier,
                data: formData
            }),
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            if (res.data.success === 1 || res.data.success === true) {
                const msg = lang.getNotification('save', true);
                toast.success(msg.replace('%s', res.data.name));
            } else {
                const msg = lang.getNotification('save', false);
                toast.error(msg.replace('%s', res.data.name));
            }
            refreshTable();
        }).catch(err => {
            const unauthorizedError = 401;
            if ( err.response.status === unauthorizedError ) {
                Cookies.remove('JWT');
                Cookies.remove('user');
                Cookies.remove('rank');
                Cookies.remove('displayname');
                window.location.href = `/login?redirect=${window.location.pathname}`;
            }
        });
    };

    const submitAndActionHandler = async (formData, identifier) => {
        await axios.post(
            `/rest/${window.location.pathname}/saveAndPush`,
            JSON.stringify({
                identifier,
                data: formData
            }),
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            if (res.data.success === 1 || res.data.success === true) {
                const msg = lang.getNotification('save', true);
                toast.success(msg.replace('%s', res.data.name));
            } else {
                const msg = lang.getNotification('save', false);
                toast.error(msg.replace('%s', res.data.name));
            }
            refreshTable();
        }).catch(err => {
            const unauthorizedError = 401;
            if ( err.response.status === unauthorizedError ) {
                Cookies.remove('JWT');
                Cookies.remove('user');
                Cookies.remove('rank');
                Cookies.remove('displayname');
                window.location.href = `/login?redirect=${window.location.pathname}`;
            }
        });
    };

    const fastEditHandler = (rowKeyTmp:string) => {
        const fieldName = rowKeyTmp.split('_')[1];

        const msgDialog = `${dataJson.hasOwnProperty('name') ? dataJson['name'] : ''} (${dataJson[columnid]})`;

        confirmAlert({
            title: `${config.getGlobal('sitename')}`,
            message: lang['formErrorFix'],
            customUI: ({onClose}) => {
                const saveHandler = formData => {
                    submitHandler(formData, dataJson[columnid]);
                    onClose();
                };
                const saveActionHandler = formData => {
                    submitAndActionHandler(formData, dataJson[columnid]);
                    onClose();
                };
                return <div className={`confirm-ui width-px-450-min fit-content p-10 bg-l4-grey fg-l3-white`}>
                    <h1 className={'text-center'}>{config.getGlobal('sitename')}</h1>
                    <p className={'text-center'}>{msgDialog}</p>
                    <div className={`float-clear mx-auto width-100`}>
                        <AauComponentForm
                            columns={columnForm}
                            item={dataJson}
                            readonly={false}
                            saveHandler={saveHandler}
                            saveActionHandler={canSaveAndAction !== null ? saveActionHandler : null}
                            saveAndActionLabel={canSaveAndAction !== null ? saveAndActionLabel : null}
                            inDialog={true}
                            lang={lang}
                            config={config}
                            fgColorL1={fgColorL1}
                        />
                        <div className='float-clear'></div>
                    </div>
                    <div className={`float-clear mt-2 mx-auto`}>
                        <AauMixinIconButton
                            variant='righticontext'
                            size='tiny'
                            bgColor='l4-red'
                            fgColor='l3-white'
                            icon={null}
                            className='width-100 text-bold'
                            classContainer='mx-auto width-100'
                            mouseClickHandler={onClose}
                            type='div'
                            tooltip={lang.getBtn('cancel')}
                        />
                    </div>
                    <div className='float-clear'></div>
                </div>;
            }
        });
    };

    if ( rowKey.split('_')[1] !== 'btnactiongroup' ) {
        switch (mixin) {
            case 'list_html_nobullet':
                align = 'left'
                if ( typeof(value) === "undefined" || value === null ) {
                    ret = '';
                } else {
                    ret = <AauMixinListText value={value.toString().split(',')} type='html_nobullet'/>;
                }
                break;
            case 'list_html':
                align = 'left'
                if ( typeof(value) === "undefined" || value === null ) {
                    ret = '';
                } else {
                    ret = <AauMixinListText value={value.toString().split(',')} type='html'/>;
                }
                break;
            case 'list_html_button':
                align = 'left';
                if ( typeof(value) === "undefined" || value === null ) {
                    ret = '';
                } else {
                    ret = <AauMixinListText value={value.toString().split(',')} type='html_button'/>;
                }
                break;
            case 'list_num':
                align = 'left';
                if ( typeof(value) === "undefined" || value === null ) {
                    ret = '';
                } else {
                    ret = <AauMixinListText value={value.toString().split(',')} type='num'/>;
                }
                break;
            case 'list_join':
                align = 'left';
                if ( typeof(value) === "undefined" || value === null ) {
                    ret = '';
                } else {
                    ret = <AauMixinListText value={value.toString().split(',')} type='join'/>;
                }
                break;
            case 'int':
                align = 'right';
                ret = value.toString();
                break;
            case 'float':
                align = 'right';
                const defaultPrecision = 2;
                const precision = component.hasOwnProperty('precision') ? component['precision'] : defaultPrecision;
                ret = Number(value).toFixed(precision);
                break;
            case 'str_compare':
                align = 'center';

                const field1 = component.hasOwnProperty('field1') ? component['field1'] : '';
                const value1 = dataJson.hasOwnProperty(field1) ? dataJson[field1] : '-';
                const field2 = component.hasOwnProperty('field2') ? component['field2'] : '';
                const value2 = dataJson.hasOwnProperty(field2) ? dataJson[field2] : '-';

                ret = <AauMixinStrCompare
                    value1={value1}
                    value2={value2}
                    colorOK= {null}
                    colorNULL= {null}
                />;
                break;
            case 'str_date':
                align = 'center';

                ret = <AauMixinDate
                    value={value !== null ? value.toString() : null}
                    mode='display'
                    date_format={component.hasOwnProperty('date_format') ? component['date_format'] : 'DD/MM/YYYY HH:mm:ss'}
                />;
                break;
            case 'str_timestamp':
                align = 'center';

                ret = <AauMixinTimestamp
                    value={value !== null ? Number(value) : null}
                    mode='display'
                    date_format={component.hasOwnProperty('date_format') ? component['date_format'] : 'DD/MM/YYYY HH:mm:ss'}
                />;
                break;
            case 'str_date-diff-from-now':
                align = 'center';

                ret = <AauMixinDate
                    value={value !== null ? value.toString() : null}
                    mode='compare'
                    less={component.hasOwnProperty('less') ? component['less'] : config.getDefaultValues('less_percent')}
                    greater={component.hasOwnProperty('greater') ? component['greater'] : config.getDefaultValues('greater_percent')}
                    date_format={component.hasOwnProperty('date_format') ? component['date_format'] : 'DD/MM/YYYY H:m:s'}
                />;
                break;
            case 'str_timestamp-diff-from-now':
                align = 'center';

                ret = <AauMixinTimestamp
                    value={value !== null ? Number(value) : null}
                    mode='display'
                    less={component.hasOwnProperty('less') ? component['less'] : config.getDefaultValues('less_percent')}
                    greater={component.hasOwnProperty('greater') ? component['greater'] : config.getDefaultValues('greater_percent')}
                    date_format={component.hasOwnProperty('date_format') ? component['date_format'] : 'DD/MM/YYYY HH:mm:ss'}
                />;
                break;
            case 'str_euro':
            case 'str_dollar':
            case 'str_pound':
            case 'str_yen':
            case 'str_rubble':
                align = 'right';
                ret = <AauMixinStrAmount
                    value={Number(value)}
                    moneyType={mixin.split('_')[1].toLowerCase() as MoneyType}
                />;
                break;
            case 'str_unit':
            case 'str_octect':
                align = 'right';
                ret = <AauMixinStrUnit
                    value={Number(value)}
                    precision={component.hasOwnProperty('precision') ? component['precision'] : config.getDefaultValues('precision')}
                    unit={component.hasOwnProperty('unit') ? component['unit'] : 'o'}
                />;
                break;
            case 'str_bytes':
                align = 'right';
                ret = <AauMixinStrUnit
                    value={Number(value)}
                    precision={component.hasOwnProperty('precision') ? component['precision'] : config.getDefaultValues('precision')}
                    unit='B'
                />;
                break;
            case 'str_bit':
                align = 'right';
                ret = <AauMixinStrUnit
                    value={Number(value)}
                    precision={component.hasOwnProperty('precision') ? component['precision'] : config.getDefaultValues('precision')}
                    unit='b'
                />;
                break;
            case 'str_mask':
                align = 'center';

                const usedMask = component.hasOwnProperty('used_mask') ? component['used_mask'] : false;
                const maskFieldName = component.hasOwnProperty('mask_field') ? component['mask_field'] : '';
                const maskField = usedMask && dataJson.hasOwnProperty(maskFieldName) ? dataJson[maskFieldName] : false;

                ret = <AauMixinStrMask
                    value={value !== null ? value.toString() : null}
                    maskChar={component.hasOwnProperty('mask_char') ? component['mask_char'] : '*'}
                    maskLen={component.hasOwnProperty('mask_len') ? component['mask_len'] : 10}
                    maskEnabled={!maskField}
                />;
                break;
            case 'str_enum':
                align = 'center';

                const values = component.hasOwnProperty('values') ? component['values'] : {};

                ret = values.hasOwnProperty(value) ? values[value.toString()] : '';
                break;
            case 'str_split':
                align = 'center';

                const separator = component.hasOwnProperty('separator') ? component['separator'] : '?';
                let formatStr = component.hasOwnProperty('formatStr') ? component['formatStr'] : '';
                const split_values = value.toString().split(separator);
                const enums = component.hasOwnProperty('enums') ? component['enums'] : {};

                if (value.toString() !== 'NC') {
                    split_values.forEach((val, index) => formatStr = formatStr.replace(`{${index}}`, val));
                    ret = <AauMixinStrLink
                        url={`${formatStr.split('://')[1]}`}
                        scheme={`${formatStr.split('://')[0]}` as aauMixinStrLinkScheme}
                        name={enums.hasOwnProperty(value.toString()) ? enums[value.toString()] : value.toString()}
                        type='text'
                    />;
                } else {
                    ret = '-'
                }
                break;
            case 'int_color':
            case 'float_color':
                align = 'right';

                ret = <AauMixinBadge
                    value={Number(value)}
                    less={component.hasOwnProperty('less') ? component['less'] : config.getDefaultValues('less_percent')}
                    greater={component.hasOwnProperty('greater') ? component['greater'] : config.getDefaultValues('greater_percent')}
                    precision={component.hasOwnProperty('precision') ? component['precision'] : config.getDefaultValues('precision')}
                    size={component.hasOwnProperty('size') ? component['size'] : 'tiny'}
                    lang={lang}
                />;
                break;
            case 'rating':
                align = 'center';

                ret = <AauMixinRating
                    value={Number(value)}
                />;
                break;
            case 'btn_state':
                align = 'right';

                ret = <AauMixinBtnBool
                    value={value}
                    size={component.hasOwnProperty('size') ? component['size'] : 'tiny'}
                    lang={lang}
                />;
                break;
            case 'toggle':
            case 'switch':
                align = 'center';

                ret = <AauMixinSwitchBool
                    value={value}
                />;
                break;
            case 'toggle_state':
            case 'switch_state':
                align = 'center';

                ret = <AauMixinSwitchBool
                    value={value}
                />;
                break;
            case 'dynamicForm':
                ret = Object.keys(value[rowKey.split('_')[1]]).map(rid => {
                    let ret = null;
                    const o = value[rowKey.split('_')[1]][rid];
                    if ( !o.hasOwnProperty('substate') || (o.hasOwnProperty('substate') && (o['substate'] === 1 || o['substate'] === true)) ) {
                        const subContent = Object.keys(o).map(cid => {
                            let subret = null;

                            if (cid !== 'substate') {
                                subret = <li key={`${rowKey}_${rid}_${cid}`}>
                                    <b>{lang.getText(cid)}</b>: {o[cid]}
                                </li>;
                            }

                            return subret;
                        });
                        ret = <ul key={`${rowKey}_${rid}`} className='mb-1'>{subContent}</ul>;
                    }
                    return ret
                });
                break;
            case 'str_to_icon':
                ret = <AauMixinStrToIcon
                    value={value.toString()}
                    options={component.hasOwnProperty('options') ? component['options'] : {}}
                />;
                break;
            case 'persona':
                ret = <AauMixinPersona
                    displayName={value.toString()}
                    config={config}
                />;
                break;
            case 'progress':
                ret = <AauMixinProgress
                    value={Number(value)}
                />;
                break;
            case 'str_timeago':
                ret = <AauMixinTimeAgo
                    value={value !== null ? Number(value) : 0}
                    type="ts"
                />;
                break;
            case 'str_datetimeago':
                ret = <AauMixinTimeAgo
                    value={value !== null ? value.toString() : '0'}
                    type="dt"
                />;
                break;
            case 'json':
            case 'jsonb':
                ret = <AauMixinJson
                    value={value}
                />;
                break;
            default:
                align = 'center';
                ret = value;
        }

        if ( canView && rowKey.split('_')[1] === 'name' ) {
            const url = `${window.location.pathname}/${viewAction}/${dataJson[columnid]}`;
            ret = <div className='text-bold' style={{cursor: 'pointer', textDecoration: 'underline', textDecorationColor: 'initial', color: 'inherit'}} onClick={(e) => {if ( e.ctrlKey === true ) {history(url)} else {handlerPanelInfo(url)}}}>
                {ret}
            </div>;
        }
    } else {
        ret = <AauMixinBtnGroup
            btns={component.hasOwnProperty('grpItems') ? component['grpItems'] : {}}
            dataJson={dataJson}
            size='tinyiest'
            align='right'
            refreshTable={refreshTable}
            columnid={columnid}
            lang={lang}
            config={config}
            bgColorL1={bgColorL1}
            fgColorL1={fgColorL1}
            darkMode={darkMode}
            rankField={component.hasOwnProperty('rank_field') ? component['rank_field'] : null}
        />;
    }

    return (
        <td key={rowKey} className={`text-${align} ${canEdit? 'cursor-pointer': ''}`}
            onDoubleClick={e => {
            if ( canEdit ) {
                fastEditHandler(rowKey);
            }
        }}
            onMouseOver={e => {setDisplayEditIcon(canEdit && true)}}
            onMouseOut={e => {setDisplayEditIcon(canEdit && false)}}
        >
            {ret}
            {displayEditIcon && <AauMixinIcon size='inline' color={bgColorMain} icon='' iconazure='PencilReply' className={`float-right ml-2 mr-1`} />}
        </td>
    );
};
AauComponentTableBodyColumn.defaultProps = AauComponentTableBodyColumnPropsDefault;
