/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC, ReactElement, useState} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauComponentMenuHorizontalItemObject} from './aauComponentMenuHorizontalItemObject.d';
import {AauMixinIcon} from '../aauMixinIcon/aauMixinIcon';
import {AauMixinBadgeVersion} from "../aauMixinBadgeVersion/aauMixinBadgeVersion";

export interface AauComponentMenuHorizontalItemProps {
    data: object | AauComponentMenuHorizontalItemObject;
    level: number;
    itemFirstLevelClassName: string;
    itemOtherLevelClassName: string;
    linkClassName: string;
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorL2?: aauToolsColorEnum;
    bgColorMain?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode?: boolean;
}

const defaultProps = {
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    bgColorMain: 'l3-blue' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    darkMode: false
} as AauComponentMenuHorizontalItemProps;

export const AauComponentMenuHorizontalItem: FC<AauComponentMenuHorizontalItemProps> = (props):ReactElement  => {

    const {data, level, itemFirstLevelClassName, itemOtherLevelClassName, linkClassName, lang, config, bgColorL1, bgColorL2, bgColorMain, fgColorL1, darkMode} = props;

    const [open, setOpen] = useState<string>('');

    const menuLvl = 80;

    return (
        <div className=''>
            {
                Object.keys(data).map(labelkey => {
                    return data[labelkey].hasOwnProperty('href') === true ? (
                        <div key={`menu-sub menu-l${level}-${labelkey}`}
                            onMouseOver={e => setOpen(labelkey)}
                            onMouseOut={e => setOpen('')}
                            className={`menu-l${level} pos-relative ${itemOtherLevelClassName}`}
                        >
                            <a className={linkClassName} href={data[labelkey]['href']}>
                                <AauMixinIcon color={fgColorL1}  icon='' size='tiny' /> {data[labelkey]['name']}
                                {
                                    data[labelkey][''] !== null && <AauMixinBadgeVersion
                                        className={`font-8 fit-content pl-1 pr-1 ml-2 mt-3`}
                                        text={data[labelkey]['badge_text']}
                                        state={data[labelkey]['badge_state']}
                                    />
                                }
                            </a>
                        </div>
                    ) : (
                        <div
                            key={`menu-l${level}-${labelkey}`}
                            onMouseOver={e => setOpen(labelkey)}
                            onMouseOut={e => setOpen('')}
                            className={`menu-l${level} pos-relative ${level === 1 ? `float-left ${itemFirstLevelClassName}` : `${itemOtherLevelClassName}`}`}
                        >
                            <span className={linkClassName}>
                                {level > 1 ? <AauMixinIcon color={fgColorL1}  icon={open === labelkey  ? 'circle-left' : 'circle-right'} size='tiny' /> : null} {labelkey}
                            </span>
                            <div className={`menu-l${level} z-${level+menuLvl} pos-absolute text-nowrap fit-content ${open === labelkey ? 'd-block' : 'd-none'} bd-2 bd-${fgColorL1} mt-2 ${level > 1 ? 'mt-2-minus ml-16' : ''}`}>
                                <AauComponentMenuHorizontalItem
                                    data={data[labelkey]}
                                    level={level + 1}
                                    itemFirstLevelClassName={itemFirstLevelClassName}
                                    itemOtherLevelClassName={itemOtherLevelClassName}
                                    linkClassName={linkClassName}
                                    lang={lang}
                                    config={config}
                                    bgColorL1={bgColorL1}
                                    bgColorL2={bgColorL2}
                                    bgColorMain={bgColorMain}
                                    fgColorL1={fgColorL1}
                                    darkMode={darkMode}
                                />
                            </div>
                        </div>
                    );
                })
            }
            <div className='float-clear'></div>
        </div>
    );
};
AauComponentMenuHorizontalItem.defaultProps = defaultProps;
