/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';
import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {AauMixinFab} from './aauMixinFab';
import AauToolsConfiguration from "../aauToolsConfiguration/aauToolsConfiguration";


export interface AauMixinFabPredined {
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    url?: string;
}

const defaultProps = {
} as AauMixinFabPredined;

export const AauMixinFabGoToDown: FC<AauMixinFabPredined> = props => {
    const { lang, config } = props;

    return <AauMixinFab
        lang={lang}
        config={config}        
        tooltip={lang.getBtn('go_to_down')}
        pos_px_bottom={10}
        icon='arrow-down'
        iconazure='Down'
        bgColor='l3-grey-med'
        fgColor='l3-white'
        mouseClickHandler={() => window.scroll(0, document.body.scrollHeight)}
    />;
};
AauMixinFabGoToDown.defaultProps = defaultProps;

export const AauMixinFabGoToUp: FC<AauMixinFabPredined> = props => {
    const {lang, config} = props;

    return <AauMixinFab
        lang={lang}
        config={config}            
        tooltip={lang.getBtn('go_to_up')}
        pos_px_bottom={60}
        icon='arrow-up'
        iconazure='Up'
        bgColor='l3-grey-med'
        fgColor='l3-white'
        mouseClickHandler={() => window.scrollTo(0, 0)}
    />;
};
AauMixinFabGoToDown.defaultProps = defaultProps;

export const AauMixinFabBack: FC<AauMixinFabPredined> = props => {
    const {lang, config, url} = props;

    return <AauMixinFab
        lang={lang}
        config={config}            
        tooltip={lang.getBtn('go_back')}
        pos_px_bottom={110}
        icon='arrow-big-undo'
        iconazure='Undo'
        bgColor='l1-grey-dark'
        mouseClickHandler={() => window.location.href = url}
    />;
};
AauMixinFabBack.defaultProps = defaultProps;

export const AauMixinFabAdd: FC<AauMixinFabPredined> = props => {
    const {lang, config, url} = props;

    return <AauMixinFab
        lang={lang}
        config={config}            
        tooltip={lang.getBtn('create')}
        pos_px_bottom={160}
        icon='plus'
        iconazure='PageAdd'
        bgColor='l3-green-dark'
        fgColor='l3-white'
        mouseClickHandler={() => window.location.href = url}
    />;
};
AauMixinFabAdd.defaultProps = defaultProps;

export const AauMixinFabEdit: FC<AauMixinFabPredined> = props => {
    const {lang, config, url} = props;

    return <AauMixinFab
        lang={lang}
        config={config}            
        tooltip={lang.getBtn('edit')}
        pos_px_bottom={260}
        icon='pen'
        iconazure='PencilReply'
        bgColor='l3-orange'
        mouseClickHandler={() => window.location.href = url}
    />;
};
AauMixinFabEdit.defaultProps = defaultProps;

export const AauMixinFabView: FC<AauMixinFabPredined> = props => {
    const {lang, config, url} = props;

    return <AauMixinFab
        lang={lang}
        config={config}            
        tooltip={lang.getBtn('view')}
        pos_px_bottom={260}
        icon='eye'
        iconazure='View'
        bgColor='l3-grey'
        mouseClickHandler={() => window.location.href = url}
    />;
};
AauMixinFabView.defaultProps = defaultProps;

export const AauMixinFabRecordHistory: FC<AauMixinFabPredined> = props => {
    const {lang, config, url} = props;

    return <AauMixinFab
        lang={lang}
        config={config}            
        tooltip={lang.getBtn('history')}
        pos_px_bottom={310}
        icon='history'
        iconazure='TimeEntry'
        bgColor='l3-white'
        mouseClickHandler={() => window.location.href = url}
    />;
};
AauMixinFabRecordHistory.defaultProps = defaultProps;
