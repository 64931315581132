/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {AauMixinIconButton} from '../aauMixinIconButton/aauMixinIconButton';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';
import {ComboBox, IComboBoxOption} from '@fluentui/react';

export interface AauComponentTablesNavigationProps {
    recordCount: number;
    recordTotalCount: number;
    pageIndex: number;
    onPageChange: any;
    displayRows: number;
    displayRowsList: number[];
    onDisplayRowChange: any;
    lang: AauToolsLang;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauComponentTablesNavigationProps;

export const AauComponentTablesNavigation: FC<AauComponentTablesNavigationProps> = props => {
    const { recordCount, recordTotalCount, pageIndex, onPageChange, displayRows, displayRowsList, onDisplayRowChange,
            fgColorL1, lang} = props;

    let btn1:number;
    let btn2:number;
    let btn3:number;

    const numeric2 = 2;
    const numeric3 = 3;

    if ( pageIndex <= numeric2 ) {
        btn1 = 1;
        btn2 = numeric2;
        btn3 = numeric3;
    } else if ( pageIndex > Math.ceil(recordCount/displayRows)-numeric2 ) {
        btn1 = Math.ceil(recordCount/displayRows)-numeric2;
        btn2 = Math.ceil(recordCount/displayRows)-1;
        btn3 = Math.ceil(recordCount/displayRows);
    } else if ( pageIndex === Math.ceil(recordCount/displayRows)-numeric2 ) {
        btn1 = Math.ceil(recordCount/displayRows)-numeric3;
        btn2 = Math.ceil(recordCount/displayRows)-numeric2;
        btn3 = Math.ceil(recordCount/displayRows)-1;
    } else {
        btn1 = pageIndex-1;
        btn2 = pageIndex;
        btn3 = pageIndex+1;
    }

    const buttonNavFirst = <AauMixinIconButton
        key='navigation_first'
        className='mt-1'
        variant='outlined'
        size='tiny'
        bgColor={fgColorL1}
        iconazure={'Previous'}
        mouseClickHandler={() => onPageChange('pageIndex', 1)}
        tooltip={lang.getBtn('nav_first')}
    />;
    const buttonNavPrevious = <AauMixinIconButton
        key='navigation_previous'
        className='mt-1'
        variant='outlined'
        size='tiny'
        bgColor={fgColorL1}
        iconazure={'ChevronLeft'}
        mouseClickHandler={() => onPageChange('pageIndex', pageIndex === 1 ? 1 : pageIndex-1)}
        tooltip={lang.getBtn('nav_first')}
    />;
    const buttonNavP1 = <AauMixinIconButton
        key='navigation_p1'
        className='mt-1 font-16'
        variant='outlined_onlytext'
        size='tiny'
        bgColor={fgColorL1}
        mouseClickHandler={() => onPageChange('pageIndex', btn1)}
        tooltip={btn1.toString()}
    />;
    const buttonNavP2 = <AauMixinIconButton
        key='navigation_p2'
        className='mt-1 font-16'
        variant='outlined_onlytext'
        size='tiny'
        bgColor={fgColorL1}
        mouseClickHandler={() => onPageChange('pageIndex', btn2)}
        tooltip={btn2.toString()}
    />;
    const buttonNavP3 = <AauMixinIconButton
        key='navigation_p3'
        className='mt-1 font-16'
        variant='outlined_onlytext'
        size='tiny'
        bgColor={fgColorL1}
        mouseClickHandler={() => onPageChange('pageIndex', btn3)}
        tooltip={btn3.toString()}
    />;
    const buttonNavNext = <AauMixinIconButton
        key='navigation_next'
        className='mt-1'
        variant='outlined'
        size='tiny'
        bgColor={fgColorL1}
        iconazure={'ChevronRight'}
        mouseClickHandler={() => onPageChange('pageIndex', pageIndex === Math.ceil(recordCount/displayRows) ? Math.ceil(recordCount/displayRows) : pageIndex+1)}
        tooltip={lang.getBtn('nav_first')}
    />;
    const buttonNavLast = <AauMixinIconButton
        key='navigation_last'
        className='mt-1'
        variant='outlined'
        size='tiny'
        bgColor={fgColorL1}
        iconazure={'Next'}
        mouseClickHandler={() => onPageChange('pageIndex', Math.ceil(recordCount/displayRows))}
        tooltip={lang.getBtn('nav_last')}
    />;

    const optionsDisplayRows: IComboBoxOption[] = [];
    Object.keys(displayRowsList).map(key => optionsDisplayRows.push({key, text: displayRowsList[key].toString()}));

    const footer = <footer key='navigation' className='pos-relative d-block z-10'>
        <div key='navigation_stats_1' className={`d-block float-left mt-1 ml-2 fg-${fgColorL1}`}>
            Page {pageIndex} / {Math.ceil(recordCount/displayRows)} contenant
        </div>
        <ComboBox
            id={`navigation_displayRows`}
            key={`navigation_displayRows`}
            aria-labelledby={'navigation_displayRows'}
            className={`float-left width-px-60 mr-2 ml-2 mt-2 z-10 bd-${fgColorL1} fg-${fgColorL1}`}
            allowFreeform={true}
            autoComplete={'on'}
            onChange={(e, option, index, value) => {
                if ( typeof(option) !== 'undefined' ) {
                    onDisplayRowChange(option.text);
                }
            }}
            options={optionsDisplayRows}
            text={displayRows.toString()}
        />
        <div key='navigation_stats_2' className={`d-block float-left mt-1 fg-${fgColorL1}`}>
            &eacute;lements sur un total de {recordCount}
            { recordCount !== recordTotalCount ? ` dont (${recordTotalCount-recordCount} filtrés)` : null}
        </div>
        <div key='navigation_group_button' className='d-block float-right mr-2'>
            {recordCount !== 0 ? buttonNavFirst : null}
            {recordCount !== 0 && Math.ceil(recordCount/displayRows) >=2 ? buttonNavPrevious : null}
            {recordCount !== 0 ? buttonNavP1 : null}
            {recordCount !== 0 && Math.ceil(recordCount/displayRows) >=2 ? buttonNavP2 : null}
            {recordCount !== 0 && Math.ceil(recordCount/displayRows) >=3 ? buttonNavP3 : null}
            {recordCount !== 0 && Math.ceil(recordCount/displayRows) >=2 ? buttonNavNext : null}
            {recordCount !== 0 ? buttonNavLast : null}
        </div>
        <div key={'navigation_clear'} className='float-clear' />
    </footer>;

    //return Math.ceil(recordCount/displayRows) === 1 ? null : footer;
    return footer;
};
AauComponentTablesNavigation.defaultProps = defaultProps;


