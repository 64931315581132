/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauComponentMenuHorizontalItem} from './aauComponentMenuHorizontalItem';

export interface AauComponentMenuHorizontalProps {
    data: object;
    itemFirstLevelClassName?: string;
    itemOtherLevelClassName?: string;
    linkClassName?: string;
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorL2?: aauToolsColorEnum;
    bgColorMain?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode?: boolean;
}

const defaultProps = {
    itemFirstLevelClassName: 'p-3',
    itemOtherLevelClassName: 'p-1',
    linkClassName: 'text-nodecoration text-bold cursor-pointer'
} as AauComponentMenuHorizontalProps;

export const AauComponentMenuHorizontal: FC<AauComponentMenuHorizontalProps> = props => {

    const {data, itemFirstLevelClassName, itemOtherLevelClassName, linkClassName, lang, config, bgColorL1, bgColorL2, bgColorMain, fgColorL1, darkMode} = props;

    const linkClassNameBuild=`${linkClassName} fg-${fgColorL1} width-px-80`;

    let sitename = null;
    if ( config.getGlobal('sitelogourl') !== '' ) {
        sitename = <img alt='Site Logo' className={config.getGlobal('sitelogoclassname')} src={config.getGlobal('sitelogourl')} />;
    } else {
        sitename = config.getGlobal('sitename');
    }
    return  (
        <div className='width-100 pos-relative z-80'>
            <div className={`${itemFirstLevelClassName} float-left p-3 pl-8 pr-8 width-px-80`}>
                <a className={`${linkClassNameBuild}`} href='/'>
                    {sitename}
                </a>
            </div>
            <AauComponentMenuHorizontalItem
                data={data}
                level={1}
                itemFirstLevelClassName={`${itemFirstLevelClassName} bg-${bgColorL2}`}
                itemOtherLevelClassName={`${itemOtherLevelClassName} bg-${bgColorL2}`}
                linkClassName={`${linkClassName} fg-${fgColorL1}`}
                lang={lang}
                config={config}
                bgColorL1={bgColorL1}
                bgColorL2={bgColorL2}
                bgColorMain={bgColorMain}
                fgColorL1={fgColorL1}
                darkMode={darkMode}
            />
        </div>
    );
};
AauComponentMenuHorizontal.defaultProps = defaultProps;
