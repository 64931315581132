/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {AauComponentTableColumnProps, AauComponentTableColumnPropsDefault} from './tableHeadColumn.d';
import {AauComponentTablesFilters} from './tableFilters';

import {IIconProps} from '@fluentui/react/lib/Icon';
import {SearchBox} from '@fluentui/react/lib/SearchBox';
import {ComboBox, DatePicker, DayOfWeek, IComboBoxOption, ICalendarStrings} from '@fluentui/react';

import dayjs from 'dayjs';
import {AauMixinIcon} from '../aauMixinIcon/aauMixinIcon';


export const AauComponentTableHeadColumn: FC<AauComponentTableColumnProps> = props => {
    const { showFilter, name, width, mixin, filter, filterValue, enums, sort, primary, setFilters, title, columns,
            parentSetColumns, sortSetColumn, lang, config, bgColorL1, bgColorMain, fgColorL1, data, darkMode} = props;

    let filterCond = null;
    let filterElement;
    switch(filter) {
        case 'textField':
            filterElement = <SearchBox
                id={`filter_${name}`}
                key={`filter_${name}`}
                name={`filter_${name}`}
                iconProps={{ iconName: 'Filter' } as IIconProps}
                role='search'
                value={typeof(filterValue) !== 'undefined' && filterValue !== null ? filterValue.toString() : ''}
                placeholder={lang.getText('search')}
                onChange={(e, newValue) => setFilters(name, newValue, primary)}
                onClear={e => setFilters(name, '', primary)}
                styles={{
                    root: {
                        borderColor: typeof(filterValue) !== 'undefined' && filterValue !== null && filterValue !== '' ? config.getColor('fluent_ui_palette' , darkMode)['palette']['themePrimary'] : config.getColor('fluent_ui_palette' , darkMode)['palette']['neutralTertiary']
                    }
                }}
            />;
            break;
        case 'select':
            const filterCondMono = filterValue !== null && filterValue !== '' && typeof(filterValue) !== 'undefined' && filterValue !== '_NOFILTER_';
            const filterValueTmpMono = filterCondMono ? enums[filterValue.toString()] : lang.getText('Tous', 'filter', 'Tous');

            const optionsSingle: IComboBoxOption[] = [
                {key: '_NOFILTER_', text: lang.getText('Tous', 'filter', 'Tous')}
            ];

            if ( ['switch', 'switch_state', 'btn_state'].includes(mixin) ) {
                optionsSingle.push({key: 'true', text: lang.getText('true', 'filter')});
                optionsSingle.push({key: 'false', text: lang.getText('false', 'filter')});
            } else {
                Object.keys(enums).sort(function (first, second) {
                    let ret = 0;

                    if (first !== '' && second !== '' && first !== null && second !== null) {
                        if (enums[first].toString().toLowerCase() < enums[second].toString().toLowerCase()) {
                            ret = -1;
                        } else if (enums[first].toString().toLowerCase() > enums[second].toString().toLowerCase()) {
                            ret = 1;
                        } else {
                            //NOSONAR
                        }
                    }

                    return ret;
                }).map(key => optionsSingle.push({key, text: enums[key]}));
            }

            filterElement = <ComboBox
                id={`filter_${name}`}
                key={`filter_${name}`}
                aria-labelledby={name}
                allowFreeform={true}
                autoComplete={'on'}
                onChange={(e, option, index, value) => {
                    if (typeof (option) !== 'undefined' ) {
                        setFilters(name, option['key'].toString(), primary);
                    }
                }}
                options={optionsSingle}
                text={filterValueTmpMono}
                useComboBoxAsMenuWidth={true}
                styles={{
                    root: {
                        selectors: {
                            ':after': {
                                border: `${typeof (filterValue) !== 'undefined' && filterValue !== null && filterValue !== '' ? config.getColor('fluent_ui_palette', darkMode)['palette']['themePrimary'] : config.getColor('fluent_ui_palette', darkMode)['palette']['neutralTertiary']} 1px solid`
                            }
                        }
                    }
                }}
            />;
            break;
        case 'multiselect':
            const filterCondMulti = filterValue !== null && filterValue !== '' && typeof(filterValue) !== 'undefined';
            const filterValueTmpMulti = filterCondMulti ? enums[filterValue.toString()] : lang.getText('Tous', 'filter', 'Tous');

            const optionsMulti: IComboBoxOption[] = [];

            if ( ['switch', 'switch_state', 'btn_state'].includes(mixin) ) {
                optionsMulti.push({key: 'true', text: lang.getText('true', 'filter')});
                optionsMulti.push({key: 'false', text: lang.getText('false', 'filter')});
            } else {
                Object.keys(enums).sort(function (first, second) {
                    let ret = 0;

                    if (first !== '' && second !== '' && first !== null && second !== null) {
                        if (enums[first].toString().toLowerCase() < enums[second].toString().toLowerCase()) {
                            ret = -1;
                        } else if (enums[first].toString().toLowerCase() > enums[second].toString().toLowerCase()) {
                            ret = 1;
                        } else {
                            //NOSONAR
                        }
                    }

                    return ret;
                }).map(key => optionsMulti.push({key, text: enums[key]}));
            }

            filterElement = <ComboBox
                id={`filter_${name}`}
                key={`filter_${name}`}
                selectedKey={filterValue as string[]}
                aria-labelledby={name}
                allowFreeform={true}
                autoComplete={'on'}
                onChange={(e, option, index, value) => {
                    if (typeof (option) !== 'undefined' ) {
                        setFilters(name, option['key'].toString(), primary, true);
                    }
                }}
                options={optionsMulti}
                multiSelect
                text={filterValueTmpMulti}
                useComboBoxAsMenuWidth={true}
                styles={{
                    root: {
                        selectors: {
                            ':after': {
                                border: `${typeof (filterValue) !== 'undefined' && filterValue !== null && filterValue !== '' ? config.getColor('fluent_ui_palette', darkMode)['palette']['themePrimary'] : config.getColor('fluent_ui_palette', darkMode)['palette']['neutralTertiary']} 1px solid`
                            }
                        }
                    }
                }}
            />;
            break;
        case 'rangeint':
        case 'rangedint':
            filterElement = <div className={`width-px-120`}>
                <SearchBox
                    id={`filter_${name}_min`}
                    key={`filter_${name}_min`}
                    name={`filter_${name}_min`}
                    iconProps={{ iconName: 'ChevronRightSmall' } as IIconProps}
                    role='search'
                    value={filterValue !== null && typeof(filterValue) === 'object' && filterValue.hasOwnProperty('min') && filterValue['min'] !== null ? filterValue['min'] : ''}
                    placeholder={lang.getText('filter_min')}
                    onChange={(e, newValue) => setFilters(`${name}@min`, newValue !== null && newValue !==  '' ? parseInt(newValue, 10) : '', primary)}
                    onClear={e => setFilters(`${name}@min`, null, primary)}
                    styles={{
                        root: {
                            borderColor: filterValue !== null && typeof(filterValue) === 'object' && filterValue.hasOwnProperty('min') && !isNaN(filterValue['min']) ? config.getColor('fluent_ui_palette' , darkMode)['palette']['themePrimary'] : config.getColor('fluent_ui_palette' , darkMode)['palette']['neutralTertiary']
                        }
                    }}
                />
                <SearchBox
                    id={`filter_${name}_max`}
                    key={`filter_${name}_max`}
                    name={`filter_${name}_max`}
                    iconProps={{ iconName: 'ChevronLeftSmall' } as IIconProps}
                    role='search'
                    value={ filterValue !== null && typeof(filterValue) === 'object' && filterValue.hasOwnProperty('max') && filterValue['max'] !== null && !Number.isNaN(filterValue['max']) ? filterValue['max'] : ''}
                    placeholder={lang.getText('filter_max')}
                    onChange={(e, newValue) => setFilters(`${name}@max`, newValue !== null && newValue !==  '' ? parseInt(newValue, 10) : '', primary)}
                    onClear={e => setFilters(`${name}@max`, null, primary)}
                    styles={{
                        root: {
                            borderColor: filterValue !== null && typeof(filterValue) === 'object' && filterValue.hasOwnProperty('max') && filterValue['max'] !== null && !isNaN(filterValue['max']) ? config.getColor('fluent_ui_palette' , darkMode)['palette']['themePrimary'] : config.getColor('fluent_ui_palette' , darkMode)['palette']['neutralTertiary']
                        }
                    }}
                />
            </div>;
            break;
        case 'rangedate':
            filterCond = filterValue !== null && typeof(filterValue) === 'object';
            const dFrom = filterCond && filterValue.hasOwnProperty('from') ? new Date(filterValue['from']) : null;
            const dTo = filterCond && filterValue.hasOwnProperty('to') ? new Date(filterValue['to']) : null;

            const months = [];
            const shortMonths = [];
            const days = [];
            const shortDays = [];

            const padLength = 2;
            const monthLength = 12;
            const dayLength = 6;

            for ( let i = 1; i <= monthLength; i++ ) {
                months.push(lang.getText(`month_long_${i.toString().padStart(padLength, '0')}`));
                shortMonths.push(lang.getText(`month_short_${i.toString().padStart(padLength, '0')}`));
            }

            for ( let i = 0; i <= dayLength; i++ ) {
                days.push(lang.getText(`day_long_${i.toString().padStart(padLength, '0')}`));
                shortDays.push(lang.getText(`day_short_${i.toString().padStart(padLength, '0')}`));
            }

            const extractDate = dValue => `${dValue.getFullYear()}-${(dValue.getMonth() + 1).toString().padStart(2, '0')}-${dValue.getDate().toString().padStart(2, '0')}`;

            filterElement = <div className={`width-px-120`}>
                <DatePicker
                    firstDayOfWeek={DayOfWeek.Monday}
                    showWeekNumbers={true}
                    firstWeekOfYear={1}
                    formatDate={date => dayjs(date).format('DD/MM/YYYY').toString()}
                    value={dFrom !== null ? new Date(extractDate(dFrom)) : null}
                    onSelectDate={date => {
                        setFilters(`${name}@from`, date, primary);
                    }}
                    strings={{days, shortDays, months, shortMonths, goToToday: lang.getText('goToToday')} as ICalendarStrings}
                    isMonthPickerVisible={true}
                    showMonthPickerAsOverlay={false}
                    styles={{
                        root: {
                            '.ms-TextField-fieldGroup': {
                                borderColor: dFrom !== null ? config.getColor('fluent_ui_palette' , darkMode)['palette']['themePrimary'] : config.getColor('fluent_ui_palette' , darkMode)['palette']['neutralTertiary']
                            }
                        }
                    }}
                />
                <DatePicker
                    firstDayOfWeek={DayOfWeek.Monday}
                    showWeekNumbers={true}
                    firstWeekOfYear={1}
                    formatDate={date => dayjs(date).format('DD/MM/YYYY').toString()}
                    value={dTo !== null ? new Date(extractDate(dTo)) : null}
                    onSelectDate={date => {
                        setFilters(`${name}@to`, date, primary);
                    }}
                    strings={{days, shortDays, months, shortMonths, goToToday: lang.getText('goToToday')} as ICalendarStrings}
                    isMonthPickerVisible={true}
                    showMonthPickerAsOverlay={false}
                    styles={{
                        root: {
                            '.ms-TextField-fieldGroup': {
                                borderColor: dTo !== null ? config.getColor('fluent_ui_palette' , darkMode)['palette']['themePrimary'] : config.getColor('fluent_ui_palette' , darkMode)['palette']['neutralTertiary']
                            }
                        }
                    }}
                />
            </div>;
            break;
        default:
            filterElement = null;
    }

    let cssClassName = null;
    if ( name === 'btnactiongroup' ) {
        cssClassName = 'width-px-180-min width-px-180-max';
    } else if ( ['rangedint', 'rangedate'].includes(filter)  || ['str_to_icon'].includes(mixin) ) {
        cssClassName = 'width-px-120';
    } else if ( name === 'id' ) {
        cssClassName = 'width-px-70-min width-px-70-max';
    } else if ( ['btn_state', 'toggle', 'switch_state', 'switch'].includes(mixin) ) {
        cssClassName = 'width-px-75-min width-px-75-max';
    } else if ( ['persona'].includes(mixin) ) {
        cssClassName = `width-px-90-min width-px-90-max`;
    } else if ( ['rating', 'str_date'].includes(mixin) ) {
        cssClassName = `width-px-150-min width-px-150-max`;
    } else if ( width !== null ) {
        cssClassName = `width-px-${width}`;
    } else {
        cssClassName = 'fit-content';
    }

    const titleContent = <div className={`mx-auto fit-content`}>
        <div className={`width-70`} style={{display: 'table-cell', verticalAlign: 'middle'}}>{title}</div>
        <div className={`width-30 pt-2`} style={{display: 'table-cell', verticalAlign: 'middle'}}>
            <AauMixinIcon
                size='tiny'
                icon=''
                iconazure={sort === 'asc' || sort === 'none' ? 'Ascending' : 'Descending'}
                color={sort !== 'none' ? bgColorMain : 'l3-white'}
                className='ml-2 '
                mouseClickHandler={() => {
                    sortSetColumn(name, sort === 'asc' ? 'desc' : 'asc')
               }}
            />
        </div>
        <div className='float-clear'></div>
    </div>;

    return (
        <th attr-name={name} className={`text-bold mixin-${mixin} ${cssClassName}`}>
            {
                showFilter === false || name !== 'btnactiongroup'
                ? null :
                <AauComponentTablesFilters
                    data={data}
                    columns={columns}
                    parentSetColumns={parentSetColumns}
                    lang={lang}
                    bgColorL1={bgColorL1}
                    fgColorL1={fgColorL1}
                />
            }
            {showFilter || name === 'btnactiongroup' ? null : titleContent}
            {showFilter ? filterElement : null}
        </th>
    );
};
AauComponentTableHeadColumn.defaultProps = AauComponentTableColumnPropsDefault;
