/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useParams, useNavigate} from 'react-router-dom';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {AauMixinPageHeader} from '../aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../aauMixinLoader/aauMixinLoader';

import {AauMixinFabBack, AauMixinFabEdit, AauMixinFabView, AauMixinFabRecordHistory} from '../aauMixinFab/aauMixinFabPredined';
import axios from 'axios';
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauComponentForm} from '../aauComponentForm/aauComponentForm';
import {AauMixinStrYaml} from "../aauMixinStrYaml/aauMixinStrYaml";

export interface AauPageFormProps {
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorL2?: aauToolsColorEnum;
    bgColorMain?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    bgColorMain: 'l4-grey' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauPageFormProps;

export const AauPageForm: FC<AauPageFormProps> = props => {
    const {lang, config, bgColorL2, fgColorL1, bgColorMain} = props;
    const {module, action, identifier, admin} = useParams();
    const [data, setData] = useState(null);

    const history = useNavigate();

    let content = <AauMixinLoader />;
    let content_extra = null;

    const submitHandler = async (formData, itemid) => {
        if ( Object.keys(formData).length > 0 ) {
            await axios.post(
                `/rest/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}/save`,
                JSON.stringify({
                    identifier: itemid,
                    data: formData
                }),
                {
                    baseURL: `${config.getGlobal('back')}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                    }
                }
            ).then(res => {
                if (res.data.success === true) {
                    const msg = lang.getNotification('save', true);
                    toast.success(msg.replace('%s', res.data.name));
                    history(`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`);
                } else {
                    const msg = lang.getNotification('save', false);
                    const msgDetail = lang.getText(res.data.msg);
                    toast.error(msg.replace('%s', res.data.name) + ' - ' + msgDetail);
                }
            }).catch(err => {
                const unauthorizedError = 401;
                const notFoundError = 404;
                if ( err.response.status === unauthorizedError ) {
                    if ( !err.response.data.hasOwnProperty("action") ) {
                        Cookies.remove('JWT');
                        Cookies.remove('user');
                        Cookies.remove('rank');
                        Cookies.remove('displayname');
                        window.location.href = `/login?redirect=${window.location.pathname}`;
                    } else {
                        window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                    }
                } else if ( err.response.status === notFoundError ) {
                    window.location.href = `/notfound`;
                }
            });
        } else {

            window.location.href = `/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`;
        }
    };

    const submitAndActionHandler = async (formData, itemid) => {
        if ( Object.keys(formData).length > 0 ) {
            await axios.post(
                `/rest/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}/saveAndPush`,
                JSON.stringify({
                    identifier: itemid,
                    data: formData
                }),
                {
                    baseURL: `${config.getGlobal('back')}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                    }
                }
            ).then(res => {
                if (res.data.success === true) {
                    const msg = lang.getNotification('save', true);
                    toast.success(msg.replace('%s', res.data.name));
                    history(`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`);
                } else {
                    const msg = lang.getNotification('save', false);
                    const msgDetail = lang.getText(res.data.msg);
                    toast.error(msg.replace('%s', res.data.name) + ' - ' + msgDetail);
                }
            }).catch(err => {
                const unauthorizedError = 401;
                const notFoundError = 404;
                if ( err.response.status === unauthorizedError ) {
                    if ( !err.response.data.hasOwnProperty("action") ) {
                        Cookies.remove('JWT');
                        Cookies.remove('user');
                        Cookies.remove('rank');
                        Cookies.remove('displayname');
                        window.location.href = `/login?redirect=${window.location.pathname}`;
                    } else {
                        window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                    }
                } else if ( err.response.status === notFoundError ) {
                    window.location.href = `/notfound`;
                }
            });
        } else {
            window.location.href = `/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`;
        }
    };

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/${action}/${identifier}`,
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    if ( data !== null && data !== false ) {
        let canSaveAndAction = data.hasOwnProperty('can_save_and_action') ? data['can_save_and_action'] : null;
        let content_form = <AauComponentForm
            columns={data['columns']}
            item={data['item']}
            readonly={action === 'view' || data['can_edit'] === false ? true : false}
            saveHandler={action === 'view' ? null : formData => submitHandler(formData, identifier)}
            saveActionHandler={canSaveAndAction === null ? null : formData => submitAndActionHandler(formData, identifier)}
            saveAndActionLabel={canSaveAndAction !== null ? data['save_and_action_label'] : 'save and ' + canSaveAndAction}
            template={data['template_form']}
            lang={lang}
            config={config}
            fgColorL1={fgColorL1}
            bgColorMain={bgColorMain}
        />;
        if ( data['extra_information'] !== null ) {
            switch (data['extra_information']['kind'].toLowerCase()) {
                case 'yaml':
                    content_extra = <AauMixinStrYaml value={data['extra_information']['value']} />;
                    break;
                case 'text':
                    content_extra = <pre>
                        {data['extra_information']['value']}
                    </pre>;
                    break;
                case 'json':
                    content_extra = "Extra JSON Text - Not implemented";
                    break;
            }
        }

        if ( content_extra !== null ) {
            content = <div>
                <div className={`p-2 float-left width-60`}>
                    {content_form}
                </div>
                <div className={`p-2 float-left width-35 overflow-auto`}>
                    {content_extra}
                </div>
                <div className={`float-clear`}></div>
            </div>;
        } else {
            content = content_form;
        }

    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <div className='width-vw-99 height-vh-95-min'>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
                config={config}
                bgColorL2={bgColorL2}
                fgColorL1={fgColorL1}
            />
            <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-87'>
                {content}
            </div>
            <AauMixinFabBack lang={lang} config={config} url={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`} />
            {
                data !== null && data !== false && data['can_view'] && window.location.pathname.indexOf('/edit/') !== -1 ?
                <AauMixinFabView lang={lang} config={config} url={`/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/view/${identifier}`} /> :
                null
            }
            {
                data !== null && data !== false && data['can_edit'] && window.location.pathname.indexOf('/view/') !== -1 ?
                <AauMixinFabEdit lang={lang} config={config} url={`/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/edit/${identifier}`} /> :
                null
            }
            {
                data !== null && data !== false && data['can_history_compare'] ?
                <AauMixinFabRecordHistory lang={lang} config={config} url={`/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/history/${identifier}`} /> :
                null
            }
        </div>
    );
};
AauPageForm.defaultProps = defaultProps;
