import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {initializeIcons} from '@fluentui/font-icons-mdl2'; //SONAR
import {HelmetProvider} from 'react-helmet-async';

initializeIcons();

const container = document.querySelector('#root');
const root = createRoot(container!);

root.render(
    <StrictMode>
        <HelmetProvider>
            <App key="AauGenerator" />
        </HelmetProvider>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.debug);
