/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {AauComponentTableBodyColumn} from './tableBodyColumn';
import {AauComponentTableColumnProps} from './tableHeadColumn.d';
import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';


export interface AauComponentTableBodyRowProps {
    rowKey: string;
    columns: AauComponentTableColumnProps[];
    data: object[];
    bgRowColor: string;
    fgRowColor: string;
    refreshTable?: () => void;
    columnid: string;
    canEdit: boolean;
    canView: boolean;
    canSaveAndAction: string|null;
    saveAndActionLabel?: string;
    viewAction?: string;
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorMain?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode?: boolean;
    handlerPanelInfo?: (url:string) => void;
}

const defaultProps = {
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    canEdit: false,
    canView: false,
    canSaveAndAction: null,
    viewAction: 'view',
    darkMode: false
} as AauComponentTableBodyRowProps;

export const AauComponentTableBodyRow: FC<AauComponentTableBodyRowProps> = props => {
    const { rowKey, columns, data, canEdit, canView, canSaveAndAction, saveAndActionLabel, viewAction, columnid,
            refreshTable, bgRowColor, fgRowColor, lang, config, bgColorL1, bgColorMain, fgColorL1, darkMode,
            handlerPanelInfo} = props;

    return (
        <tr key={rowKey} className={`${bgRowColor} fg-${fgRowColor}`}>
            {
                Object.keys(columns).map(cid => {
                    let ret = null;

                    let columnForm = [];

                    let col = columns[cid]

                    const canViewTmp  = canView;
                    let canEditTmp = canEdit && col['inline_edit'];
                    if ( canEditTmp ) {
                        const displayRankField = col.hasOwnProperty('rank_field') ? col['rank_field'] : null;
                        const displayRankFilters = col.hasOwnProperty('rank_filters') ? col['rank_filters'] : {};
                        const displayState = col.hasOwnProperty('displayState') ? col['displayState'] : null;
                        const displayField = col.hasOwnProperty('displayField') ? col['displayField'] : null;
                        const displayFieldValue = col.hasOwnProperty('displayFieldValue') ? col['displayFieldValue'] : [];

                        if (displayState !== null && data.hasOwnProperty('state') && !displayState.includes(data['state'])) {
                            canEditTmp = false;
                        }

                        if (displayField !== null && data.hasOwnProperty(displayField) && !displayFieldValue.includes(data[displayField])) {
                            canEditTmp = false;
                        }

                        if (displayRankField !== null
                            && data.hasOwnProperty(displayRankField)
                            && ((
                                    displayRankFilters.hasOwnProperty(data[displayRankField])
                                    && displayRankFilters[data[displayRankField].toString()] === false
                                ) || !displayRankFilters.hasOwnProperty(data[displayRankField]))
                        ) {
                            canEditTmp = false;
                        }

                        columnForm.push(col);
                    }

                    if (col['state'] === true) {
                        ret = <AauComponentTableBodyColumn
                            key={`td_${rowKey}_${col['name']}`}
                            rowKey={`${rowKey}_${col['name']}`}
                            value={data[col['name']]}
                            dataJson={data}
                            mixin={col['mixin']}
                            component={col['component']}
                            refreshTable={refreshTable}
                            columnid={columnid}
                            columnForm={columnForm}
                            canView={canViewTmp}
                            canEdit={canEditTmp}
                            canSaveAndAction={canSaveAndAction}
                            saveAndActionLabel={saveAndActionLabel}
                            viewAction={viewAction}
                            lang={lang}
                            config={config}
                            bgColorL1={bgColorL1}
                            bgColorMain={bgColorMain}
                            fgColorL1={fgColorL1}
                            darkMode={darkMode}
                            handlerPanelInfo={handlerPanelInfo}
                        />;
                    }

                    return ret;
                })
            }
        </tr>
    );

};
AauComponentTableBodyRow.defaultProps = defaultProps;
