/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauFormLabel} from '../aauFormLabel/aauFormLabel';

import { Editor } from '@tinymce/tinymce-react';
import HTMLReactParser from 'html-react-parser';


export interface AauFormEditorWysiwigProps {
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    label: string;
    value?: string;
    validator: object;
    parentFunction?: any;
    helper?: string | null;
    plugins?: string;
    toolbars?: string;
    required?: boolean | null;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    label: null,
    readonly: false,
    validator: {},
    parentFunction: null,
    value: '',
    helper: null,
    plugins: 'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker searchreplace ' +
             'wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking save table ' +
             'contextmenu directionality emoticons template paste textcolor',
    toolbars: 'insertfile undo redo | styleselect | bold italic underline strikethrough subscript superscript | ' +
              'alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | '+
              'print preview media fullpage | forecolor backcolor emoticons | removeformat | help',
    required: false,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauFormEditorWysiwigProps;

export const AauFormEditorWysiwig: FC<AauFormEditorWysiwigProps> = props => {
    const validateField = (validateValue):void => {
        let isValid = true;

        if (required === true && (validateValue === '' || validateValue === null)) {
            isValid = false;
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( ((state['isValid'] === null && isValid === false) || state['isValid'] !== null) && parentFunction !== null ) {
                parentFunction(itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                'content': validateValue
            });
        }
    };

    let content;
    const {readonly, itemKey, name, value, parentFunction, helper, toolbars, plugins, required, fgColorL1, label} = props;

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': value
    });
    if (state['isValid'] === null && readonly === false ) {
        validateField(value);
    }

    if ( readonly === false) {
        content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={false}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />
            <div className='mt-3'>
                <Editor
                    id={`tinymce-${itemKey}`}
                    key={`${itemKey}-ctrl`}
                    apiKey='sd4casgcmwtdkie0l3m7puqpqg7m2cb9uyyx17odebiywmyo'
                    value={state['content']}
                    onEditorChange={editorContent => {
                        validateField(editorContent);
                    }}
                    init={{
                        height: 400,
                        plugins: `${plugins}`,
                        toolbar: `${toolbars}`,
                        toolbar_mode: 'floating',
                        tinycomments_mode: 'embedded',
                        tinycomments_author: 'Author name'
                    }}
                />
            </div>
        </div>;
    } else {
       content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={true}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />

            <div className={`view-value pt-9 fg-${fgColorL1}`}>
                {HTMLReactParser(state['content'])}
            </div>
        </div>;
    }

    return content;
};
AauFormEditorWysiwig.defaultProps = defaultProps;
