/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {ComboBox, IComboBoxOption} from '@fluentui/react';
import {SearchBox} from '@fluentui/react/lib/SearchBox';
import {Toggle} from '@fluentui/react/lib/Toggle';


export interface AauMixinFiltersProps {
    lang: AauToolsLang;
    filters: AauMixinFiltersItemProps[];
}

export interface AauMixinFiltersItemProps {
    component: string;
    name: string;
    width: number;
    options ?: IComboBoxOption[];
    changeHandler: any;
    value?: string | boolean;
}

export const AauMixinFilters: FC<AauMixinFiltersProps> = props => {
    const { lang, filters} = props;

    return <div key='filters_container' className='height-50 pl-10 pr-10 mt-5 mx-auto bg-1 bd'>
        {
            Object(filters).map(f =>{
                let content = null;
                switch ( f['component'] ) {
                    case 'text':
                    case 'textbox':
                    case 'search':
                       content = <SearchBox
                            id={`filter_${f['name']}`}
                            key={`filter_${f['name']}`}
                            name='text'
                            role='search'
                            placeholder={lang.getText('search')}
                            onChange={(e, newValue)=> f['changeHandler'](newValue)}
                            onClear={e => f['changeHandler']('')}
                        />;
                        break;
                    case 'select':
                        content = <ComboBox
                            id={`filter_${f['name']}`}
                            key={`filter_${f['name']}`}
                            aria-labelledby={f['name']}
                            allowFreeform={true}
                            autoComplete={'on'}
                            onChange={(e, option, index, value) => {
                                if (typeof (option) !== 'undefined' ) {
                                    f['changeHandler'](option['key'].toString());
                                }
                            }}
                            options={f['options']}
                            text={f['value']}
                        />;
                        break;
                    case 'toggle':
                    case 'switch':
                        content = <div className='float-left mt-1 ml-5'>
                            <Toggle
                                id={`filter_${f['name']}`}
                                key={`filter_${f['name']}`}
                                checked={f['value']}
                                onText={lang.getFilter(f['name'], 'on')}
                                offText={lang.getFilter(f['name'], 'off')}
                                role='switch'
                                onChange={()=> f['changeHandler'](!f['value'])}
                            />
                        </div>;
                        break;
                    default:
                        content = 'Not implemented';
                        break;
                }

                return <div key={`filter_${f['name']}_container`} className={`float-left width-px-${f['width']} mr-1 ml-1`}>
                    {content}
                </div>;
            })
        }
        <div className='float-clear' />
    </div>;
};
