/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useParams} from 'react-router-dom';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../../Components/aauToolsConfiguration/aauToolsConfiguration';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';

import {AauMixinFabView, AauMixinFabBack} from '../../Components/aauMixinFab/aauMixinFabPredined';
import axios from 'axios';
import Cookies from 'js-cookie';
import {aauToolsColorEnum} from '../../Components/aauToolsColor/aauToolsColor';

import ReactDiffViewer, {DiffMethod} from '@terran-one/react-diff-viewer';


export interface AauPageDiffFileProps {
    action: string;
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL2?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode?: boolean;
}

const defaultProps = {
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    darkMode: false

} as AauPageDiffFileProps;

export const AauPageDiffFile: FC<AauPageDiffFileProps> = props => {
    const {lang, action, config, bgColorL2, fgColorL1, darkMode} = props;
    const {admin, module, identifier} = useParams();
    const [data, setData] = useState(null);

    let content = <AauMixinLoader />;

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/${action}/${identifier}`,
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    if ( data !== null && data !== false ) {
        content = <ReactDiffViewer
            oldValue={data['info']['codes'][0]['code']}
            newValue={data['info']['codes'][1]['code']}
            splitView={true}
            showDiffOnly={false}
            compareMethod={DiffMethod.WORDS}
            extraLinesSurroundingDiff={0}
            leftTitle={data['info']['codes'][0]['name']}
            rightTitle={data['info']['codes'][1]['name']}
            useDarkTheme={darkMode}
        />;
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    const numeric2 = 2;

    return (
        <div className='width-vw-99 height-vh-95-min'>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
                config={config}
                bgColorL2={bgColorL2}
                fgColorL1={fgColorL1}
            />
            <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-87'>
                {content}
            </div>
            <AauMixinFabView
                lang={lang}
                config={config}
                url={`${window.location.pathname.split('/').splice(0, window.location.pathname.split('/').length-numeric2).join('/')}/view/${identifier}`}
            />
            <AauMixinFabBack
                lang={lang}
                config={config}
                url={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`}
            />
        </div>
    );
};
AauPageDiffFile.defaultProps = defaultProps;
