/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC, useRef, useState} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauFormText} from '../aauFormText/aauFormText';
import {AauFormInput} from '../aauFormInput/aauFormInput';
import {AauFormSelect} from '../aauFormSelect/aauFormSelect';
import {AauFormSlider} from '../aauFormSlider/aauFormSlider';
import {AauFormDateTime} from '../aauFormDateTime/aauFormDateTime';
import {AauFormEditorCode} from '../aauFormEditorCode/aauFormEditorCode';
import {AauFormUpload} from '../aauFormUpload/aauFormUpload';
import {AauDynamicAccordion} from '../aauDynamicAccordion/aauDynamicAccordion';
import {AauFormBoolean} from '../aauFormBoolean/aauFormBoolean';
import {AauFormEditorWysiwig} from '../aauFormEditorWysiwig/aauFormEditorWysiwig';
import {confirmAlert} from 'react-confirm-alert';
import {AauMixinIconButton} from '../aauMixinIconButton/aauMixinIconButton';
import {AauMixinFab} from '../aauMixinFab/aauMixinFab';
import {AauFormRating} from '../aauFormRating/aauFormRating';
import {AauFormPeoplePicker} from '../aauFormPeoplePicker/aauFormPeoplePicker';
import {AauFormJson} from "../aauFormJson/aauFormJson";

export interface AauComponentFormProps {
    columns: object;
    item?: object;
    mainForm?: boolean;
    readonly?: boolean;
    dynamicKey?: string;
    parentFunction?: any; // NOSONAR
    saveHandler?: (object)=>void;
    saveActionHandler?: (object)=>void | null;
    saveAndActionLabel?: string;
    inDialog?: boolean;
    refHandler?: any;
    className?: string;
    template?: object;
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    fgColorL1?: aauToolsColorEnum;
    bgColorMain?: aauToolsColorEnum;
}

const defaultProps = {
    item: {},
    inDialog: false,
    mainForm: true,
    readonly: false,
    dynamicKey: null,
    saveHandler: null,
    saveActionHandler: null,
    saveAndActionLabel: 'Save and Action',
    template: null,
    className: '',
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    bgColorMain: 'l3-white' as aauToolsColorEnum
} as AauComponentFormProps;

export const AauComponentForm: FC<AauComponentFormProps> = props => {
    const { columns, item, mainForm, readonly, dynamicKey, parentFunction, saveHandler, saveActionHandler,
            saveAndActionLabel, inDialog, lang, config, fgColorL1, bgColorMain, className, template} = props;

    const submitBtnKO = useRef(null);
    const submitBtnOK = useRef(null);
    const submitActionBtnKO = useRef(null);
    const submitActionBtnOK = useRef(null);

    const [state, setState] = useState({
        updated: Date.now(),
        content: inDialog === true ? {} : item,
        valid: {}
    });

    //const state:object = ;

    const formSetData = (keyName: string, value: any, valid: boolean): void => {
        const subKeyNameIndex = 2;
        const key = mainForm ? keyName : keyName.split('_')[subKeyNameIndex];

        state['updated'] = Date.now();
        state['content'][key] = value;
        state['valid'][key] = valid;

        const index = keyName.split('_')[1];
        if (parentFunction !== null && typeof (parentFunction) !== 'undefined' && mainForm === false && typeof (index) !== 'undefined') {
            parentFunction(parseInt(index, 10), key, value, !Object.values(state['valid']).includes(false));
        }

        if ( submitBtnOK.current !== null || submitBtnKO.current !== null ) {
            if (Object.values(state['valid']).filter(x => x === false).length > 0) {
                submitBtnOK.current.style.display = 'none';
                submitBtnKO.current.style.display = 'block';
                submitBtnKO.current.innerHTML = Object.values(state['valid']).filter(x => x === false).length;
            } else {
                submitBtnKO.current.style.display = 'none';
                submitBtnOK.current.style.display = 'block';
            }
        }

        if ( submitActionBtnOK.current !== null || submitActionBtnKO.current !== null ) {
            if (Object.values(state['valid']).filter(x => x === false).length > 0) {
                submitActionBtnOK.current.style.display = 'none';
                submitActionBtnKO.current.style.display = 'block';
                submitActionBtnKO.current.innerHTML = Object.values(state['valid']).filter(x => x === false).length;
            } else {
                submitActionBtnKO.current.style.display = 'none';
                submitActionBtnOK.current.style.display = 'block';
            }
        }

        setState(state);
    };

    let formControlBtn = null;
    if (mainForm && !inDialog) {
        formControlBtn = (
            <div>
                {
                    saveActionHandler !== null && <AauMixinFab
                        lang={lang}
                        config={config}
                        refHandler={submitActionBtnOK}
                        tooltip={saveAndActionLabel}
                        variant='full'
                        iconazure='SaveAndClose'
                        bgColor='l3-green'
                        pos_px_bottom={210}
                        mouseClickHandler={() => {
                             saveActionHandler(state['content']);
                         }}
                    />
                }
                {
                    saveActionHandler !== null && <AauMixinFab
                        lang={lang}
                        config={config}
                        refHandler={submitActionBtnKO}
                        tooltip={saveAndActionLabel}
                        variant='full'
                        iconazure='SaveAndClose'
                        bgColor='l3-red'
                        pos_px_bottom={210}
                        mouseClickHandler={() => {
                            confirmAlert({
                                title: `${config.getGlobal('sitename')}`,
                                message: lang['formErrorFix'],
                                customUI: ({onClose}) => {
                                    const closeHandler = () => {
                                        onClose();
                                    };
                                    return (
                                        <div className={`confirm-ui fit-content p-10 bg-l4-grey fg-l3-white`}>
                                            <h1 className={`text-center`}>{config.getGlobal('sitename')}</h1>
                                            <p className={`text-center`}>{lang.getText('fixformerror')} ({Object.values(state['valid']).filter(x => x === false).length})</p>
                                            <AauMixinIconButton
                                                variant='righticontext'
                                                size='tiny'
                                                bgColor='l3-green-dark'
                                                fgColor='l3-white'
                                                icon='checkmark'
                                                className='width-90 text-bold'
                                                classContainer='mx-auto mt-8 width-50 float-left'
                                                mouseClickHandler={closeHandler}
                                                type='div'
                                                tooltip={lang.getBtn('corriger')}
                                            />
                                            <div className='float-clear'></div>
                                        </div>
                                    );
                                }
                            });
                        }}
                    />
                }
                {
                    saveHandler !== null && <AauMixinFab
                        lang={lang}
                        config={config}
                        refHandler={submitBtnOK}
                        tooltip={lang.getBtn('save')}
                        variant='full'
                        iconazure='Save'
                        bgColor='l3-green'
                        pos_px_bottom={160}
                        mouseClickHandler={() => {
                            saveHandler(state['content']);
                        }}
                    />
                }
                {
                    saveHandler !== null && <AauMixinFab
                        lang={lang}
                        config={config}
                        refHandler={submitBtnKO}
                        tooltip={lang.getBtn('save')}
                        variant='full'
                        iconazure='Save'
                        bgColor='l3-red'
                        pos_px_bottom={160}
                        mouseClickHandler={() => {
                             confirmAlert({
                                 title: `${config.getGlobal('sitename')}`,
                                 message: lang['formErrorFix'],
                                 customUI: ({onClose}) => {
                                     const closeHandler = () => {
                                         onClose();
                                     };
                                     return (
                                         <div className={`confirm-ui fit-content p-10 bg-l4-grey fg-l3-white`}>
                                             <h1 className={`text-center`}>{config.getGlobal('sitename')}</h1>
                                             <p className={`text-center`}>{lang.getText('fixformerror')} ({Object.values(state['valid']).filter(x => x === false).length})</p>
                                             <AauMixinIconButton
                                                 variant='righticontext'
                                                 size='tiny'
                                                 bgColor='l3-green-dark'
                                                 fgColor='l3-white'
                                                 icon='checkmark'
                                                 className='width-90 text-bold'
                                                 classContainer='mx-auto mt-8 width-50 float-left'
                                                 mouseClickHandler={closeHandler}
                                                 type='div'
                                                 tooltip={lang.getBtn('corriger')}
                                             />
                                             <div className='float-clear'></div>
                                         </div>
                                     );
                                 }
                             });
                         }}
                    />
                }
            </div>
        );
    } else if ( mainForm && inDialog && saveHandler !== null ) {
        formControlBtn = (
            <div className={`mt-2`}>
                { saveHandler === null ? null : <AauMixinIconButton
                        variant='righticontext'
                        size='tiny'
                        bgColor='l3-green-dark'
                        fgColor='l3-white'
                        icon=''
                        className='width-100 text-bold'
                        classContainer={`mx-auto width-${saveActionHandler !== null ? 29 : 100} float-left`}
                        mouseClickHandler={() => {
                            if (Object.values(state['valid']).filter(x => x === false).length > 0) {
                                confirmAlert({
                                    title: `${config.getGlobal('sitename')}`,
                                    message: lang['formErrorFix'],
                                    customUI: ({onClose}) => {
                                        const closeHandler = () => {
                                            onClose();
                                        };
                                        return (
                                            <div className={`confirm-ui fit-content p-10 bg-l4-grey fg-l3-white`}>
                                                <h1 className={`text-center`}>{config.getGlobal('sitename')}</h1>
                                                <p className={`text-center`}>{lang.getText('fixformerror')} ({Object.values(state['valid']).filter(x => x === false).length})</p>
                                                <AauMixinIconButton
                                                    variant='righticontext'
                                                    size='tiny'
                                                    bgColor='l3-green-dark'
                                                    fgColor='l3-white'
                                                    icon='checkmark'
                                                    className='width-90 text-bold'
                                                    classContainer='mx-auto mt-8 width-50 float-left'
                                                    mouseClickHandler={closeHandler}
                                                    type='div'
                                                    tooltip={lang.getBtn('corriger')}
                                                />
                                                <div className='float-clear'></div>
                                            </div>
                                        );
                                    }
                                });
                            } else {
                                saveHandler(state['content']);
                            }
                        }}
                        type='div'
                        tooltip={lang.getBtn('save')}
                    />
                }
                { saveActionHandler === null ? null : <AauMixinIconButton
                    variant='righticontext'
                    size='tiny'
                    bgColor='l3-green-dark'
                    fgColor='l3-white'
                    icon=''
                    className='width-100 text-bold'
                    classContainer={`mx-auto width-69 float-right`}
                    mouseClickHandler={() => {
                        if (Object.values(state['valid']).filter(x => x === false).length > 0) {
                            confirmAlert({
                                title: `${config.getGlobal('sitename')}`,
                                message: lang['formErrorFix'],
                                customUI: ({onClose}) => {
                                    const closeHandler = () => {
                                        onClose();
                                    };
                                    return (
                                        <div className={`confirm-ui fit-content p-10 bg-l4-grey fg-l3-white`}>
                                            <h1 className={`text-center`}>{config.getGlobal('sitename')}</h1>
                                            <p className={`text-center`}>{lang.getText('fixformerror')} ({Object.values(state['valid']).filter(x => x === false).length})</p>
                                            <AauMixinIconButton
                                                variant='righticontext'
                                                size='tiny'
                                                bgColor='l3-green-dark'
                                                fgColor='l3-white'
                                                icon='checkmark'
                                                className='width-90 text-bold'
                                                classContainer='mx-auto mt-8 width-50 float-left'
                                                mouseClickHandler={closeHandler}
                                                type='div'
                                                tooltip={lang.getBtn('corriger')}
                                            />
                                            <div className='float-clear'></div>
                                        </div>
                                    );
                                }
                            });
                        } else {
                            saveActionHandler(state['content']);
                        }
                    }}
                    type='div'
                    tooltip={saveAndActionLabel}
                />
                }
            </div>
        );
    } else {
        //NOSONAR
    }

    const getComponent = c => {
        const co: object = c.hasOwnProperty('component') ? c['component'] : {};
        const key: string = c['name'];
        const itemKey = (dynamicKey !== null ? `${dynamicKey}_${key}` : key);
        let mixin = co.hasOwnProperty('role') && co['role'] !== '' ? co['role'] : c['field_type'];

        let value = state['content'].hasOwnProperty(key) ? state['content'][key] : (state.hasOwnProperty(key) ? state[key] : ( inDialog === true ? item[key] : null));
        if (Object.keys(state['content']).length === 0 && inDialog === false) {
            value = c['default'];
        }

        const validator = c['validator'];
        const editState = c.hasOwnProperty('edit_state') && c['edit_state'] !== null ? c['edit_state'] : false;
        const editRank = c.hasOwnProperty('edit_rank') ? c['edit_rank'] : false;
        const viewState = c.hasOwnProperty('view_state') && c['view_state'] !== null ? c['view_state'] : false;
        const viewRank = c.hasOwnProperty('view_rank') ? c['view_rank'] : false;

        let readOnlyTmp = true;
        if ( mainForm === false ) {
            readOnlyTmp = c['readonly'] === true ? true : readonly;
        }
        if ( saveHandler !== null ) {
            readOnlyTmp = editState !== false ? false : (c['readonly'] === true ? true : readonly);
        }

        if (mainForm === true
            && (
                editRank === false
                || (
                    editState !== false
                    && (
                        (Object.keys(state['content']).length > 0 && editState.indexOf(state['content']['state']) === -1)
                        || (Object.keys(state['content']).length === 0 && editState.indexOf(0) === -1)
                    )
                )
            )
        ) {
            readOnlyTmp = true;
        }

        if (mainForm === true
            && (
                readOnlyTmp
                && (viewRank === false
                    || ['btnactiongroup', 'isDeleted'].includes(itemKey)
                    || (
                        viewState !== false
                        && (
                            (Object.keys(state['content']).length > 0 && viewState.indexOf(state['content']['state']) === -1)
                            || (Object.keys(state['content']).length === 0 && viewState.indexOf(0) === -1)
                        )
                    )
                )
            )
        ) {
            readOnlyTmp = true;
            mixin = 'HIDDEN';
        }

        console.debug("Main Form: " + mainForm + " - Save Handler: " + (saveHandler !== null) + " - Key: " + key + " - Mixin: " + mixin + " - ReadOnly: " + readonly + " - ReadOnlyTmp: " + readOnlyTmp + " - editRank: " + editRank + " - editState: " + editState + " - viewRank: " + viewRank + " - viewState: " + viewState);

        let content;
        switch (mixin) {
            case 'VARCHAR':
                content = <AauFormInput
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    helper={co['helper']}
                    prefix={c['prefix']}
                    suffix={c['suffix']}
                    isInteger={false}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                    fgColorL1={fgColorL1}
                />;
                break;
            case 'TEXT':
                content = <AauFormText
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    helper={co['helper']}
                    rows={co.hasOwnProperty('rows') ? co['rows'] : 10}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                    fgColorL1={fgColorL1}
                />;
                break;
            case 'ENUM':
                content = <AauFormSelect
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value !== null ? value.toString() : ''}
                    helper={co['helper']}
                    multiple={c.hasOwnProperty('multiple') ? c['multiple'] : co['multiple']}
                    dualmode={co['dualmode']}
                    parentFunction={formSetData}
                    enums={co['enums']}
                    {...validator}
                    lang={lang}
                    fgColorL1={fgColorL1}
                    reactValue={state['content']['nanespace']}
                />;
                break;
            case 'peoplepicker':
                content = <AauFormPeoplePicker
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value !== null ? value.toString() : ''}
                    helper={co['helper']}
                    multiple={c['multiple']}
                    parentFunction={formSetData}
                    enums={co['enums']}
                    {...validator}
                    lang={lang}
                    fgColorL1={fgColorL1}
                    backurl={config.getGlobal('back')}
                    config={config}
                />;
                break;
            case 'text_code':
                content = <AauFormEditorCode
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    helper={co['helper']}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                    fgColorL1={fgColorL1}
                />;
                break;
            case 'text_wysiwyg':
                content = <AauFormEditorWysiwig
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    helper={co['helper']}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                    fgColorL1={fgColorL1}
                />;
                break;
            case 'slider':
                content = <AauFormSlider
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    helper={co['helper']}
                    parentFunction={formSetData}
                    min={co['min']}
                    max={co['max']}
                    step={co['step']}
                    marks={co['marks']}
                    {...validator}
                    lang={lang}
                    fgColorL1={fgColorL1}
                />;
                break;
            case 'INTEGER':
            case 'BIGINT':
            case 'NUMERIC':
            case 'FLOAT':
            case 'INT':
            case 'DOUBLE_PRECISION':
                content = <AauFormInput
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    helper={co['helper']}
                    prefix={c['prefix']}
                    suffix={c['suffix']}
                    min={co['min']}
                    max={co['max']}
                    isInteger={true}
                    type='number'
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                    fgColorL1={fgColorL1}
                />;
                break;
            case 'TIMESTAMP WITH TIME ZONE':
            case 'DATETIME':
            case 'DATE':
            case 'TIME':
            case 'TIMESTAMP':
                content = <AauFormDateTime
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    helper={co['helper']}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                    fgColorL1={fgColorL1}
                />;
                break;
            case 'BOOLEAN':
            case 'BOOLEANINT':
                content = <AauFormBoolean
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    helper={co['helper']}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                    fgColorL1={fgColorL1}
                />;
                break;
            case 'UPLOAD':
            case 'BYTES':
                content = <AauFormUpload
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    helper={co['helper']}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                    fgColorL1={fgColorL1}
                />;
                break;
            case 'rating':
                content = <AauFormRating
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    helper={co['helper']}
                    max={co['max']}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                    fgColorL1={fgColorL1}
                />;
                break;
            case 'JSON':
                content = <AauFormJson
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    name={key}
                    label={c['label']}
                    value={value}
                    helper={co['helper']}
                    parentFunction={formSetData}
                    {...validator}
                    lang={lang}
                    fgColorL1={fgColorL1}
                />;
                break;
            case 'dynamicForm':
                content = <AauDynamicAccordion
                    key={itemKey}
                    readonly={readOnlyTmp}
                    itemKey={itemKey}
                    columns={co['subForm']}
                    titleStr={co.hasOwnProperty('substate_title') ? co['substate_title'] : `{${co['subForm'][0]['name']}}`}
                    name={key}
                    label={c['label']}
                    value={(value !== null && value.hasOwnProperty(key)) ? value[key] : []}
                    helper={co['helper']}
                    substate={c['subState']}
                    parentFunction={formSetData}
                    dynamicKey={dynamicKey}
                    mainForm={false}
                    {...validator}
                    lang={lang}
                    fgColorL1={fgColorL1}
                />;
                break;
            case 'HIDDEN':
                content = '';
                break;
            default:
                content = <div className={`mb-5 text-bold fg-${fgColorL1}`}>
                    {itemKey} with {mixin} is not implemented
                </div>;
        }

        return content;
    };

    const buildForm = () => {
        const colInfo = {};

        Object.keys(columns).forEach(cid => {
            const c: object = columns[cid];
            let content = getComponent(c);
            if ( content !== '' ) {
                colInfo[c['name'].toLowerCase()] = content;
            }
        });

        let rowContent;
        let component = 0;
        return <div className='ms-Grid'>
            {
                Object.keys(template).map(rid => {

                    return <div className='ms-Grid-row'>
                        {
                            Object.entries(template[rid]).map(([key, value]) => {
                                const cname = Object.keys(value)[0];
                                const csize = value[cname];

                                if ( colInfo.hasOwnProperty(cname.toLowerCase()) ) {
                                    rowContent = true;
                                    component += 1;
                                }

                                let content = null;
                                if ( cname !== '-' ) {
                                    content = <div className={`ms-Grid-col ms-sm${csize} pr-3 pl-3 float-left`}>
                                        {colInfo[cname.toLowerCase()]}
                                    </div>;
                                } else {
                                    if ( rowContent === true && component < Object.keys(colInfo).length ) {
                                        content = <div className={`ms-Grid-col ms-sm12 p-8`}>
                                            <hr className={`width-80 bd-1 bd-${bgColorMain}`} />
                                        </div>;
                                    }
                                    rowContent = false;
                                }

                                return content;
                            })
                        }
                    </div>;
                })
            }
        </div>;
    };

    const buildSubForm = () => {
        return Object.keys(columns).map(cid => {
            const c: object = columns[cid];

            return getComponent(c);
        });
    };

    return (
        <div className={`pos-relative z-5 width-100`}>
            {
                mainForm ?
                <form  className={`mx-auto width-100 ${className}`}>{inDialog || template === null? buildSubForm() : buildForm()}</form> :
                <div className={`${mainForm ? 'width-90 p-2' : 'pt-5 pr-10 pl-10 pb-10'} ${className}`}>{buildSubForm()}</div>
            }
            {saveHandler !== null ? formControlBtn : null}
        </div>
    );
};
AauComponentForm.defaultProps = defaultProps;
