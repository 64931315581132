/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {Toggle} from '@fluentui/react/lib/Toggle';


export interface AauMixinSwitchBoolProps {
    value:  any;
}

export const AauMixinSwitchBool: FC<AauMixinSwitchBoolProps> = props => {
    const {value} = props;

    return <div className='width-px-40 mx-auto pos-relative'>
        <Toggle
            checked={value === 1 || value === true ? true : false}
            role='switch'
        />
        <div className='pos-absolute z-10 width-px-40 height-px-20 top-0 d-block' />
    </div>;
};
