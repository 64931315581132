/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import Graph from 'react-graph-vis';


export interface AauMixinCartoVisProps {
    nodes: object[];
    edges: object[];
    events?: object;
    color: string;
    networkHandler: any;
    sprintLength?: number;
}

const defaultProps = {
    events: {},
    sprintLength: 200
} as AauMixinCartoVisProps;

export const AauMixinCartoVis: FC<AauMixinCartoVisProps> = props => {
    const { nodes, edges, events, color, networkHandler, sprintLength} = props;

    return <Graph
        id='graph-vis-network'
        key='graph-vis-network'
        graph={{
            edges,
            nodes
        }}
        options={{
            'autoResize': true,
            'height': '95%',
            'width': '95%',
            'nodes': {
                'borderWidth': 0.5,
                'shadow': {
                    'enabled': true
                },
                'shape': 'dot',
                'size': 13,
                'font': {
                    'color': `${color}`
                }
            },
            'edges': {
                'color': {
                    'inherit': 'both',
                    'opacity': 0.5
                },
                'smooth': {
                    'type': 'cubicBezier',
                    'forceDirection': 'none',
                    'roundness': 0.75
                }
            },
            'interaction': {
                'hover': true,
                'keyboard': {
                    'enabled': false
                },
                'navigationButtons': false,
                'selectable': false,
                'selectConnectedEdges': false
            },
            'manipulation': {
                'enabled': true
            },
            'physics': {
                'barnesHut': {
                    'gravitationalConstant': -3000,
                    'centralGravity': 0.2,
                    'springLength': sprintLength,
                    'avoidOverlap': 0.8
                },
                'maxVelocity': 20,
                'minVelocity': 0.75
            }
        }}
        events={events}
        getNetwork={network => {
            networkHandler(network);
         }}
    />;
};
AauMixinCartoVis.defaultProps = defaultProps;
