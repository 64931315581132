/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

export type MoneyType = 'euro' | 'dollar' | 'pound' | 'rubble' | 'yen';


export interface AauMixinStrAmountProps {
    'value': string | number | null;
    'moneyType': MoneyType;
}

const defaultProps = {
    'value': '',
    'moneyType': 'euro'
} as AauMixinStrAmountProps;

export const AauMixinStrAmount: FC<AauMixinStrAmountProps> = props => {
    const { value, moneyType } = props;

    let content;
    switch( moneyType ) {
        case 'euro':
            content = (
                <div className='text-bold text-right'>
                    {value} &euro;
                </div>
            );
            break;
        case 'dollar':
            content = (
                <div className='text-bold text-left'>
                    &#36; {value}
                </div>
            );
            break;
        case 'pound':
            content = (
                <div className='text-bold text-left'>
                    &pound; {value}
                </div>
            );
            break;
        case 'rubble':
            content = (
                <div className='text-bold text-right'>
                    {value} &#8381;
                </div>
            );
            break;
        case 'yen':
            content = (
                <div className='text-bold text-right'>
                    {value} &yen;
                </div>
            );
            break;
        default:
            //NOSONAR
    }

    return content;
};
AauMixinStrAmount.defaultProps = defaultProps;
