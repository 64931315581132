/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';
import './aau-colors.scss';

export const colorsVisCode = ['#CE93D8', '#B39DDB', '#B39DDB', '#9FA8DA', '#90CAF9', '#80DEEA', '#80CBC4', '#A5D6A7',
    '#E6EE9C', '#FFCC80', '#FFAB91', '#BCAAA4', '#B0BEC5', '#CC0000'];

export type aauToolsColorsBase = 'grey' | 'grey-med' | 'grey-dark' | 'red' | 'red-med' | 'red-dark' | 'orange' |
    'orange-med' | 'orange-dark' | 'green' | 'green-med' | 'green-dark' | 'yellow' | 'yellow-med' | 'sky' | 'sky-med' |
    'sky-dark' | 'blue' | 'blue-med' | 'blue-dark' | 'purple' | 'purple-med' | 'purple-dark' | 'pink' | 'pink-med' |
    'pink-dark'; // tslint:disable-line

export type aauToolsColorEnum = 'l1-black' | 'l2-black' | 'l3-black' | 'l3-white' | 'l1-grey'  | 'l2-grey'  |
    'l3-grey'  | 'l4-grey'  | 'l5-grey'  | 'l1-grey-med'  | 'l2-grey-med'  | 'l3-grey-med'  | 'l4-grey-med'  |
    'l5-grey-med'  | 'l1-grey-dark'  | 'l2-grey-dark'  | 'l3-grey-dark'  | 'l4-grey-dark'  | 'l5-grey-dark' |
    'l1-red'  | 'l2-red'  | 'l3-red'  | 'l4-red'  | 'l5-red'  | 'l1-red-med'  | 'l2-red-med'  | 'l3-red-med'  |
    'l4-red-med'  | 'l5-red-med'  | 'l1-red-dark'  | 'l2-red-dark'  | 'l3-red-dark'  | 'l4-red-dark'  | 'l5-red-dark' |
    'l1-orange'  | 'l2-orange'  | 'l3-orange'  | 'l4-orange'  | 'l5-orange'  | 'l1-orange-med'  | 'l2-orange-med'  |
    'l3-orange-med'  | 'l4-orange-med'  | 'l5-orange-med'  | 'l1-orange-dark'  | 'l2-orange-dark'  | 'l3-orange-dark'  |
    'l4-orange-dark'  | 'l5-orange-dark' | 'l1-green'  | 'l2-green'  | 'l3-green'  | 'l4-green'  | 'l5-green'  |
    'l1-green-med'  | 'l2-green-med'  | 'l3-green-med'  | 'l4-green-med'  | 'l5-green-med'  | 'l1-green-dark'  |
    'l2-green-dark'  | 'l3-green-dark'  | 'l4-green-dark'  | 'l5-green-dark' | 'l1-yellow'  | 'l2-yellow'  |
    'l3-yellow'  | 'l4-yellow'  | 'l5-yellow'  | 'l1-yellow-med'  | 'l2-yellow-med'  | 'l3-yellow-med'  |
    'l4-yellow-med'  | 'l5-yellow-med'  | 'l1-yellow-dark'  | 'l2-yellow-dark'  | 'l3-yellow-dark'  |
    'l4-yellow-dark'  | 'l5-yellow-dark' | 'l1-sky'  | 'l2-sky'  | 'l3-sky'  | 'l4-sky'  | 'l5-sky'  | 'l1-sky-med'  |
    'l2-sky-med'  | 'l3-sky-med'  | 'l4-sky-med'  | 'l5-sky-med'  | 'l1-sky-dark'  | 'l2-sky-dark'  | 'l3-sky-dark'  |
    'l4-sky-dark'  | 'l5-sky-dark' | 'l1-blue'  | 'l2-blue'  | 'l3-blue'  | 'l4-blue'  | 'l5-blue'  | 'l1-blue-med'  |
    'l2-blue-med'  | 'l3-blue-med'  | 'l4-blue-med'  | 'l5-blue-med'  | 'l1-blue-dark'  | 'l2-blue-dark'  |
    'l3-blue-dark'  | 'l4-blue-dark'  | 'l5-blue-dark' | 'l1-purple'  | 'l2-purple'  | 'l3-purple'  | 'l4-purple'  |
    'l5-purple'  | 'l1-purple-med'  | 'l2-purple-med'  | 'l3-purple-med'  | 'l4-purple-med'  | 'l5-purple-med'  |
    'l1-purple-dark'  | 'l2-purple-dark'  | 'l3-purple-dark'  | 'l4-purple-dark'  | 'l5-purple-dark' | 'l1-pink'  |
    'l2-pink'  | 'l3-pink'  | 'l4-pink'  | 'l5-pink'  | 'l1-pink-med'  | 'l2-pink-med'  | 'l3-pink-med'  |
    'l4-pink-med'  | 'l5-pink-med'  | 'l1-pink-dark'  | 'l2-pink-dark'  | 'l3-pink-dark'  | 'l4-pink-dark'  |
    'l5-pink-dark' | 'l1-ep-blue'  | 'l2-ep-blue'  | 'l3-ep-blue'  | 'l4-ep-blue'  |
    'l5-ep-blue' | 'l1-ep-red'  | 'l2-ep-red'  | 'l3-ep-red'  | 'l4-ep-red'  |
    'l5-ep-red'; // tslint:disable-line

export interface AauToolsColorProps {
    color?: aauToolsColorsBase;
    msg: string;
}

const defaultProps = {
    color: 'grey',
    msg: 'Ask And Use'
} as AauToolsColorProps;

export const AauToolsColor: FC<AauToolsColorProps> = props => {
    const { color, msg } = props;

    const tmp = [];
    const maxColorLevel = 5;
    for (let i = 1; i <= maxColorLevel; i++) {
        tmp.push(
            <tr className='bd-black'>
                <td align='center' className='p-4 text-bold'>l{i}-{color}</td>
                <td align='center' className='p-4'><div className={`bg-l${i}-${color} demo_bg demo_bd mx-auto`} /></td>
                <td align='center' className='p-4'><div className={`fg-l${i}-${color}`}>{msg}</div></td>
                <td align='center' className='p-4'><div className={`bd-l${i}-${color} demo_bg demo_bd`}/></td>
                <td align='center' className='p-4'><div className={`ol-l${i}-${color} demo_bg demo_ol`}/></td>
            </tr>
        );
    }

    return <table className='width-100'>
        <tr>
            <th className='width-20'>Color Name</th>
            <th className='width-20'>Background Color (bg-*)</th>
            <th className='width-20'>Foreground Color (fg-*)</th>
            <th className='width-20'>Border Color (bd-*)</th>
            <th className='width-20'>Outline Color (ol-*)</th>
        </tr>
        {
            tmp.map(i => i)
        }
    </table>;
};
AauToolsColor.defaultProps = defaultProps;
