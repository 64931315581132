/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import './aauComponentTable.css';

import {AauComponentTableHeadColumn} from './tableHeadColumn';
import {AauComponentTableColumnProps} from './tableHeadColumn.d';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';


export interface AauComponentTableHeadRowProps {
    rowId: string;
    showFilter: boolean;
    columns: AauComponentTableColumnProps[];
    parentSetColumns: any;
    sortSetColumn: any;
    lang: AauToolsLang;
    config?: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorMain?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    data?: object[];
    darkMode?: boolean;
}

const defaultProps = {
    columns: {},
    data: [],
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    bgColorMain: 'l3-black' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauComponentTableHeadRowProps;

export const AauComponentTableHeadRow: FC<AauComponentTableHeadRowProps> = props => {
    const { rowId, showFilter, columns, parentSetColumns, sortSetColumn, lang, config, bgColorL1, bgColorMain, fgColorL1,
            data, darkMode} = props;

    return (
        <tr>
            {
                Object.keys(columns).map(column => {
                    let ret = null;

                    if (columns[column]['state'] === true) {
                        ret = <AauComponentTableHeadColumn
                            data={data}
                            showFilter={showFilter}
                            key={`${rowId}_${columns[column]['name']}`}
                            name={columns[column]['name']}
                            label={columns[column]['label']}
                            mixin={columns[column]['mixin']}
                            width={columns[column]['width']}
                            enums={columns[column]['component']['enums']}
                            filter={columns[column]['filter']}
                            component={columns[column]['component']}
                            primary={columns[column]['primary']}
                            state={columns[column]['state']}
                            filterValue={columns[column]['filterValue']}
                            title={columns[column]['title']}
                            setFilters={columns[column]['setFilters']}
                            sort={columns[column]['sort']}
                            columns={columns}
                            parentSetColumns={parentSetColumns}
                            sortSetColumn={sortSetColumn}
                            lang={lang}
                            config={config}
                            bgColorL1={bgColorL1}
                            bgColorMain={bgColorMain}
                            fgColorL1={fgColorL1}
                            darkMode={darkMode}
                        />;
                    }

                    return ret;
                })
            }
        </tr>
    );
};
AauComponentTableHeadRow.defaultProps = defaultProps;
