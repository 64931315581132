/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useParams} from 'react-router-dom';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {AauMixinPageHeader} from '../aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../aauMixinLoader/aauMixinLoader';

import {AauMixinFabAdd} from '../aauMixinFab/aauMixinFabPredined';
import axios from 'axios';
import Cookies from 'js-cookie';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauComponentTable} from '../aauComponentTable/aauComponentTable';
import {AauMixinFab} from '../aauMixinFab/aauMixinFab';
import {toast} from 'react-toastify';
import nextId from 'react-id-generator';
import {Panel, PanelType} from '@fluentui/react/lib/Panel';
import {useBoolean} from '@fluentui/react-hooks';
import {AauComponentForm} from '../aauComponentForm/aauComponentForm';
import {AauMixinBtnGroup} from "../aauMixinBtnGroup/aauMixinBtnGroup";

export interface AauPageListProps {
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorL2?: aauToolsColorEnum;
    bgColorMain?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode?: boolean;
}

const defaultProps = {
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    darkMode: false
} as AauPageListProps;

export const AauPageList: FC<AauPageListProps> = props => {
    const {lang, config, bgColorL1, bgColorL2, bgColorMain, fgColorL1, darkMode} = props;
    const {module, admin} = useParams();
    const [data, setData] = useState(null);
    const [isPanelOpen, {setTrue: openPanel, setFalse: dismissPanel}] = useBoolean(false);
    const [dataPanel, setDatapanel] = useState(null);

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}`,
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    const handlerPanelInfo = async (url:string) => {
        await axios.get(
            `/rest/${url}`,
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setDatapanel(res.data);
            openPanel();
        }).catch(err => {
            const unauthorizedError = 401;
            if ( err.response.status === unauthorizedError ) {
                Cookies.remove('JWT');
                Cookies.remove('user');
                Cookies.remove('rank');
                Cookies.remove('displayname');
                window.location.href = `/login?redirect=${window.location.pathname}`;
            }
        });
    }

    let content = <AauMixinLoader />;
    let panelContent = null;

    if ( data !== null && data !== false ) {
        let canSaveAndAction = data.hasOwnProperty('can_save_and_action') ? data['can_save_and_action'] : null;
        content = <AauComponentTable
            className="top-px-87"
            columns={data['columns']}
            data={data['info']['data']}
            columnid={data['columnid']}
            displayRows={data['info'].hasOwnProperty('options') ? data['info']['options']['showEntries'] : config.getDefaultValues('display_rows')}
            displayRowsList={data['info'].hasOwnProperty('options') ? data['info']['options']['showEntriesList'] : config.getDefaultValues('display_rows_list')}
            refreshTable={retrieveData}
            lang={lang}
            config={config}
            bgColorL1={bgColorL1}
            bgColorMain={bgColorMain}
            fgColorL1={fgColorL1}
            canEdit={data.hasOwnProperty('can_edit') ? data['can_edit'] : false}
            canView={data.hasOwnProperty('can_view') ? data['can_view'] : false}
            canSaveAndAction={canSaveAndAction}
            saveAndActionLabel={data.hasOwnProperty('save_and_action_label') ? data['save_and_action_label'] : 'save and ' + canSaveAndAction}
            viewAction={data.hasOwnProperty('table_view_action') ? data['table_view_action'] : 'view'}
            darkMode={darkMode}
            handlerPanelInfo={handlerPanelInfo}
        />;

        if ( dataPanel !== null ) {
            let btns = dataPanel.hasOwnProperty('columns' ) && dataPanel['columns'][dataPanel['columns'].length -1]['name'] === 'btnactiongroup' ? dataPanel['columns'][dataPanel['columns'].length -1]['component'] : null;

            panelContent = <Panel
                isOpen={isPanelOpen}
                onDismiss={dismissPanel}
                type={PanelType.largeFixed}
                hasCloseButton={false}
                headerText={`${dataPanel['name']}`}
                closeButtonAriaLabel='Close'
                isLightDismiss={true}
                isBlocking={true}
            >
                {
                    dataPanel !== null && <div>
                        {
                            btns !== null && <AauMixinBtnGroup
                                btns={btns.hasOwnProperty('grpItems') ? btns['grpItems'] : {}}
                                dataJson={dataPanel['item']}
                                size='tinyiest'
                                align='right'
                                columnid={dataPanel['columnid']}
                                lang={lang}
                                config={config}
                                bgColorL1={bgColorL1}
                                fgColorL1={fgColorL1}
                                darkMode={darkMode}
                                rankField={btns.hasOwnProperty('rank_field') ? btns['rank_field'] : null}
                                optionalMode={false}
                            />
                        }
                        <AauComponentForm
                            className={'float-left'}
                            columns={dataPanel['columns']}
                            item={dataPanel['item']}
                            readonly={true}
                            saveHandler={null}
                            lang={lang}
                            config={config}
                            fgColorL1={fgColorL1}
                            bgColorMain={bgColorMain}
                        />
                    </div>
                }
            </Panel>;
        }

    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    const fabStartHeight = 210;
    const fabHeight = 50;

    return (
        <div className='width-vw-99 height-95-min'>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
                config={config}
                bgColorL2={bgColorL2}
                fgColorL1={fgColorL1}
            />
            <div className='top-50 p-5 pl-7 pr-15'>{content}</div>
            {
                data !== null && data !== false && data['can_create'] && <AauMixinFabAdd key={nextId()} lang={lang} config={config} url={`/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/create/0`} />
            }
            {
                data !== null && data !== false && Object.keys(data['fabs']).length > 0 ?
                Object.keys(data['fabs']).map((key, i) => {
                    var btn = data['fabs'][key];
                    var link_path = `${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}${ btn['component'].hasOwnProperty('url') ? btn['component']['url'] : `/${key}` }`;

                    return <AauMixinFab
                        lang={lang}
                        config={config}
                        key={nextId()}
                        tooltip={btn['label']}
                        pos_px_bottom={fabStartHeight + (fabHeight*i)}
                        icon={btn['component']['icon']}
                        iconazure={btn['component']['iconazure']}
                        bgColor={btn['component'][darkMode ? 'dark' : 'light']['bg_color']}
                        fgColor={btn['component'][darkMode ? 'dark' : 'light']['fg_color']}
                        confirm={btn['component']['confirm']}
                        mouseClickHandler={() => {
                            if ( btn['mixin'] === 'btn_link_with_url' ) {
                                window.open(btn['component']['url'], '_blank');
                            } else if ( btn['mixin'] === 'btn_link_page_id' ) {
                                window.location.href = `${config.getGlobal('front')}/${link_path}`;
                            } else if ( btn['mixin'] === 'btn_link_action_id' ) {
                                axios.get(
                                `/rest/${link_path}`,
                                {
                                        baseURL: `${config.getGlobal('back')}`,
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                                        }
                                    }
                                ).then(res => {
                                    if (res.data.success === true) {
                                        const msg = lang.getNotification(btn['name'], true);
                                        toast.success(msg.replace('%s', res.data.name));
                                        retrieveData();
                                    } else {
                                        const msg = lang.getNotification(btn['name'], false);
                                        toast.error(msg.replace('%s', res.data.name));
                                    }
                                }).catch(err => {
                                    const unauthorizedError = 401;
                                    if ( err.response.status === unauthorizedError ) {
                                        Cookies.remove('JWT');
                                        Cookies.remove('user');
                                        Cookies.remove('rank');
                                        Cookies.remove('displayname');
                                        window.location.href = `/login?redirect=${window.location.pathname}`;
                                    } else {
                                        const msg = lang.getError(err.response.data['code']);
                                        toast.error(msg.replace('%s', ''));
                                    }
                                });
                            } else {
                                //NOSONAR
                            }
                        }}
                    />;
                }) : null
            }
            {panelContent}
        </div>
    );
};
AauPageList.defaultProps = defaultProps;
