/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {AauMixinIconButton} from '../aauMixinIconButton/aauMixinIconButton';
import {aauSize} from '../aauMixinIcon/aauMixinIcon';
import AauToolsLang from '../aauToolsLang/aauToolsLang';


export interface AauMixinBtnBoolProps {
    value:  any;
    size?: aauSize;
    lang: AauToolsLang;
}

const defaultProps = {
    size: 'tiny'
} as AauMixinBtnBoolProps;

export const AauMixinBtnBool: FC<AauMixinBtnBoolProps> = props => {
    const { value, size, lang } = props;

    return <div className='mt-3 mx-auto'>
        <AauMixinIconButton
            variant='onlyicon'
            icon={value === 1 || value === true ? 'checkmark' : 'cross'}
            fgColor={value === 1 || value === true ? 'l4-green' : 'l4-red'}
            size={size}
            tooltip={lang.getBtn(value === 1 || value === true ? 'mixinEnabled' : 'mixinDisabled')}
            type='div'
            classContainer='fit-content mx-auto'
        />
    </div>;
};
AauMixinBtnBool.defaultProps = defaultProps;
