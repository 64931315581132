/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauFormLabel} from '../aauFormLabel/aauFormLabel';

import {AauMixinDate} from '../aauMixinDate/aauMixinDate';
import {TextField} from '@fluentui/react/lib/TextField';


export interface AauFormDateTimeProps {
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    label: string;
    value?: string;
    validator: object;
    parentFunction?: any;
    helper?: string | null;
    required?: boolean | null;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    label: null,
    readonly: false,
    validator: {},
    parentFunction: null,
    value: '',
    helper: null,
    required: false,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauFormDateTimeProps;

export const AauFormDateTime: FC<AauFormDateTimeProps> = props => {
    const validateField = (validateValue):void => {
        let isValid;
        if (required === true && (validateValue === '' || validateValue === null)) {
            isValid = false;
        } else {
            const dateRegExp = '^([0-4]{4}-(1[0-2]|0[1-9])-(3[0-1]|[1-2][0-9]|0[1-9]) (2[0-3]|1[0-9]|0[0-9]):([0-5][0-9]):([0-5][0-9]))(\\+[0-9]{2}:[0-9]{2})*$';
            isValid = RegExp(dateRegExp, 'g').test(validateValue);
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( state['isValid'] !== isValid && parentFunction !== null ) {
                parentFunction(itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                'content': validateValue
            });
        }
    };

    let content;
    const {readonly, itemKey, name, value, parentFunction, helper, required, fgColorL1, label} = props;

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': value
    });
    if (state['isValid'] === null && readonly === false ) {
        validateField(value);
    }

    const cssClassName = `bd-1 bd-l3-black bd-top-none bd-left-none bd-right-none`;
    if ( readonly === false) {
        content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
        <AauFormLabel
            key={`${name}-label`}
            isValid={state['isValid']}
            name={name}
            value={label}
            readonly={false}
            required={required}
            fgColorL1={fgColorL1}
            helper={helper}
        />
        <div className='mt-1'>
            <TextField
                id={itemKey}
                key={`${itemKey}-ctrl`}
                name={name}
                aria-labelledby={name}
                defaultValue={state['content']}
                inputClassName={cssClassName}
                onChange={(e, val) => validateField((val))}
                canRevealPassword={true}
                borderless
            />
        </div>
    </div>;
    } else {
       content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                isValid={state['isValid']}
                name={name}
                value={label}
                readonly={true}
                required={required}
                fgColorL1={fgColorL1}
                helper={helper}
            />
            <div className={`view-value pt-5 fg-${fgColorL1}`}>
                <AauMixinDate
                    value={state['content']}
                    mode='display'
                />
            </div>
        </div>;
    }

    return content;
};
AauFormDateTime.defaultProps = defaultProps;
