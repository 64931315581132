/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC, useState} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';
import {AauMixinIconButton} from '../aauMixinIconButton/aauMixinIconButton';

import Cookies from 'js-cookie';

import './aauComponentTable.css';

import {AauComponentTableColumnProps} from './tableHeadColumn.d';
import {Toggle} from '@fluentui/react/lib/Toggle';
import {CSVLink} from "react-csv";


export interface AauComponentTablesFiltersProps {
    columns: AauComponentTableColumnProps[];
    parentSetColumns: any;
    lang: AauToolsLang;
    data?: object[];
    bgColorL1?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    data: [],
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauComponentTablesFiltersProps;

export const AauComponentTablesFilters: FC<AauComponentTablesFiltersProps> = props => {
    const { data, columns, parentSetColumns, lang, bgColorL1, fgColorL1 } = props;

    const displayFilterClick = (key: string, state: boolean) => () => {
        const displayColumnStateTmp = [...displayColumnState];

        if ( state === true && !displayColumnStateTmp.includes(key)) {
            displayColumnStateTmp.push(key);
        } else if ( state === false && displayColumnStateTmp.includes(key)) {
            const index = displayColumnStateTmp.indexOf(key, 0);
            if (index > -1) {
               displayColumnStateTmp.splice(index, 1);
            }
        }
        setDisplayColumnState(displayColumnStateTmp);
        parentSetColumns(displayColumnStateTmp);
    };

    const displayColumnStateArray: string[] = [];
    const content = Object.keys(columns).map(key => {
        const c = columns[key];

        let filterContent = null;
        if ( c['state'] === true ) {
            displayColumnStateArray.push(c['name']);
        }

        if ( c['mixin'] !== 'grp' && c['name'] !== '' ) {
            filterContent = <div key={`displayColumn_${key}`} className={`p-2`} onClick={displayFilterClick(c['name'], !c['state'])}>
                <Toggle
                    id={`filter-column-${key}`}
                    key={`filter-column-${key}-ctrl`}
                    aria-labelledby={lang.getText(c['name'])}
                    checked={c['state']}
                    offText={c['label']}
                    onText={c['label']}
                    role='switch'
                />
            </div>;
        }

        return filterContent;
    });

    const [displayColumnFilter, setDisplayColumnFilter] = useState<boolean>(false);
    const [displayColumnState, setDisplayColumnState] = useState<string[]>(null);

    if ( displayColumnState === null ){
        setDisplayColumnState(displayColumnStateArray);
    }

    const csvSeparator = '";"';
    const csvContentArray = [];

    const savedFiltersLoad = JSON.parse(localStorage.getItem(Cookies.get('user')))['filters'];
    const savedModuleFilterLoad = savedFiltersLoad.hasOwnProperty(window.location.href) ? savedFiltersLoad[window.location.href] : {};

    const csvHeader = []
    Object.keys(columns).map(ckey => {
        const c = columns[ckey];
        const cname = c['name'];
        const mixin = c['mixin'];

        const co = c.hasOwnProperty('component') ? c['component'] : {};
        const optional = co.hasOwnProperty('optional') && co['optional'] === true;

        if ( !['grp'].includes(mixin) && (
            (
                savedModuleFilterLoad.hasOwnProperty(cname) && savedModuleFilterLoad[cname].hasOwnProperty('state') && savedModuleFilterLoad[cname]['state'] === true
            ) || (
                (!savedModuleFilterLoad.hasOwnProperty(cname) || !savedModuleFilterLoad[cname].hasOwnProperty('state')) && optional === true
            )
        )) {
            csvHeader.push(lang.getText(cname));
        }

        return null;
    });
    csvContentArray.push(`"${csvHeader.join(csvSeparator)}"`);

    Object.keys(data).map(dkey => {
        const csvTmp = []
        const d = data[dkey];

        Object.keys(columns).map(ckey => {
            const c = columns[ckey];
            const cname = c['name'];
            const mixin = c['mixin'];
            let dTmp = d[cname];

            const co = c.hasOwnProperty('component') ? c['component'] : {};
            //const o = c.hasOwnProperty('options') ? c['options'] : {};
            const optional = co.hasOwnProperty('optional') && co['optional'] === true;

            switch (mixin) {
                case 'btn_state':
                case 'toggle':
                case 'switch':
                case 'toggle_state':
                case 'switch_state':
                case 'boolean':
                case 'bool':
                    dTmp = dTmp === true ? 1 : 0;
                    break;
                case 'str_mask':
                    const usedMask = co.hasOwnProperty('used_mask') ? co['used_mask'] : false;
                    const maskFieldName = co.hasOwnProperty('mask_field') ? co['mask_field'] : '';
                    const maskField = usedMask && d.hasOwnProperty(maskFieldName) ? d[maskFieldName] : false;

                    dTmp = maskField === true ? '************' : dTmp;
                    break;
                case 'dynamicForm':
                case 'json':
                case 'jsonb':
                    dTmp = JSON.stringify(dTmp).replaceAll("'", "\\'").replaceAll('"', "'")
                    break;
            }

            if ( !['grp'].includes(mixin) && (
                (
                    !savedModuleFilterLoad.hasOwnProperty(cname) && optional === false
                ) || (
                    savedModuleFilterLoad.hasOwnProperty(cname) && savedModuleFilterLoad[cname].hasOwnProperty('state') && savedModuleFilterLoad[cname]['state'] === true
                )
            )) {
                csvTmp.push(dTmp);
            }

            return null;
        });

        csvContentArray.push(`"${csvTmp.join(csvSeparator)}"`);

        return null;
    });
    const csvContent = csvContentArray.join('\n');

    return (
        <div className={`d-block width-100`}>
            <AauMixinIconButton
                variant='outlined'
                classContainer='pos-relative float-right right-5'
                size='tiny'
                iconazure='ColumnOptions'
                bgColor={fgColorL1}
                mouseClickHandler={() => setDisplayColumnFilter(!displayColumnFilter)}
                tooltip={lang.getBtn('display_column_choice')}
            />
            <AauMixinIconButton
                variant='outlined'
                classContainer='pos-relative float-right right-5'
                size='tiny'
                iconazure='TripleColumn'
                bgColor={fgColorL1}
                mouseClickHandler={() => parentSetColumns('clearColumns')}
                tooltip={lang.getBtn('reset_column_choice_and_filter')}
            />
            <CSVLink
                data={csvContent}
                filename={`knowit_export_${window.location.pathname.split('/')[window.location.pathname.split('/').length-1]}.csv`}
            >
                <AauMixinIconButton
                    variant='outlined'
                    classContainer='pos-relative float-right right-5'
                    size='tiny'
                    iconazure='ExcelDocument'
                    bgColor={fgColorL1}
                    mouseClickHandler={() => {
                    }}
                    tooltip={lang.getBtn('export_to_csv')}
                />
            </CSVLink>
            {
                displayColumnFilter ?
                (
                    <div>
                        <div
                            className='pos-absolute d-block left-px-30-minus top-px-100-minus z-400 width-100 height-vh-100 bga-black-80'
                            onMouseMove={e => setDisplayColumnFilter(!displayColumnFilter)}
                        />
                        <div className={`pos-absolute bg-${bgColorL1} fg-${fgColorL1} bd-2 bd-${fgColorL1} right-px-10 top-px-55 z-401`}>
                            {content}
                        </div>
                    </div>
                ) :
                null
            }
        </div>
    );
};
AauComponentTablesFilters.defaultProps = defaultProps;
