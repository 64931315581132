/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useParams} from 'react-router-dom';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../../Components/aauToolsConfiguration/aauToolsConfiguration';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';

import {AauMixinFabBack} from '../../Components/aauMixinFab/aauMixinFabPredined';
import axios from 'axios';
import Cookies from 'js-cookie';
import {aauToolsColorEnum} from '../../Components/aauToolsColor/aauToolsColor';

import {AauMixinFilters} from '../../Components/aauMixinFilters/aauMixinFilters';
import {IComboBoxOption} from '@fluentui/react';
import {AauComponentLogFile} from '../../Components/aauComponentLogFile/aauComponentLogFile';


export interface AauPageLogHistoryProps {
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorL2?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode?: boolean;
}

const defaultProps = {
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    darkMode: false
} as AauPageLogHistoryProps;

export const AauPageLogHistory: FC<AauPageLogHistoryProps> = props => {
    const {lang, config, bgColorL2, fgColorL1} = props;
    const {module, identifier, admin} = useParams();
    const [data, setData] = useState(null);
    const [logLevel, setLogLevel] = useState<string>('all');
    const [nbrLigneToDisplay, setNbrLigneToDisplay] = useState<string>('5000');
    const [searchPattern, setSearchPattern] = useState<string>('');

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/cloudloghistory/${identifier}?lines=${nbrLigneToDisplay}`,
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    let content = <AauMixinLoader />;

    if ( data !== null && data !== false ) {
        content = <AauComponentLogFile
            value={data['info']['logs']}
            loglevel={logLevel}
            searchPattern={searchPattern}
        />;
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    const optionsNbrLigneToDisplay: IComboBoxOption[] = [
        {key: '10', text: 'Afficher 10 lignes'},
        {key: '1000', text: 'Afficher 1000 lignes'},
        {key: '2500', text: 'Afficher 2500 lignes'},
        {key: '5000', text: 'Afficher 5000 lignes'},
        {key: '7500', text: 'Afficher 7500 lignes'},
        {key: '10000', text: 'Afficher 10000 lignes'},
        {key: '15000', text: 'Afficher 15000 lignes'},
        {key: '20000', text: 'Afficher 20000 lignes'}
    ];

    const optionsLogLevel: IComboBoxOption[] = [
        {key: 'all', text: 'Tout afficher'},
        {key: 'debug', text: 'Afficher debug et plus'},
        {key: 'info', text: 'Afficher info et plus'},
        {key: 'warn', text: 'Afficher warn et plus'},
        {key: 'error', text: 'Afficher error et plus'}
    ];

    return (
        <div className='width-vw-99 height-vh-95-min'>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
                config={config}
                bgColorL2={bgColorL2}
                fgColorL1={fgColorL1}
            />
            <div className='pos-absolute top-px-87'>
                <AauMixinFilters
                    lang={lang}
                    filters={[
                        {component: 'textbox', name: 'text', width: 200, changeHandler: newValue => {setSearchPattern(newValue);}, value: searchPattern},
                        {component: 'select', name: 'nbrLigneToDisplay', width: 200, 'options': optionsNbrLigneToDisplay, changeHandler: value => {
                            setNbrLigneToDisplay(value);
                            setData(null);
                        }, value: `Afficher ${nbrLigneToDisplay} lignes`},
                        {component: 'select', name: 'loglevel', width: 200, 'options': optionsLogLevel, changeHandler: value => {setLogLevel(value);},
                            value: (logLevel === 'all' ? 'Tout afficher' : `Afficher ${logLevel} et plus`)
                        }
                    ]}
                />
            </div>
            <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-150'>
                {content}
            </div>
            <AauMixinFabBack lang={lang} config={config} url={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`} />
        </div>
    );
};
AauPageLogHistory.defaultProps = defaultProps;
