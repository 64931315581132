/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {AauMixinIcon} from '../aauMixinIcon/aauMixinIcon';

export interface AauMixinStrToIconProps {
    value: string;
    options: object;
}

const defaultProps = {
    options: {}
} as AauMixinStrToIconProps;

export const AauMixinStrToIcon: FC<AauMixinStrToIconProps> = props => {
    const { value, options } = props;

    let ret = null;

    if ( options.hasOwnProperty(value) ) {
        ret = <AauMixinIcon
            size='tiny'
            color={options[value]['fgColor']}
            icon={options[value].hasOwnProperty('icon') ? options[value]['icon'] : 'home'}
            iconazure={options[value].hasOwnProperty('iconazure') ? options[value]['iconazure'] : ''}
        />;
    }

    return <div className='mx-auto width-80 float-left'>
        <div className='fit-content mx-auto'>
            {ret}
        </div>
    </div>;
};
AauMixinStrToIcon.defaultProps = defaultProps;
