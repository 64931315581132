/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useParams} from 'react-router-dom';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {AauMixinPageHeader} from '../aauMixinPageHeader/aauMixinPageHeader';

import axios from 'axios';
import Cookies from 'js-cookie';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import {IComboBoxOption} from '@fluentui/react';
import {AauMixinFilters} from '../aauMixinFilters/aauMixinFilters';
import {AauPageSearchCard} from "./aauPageSearchCard";


export interface AauPageSearchProps {
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL2?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode: boolean;
}

const defaultProps = {
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauPageSearchProps;

export const AauPageSearch: FC<AauPageSearchProps> = props => {
    const {lang, config, bgColorL2, fgColorL1} = props;
    const {search} = useParams();
    const [data, setData] = useState(null);

    const [filterModule, setFilterModule] = useState('ALL');
    const [filterName, setFilterName] = useState(null);
    const [filterState, setFilterState] = useState('ALL');

    const dataArray = [];
    const optionsState: IComboBoxOption[] = [{
        key: `ALL`,
        text: `ALL`
    }, {
        key: `false`,
        text: `Inactif`
    }, {
        key: `true`,
        text: `Actif`
    }];
    const optionsModule: IComboBoxOption[] = [{
        key: `ALL`,
        text: `${lang.getAction('ALL')}`
    }];
    const optionsModuleArray = [];

    // let content = <AauMixinLoader />;
    // let filterContent = null;

    const retrieveData = async () => {
        await axios.get(
            `/rest/search?find=${search}`,
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };



    if ( data !== null && data !== false ) {
        data['results'].forEach(row => {
            if ( (filterModule === 'ALL' || row['module'] === filterModule) && (filterName === null || row['name'].indexOf(filterName) !== -1) && (filterState === 'ALL' || (filterState === 'false' && row['state'] === false) || (filterState === 'true' && row['state'] === true) )){
                dataArray.push({
                    key: row['itemid'],
                    url: `/${row['admin'] ? 'admin/' : ''}${row['module']}/view/${row['itemid']}`,
                    module: row['module'],
                    name: row['name'],
                    user: row['updatedBy'],
                    state: row['state'],
                    ts: `${row['updatedAt'].split('.')[0]}`
                });
            }

            if ( !optionsModuleArray.includes(row['module']) ) {
                optionsModuleArray.push(row['module']);
                optionsModule.push({
                    key: `${row['module']}`,
                    text: `${row['module']}`
                });
            }
        });
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }


    return (
        <div className='width-vw-99 height-vh-95-min'>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                config={config}
                bgColorL2={bgColorL2}
                fgColorL1={fgColorL1}
            />
            <div className='pos-absolute top-px-87'>
                <AauMixinFilters
                    lang={lang}
                    filters={[
                        {component: 'search', name: 'name', 'width': 200, changeHandler: value => {setFilterName(value);}},
                        {component: 'select', name: 'module', 'width': 200, 'options': optionsModule, changeHandler: (option, value) => {
                            if (typeof (option) !== 'undefined' ) {
                                setFilterModule(option.toString());
                            }
                        }, value: `${filterModule}`},
                        {component: 'select', name: 'state', 'width': 200, 'options': optionsState, changeHandler: (option, value) => {
                            if (typeof (option) !== 'undefined' ) {
                                setFilterState(option.toString());
                            }
                        }, value: `${filterState}`}
                    ]}
                />
            </div>
            <div className='p-5 pos-absolute width-92 top-px-150'>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{600: 1, 1200: 2, 1800: 3, 2400:4, 3000:5}}
                >
                    <Masonry gutter={20}>
                        {
                            dataArray.map((item: { key: string | number }) => (
                                <AauPageSearchCard
                                    url={item['url']}
                                    module={item['module']}
                                    key={item['id']}
                                    id={item['id']}
                                    user={item['user']}
                                    name={item['name']}
                                    state={item['state']}
                                    timestamp={item['ts']}
                                    config={config}
                                />
                            ))
                        }
                    </Masonry>
                </ResponsiveMasonry>;
            </div>
            {/*<AauMixinFabBack lang={lang} url={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`} />*/}
        </div>
    );
};
AauPageSearch.defaultProps = defaultProps;
