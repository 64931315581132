/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';


export interface AauMixinJsonProps {
    value: any;
}

const defaultProps = {
} as AauMixinJsonProps;

export const AauMixinJson: FC<AauMixinJsonProps> = props => {
    const {value} = props;

    return <div className='p-2'>
        WIP - {value}
    </div>;
};
AauMixinJson.defaultProps = defaultProps;
