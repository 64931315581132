/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';


export interface AauMixinStrMaskProps {
    value: string;
    maskEnabled?: boolean;
    maskChar?: string;
    maskLen?: number;
}

const defaultProps = {
    maskEnabled: false,
    maskChar: '*',
    maskLen: 10
} as AauMixinStrMaskProps;

export const AauMixinStrMask: FC<AauMixinStrMaskProps> = props => {
    const {value, maskEnabled, maskChar, maskLen} = props;

    let content = maskChar.repeat(maskLen);

    if ( maskEnabled === true ) {
        content = value;
    }

    return <div>
        {content}
    </div>;
};
AauMixinStrMask.defaultProps = defaultProps;
