
/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

//TODO (vincent.candeau): Manage List key item

import React, {FC, useState} from 'react';

import {AauComponentForm} from '../aauComponentForm/aauComponentForm';
import {AauMixinIconButton} from '../aauMixinIconButton/aauMixinIconButton';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';
import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import format from 'string-format';


export interface AauDynamicAccordionItemProps {
    key:string;
    dynamicKey: string;
    readonly: boolean;
    columns: object[];
    value: object[];
    substate: boolean;
    index: string;
    expanded: boolean;
    isValid: boolean;
    subStateClick: (index:string) => void;
    subCopyClick: (index:string, item:object) => void;
    subDeleteClick: (index:string) => void;
    subModeDownClick: (index:string) => void;
    subModeUpClick: (index:string) => void;
    subExpandedClick: (index:string) => void;
    parentFunction: (index: number, item: object, valid: boolean) => void;
    itemsCount: number;
    titleStr?:  string;
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    readonly: false,
    columns: [],
    value: [],
    isValid: false,
    substate: false,
    subStateClick: null,
    subCopyClick: null,
    subModeDownClick: null,
    subModeUpClick: null,
    subDeleteClick: null,
    parentFunction: null,
    itemsCount: 0,
    titleStr: '',
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauDynamicAccordionItemProps;

export const AauDynamicAccordionItem: FC<AauDynamicAccordionItemProps> = props => {
    const {titleStr, dynamicKey, readonly, columns, value, substate, index, expanded, isValid,
        subExpandedClick, subStateClick, subCopyClick, subDeleteClick, subModeDownClick, subModeUpClick,
        parentFunction, itemsCount, lang, config, fgColorL1} = props;

    const [state] = useState<object>({
        content: value
    });

    const subSetData = (indexTmp: number,  key: string, valueTmp: any, valid: boolean): void => {
        const stateTmp = JSON.parse(JSON.stringify(state['content']));
        stateTmp[key] = valueTmp;

        if ( parentFunction !== null ) {
            parentFunction(indexTmp, stateTmp, valid);
        }
    };

    const btnSize = 'tinyiest';

    let stateBtn = null;
    let ctrlBtn = null;
    if ( substate ) {
        stateBtn = <AauMixinIconButton
            key={`${dynamicKey}-btn-state`}
            variant='full'
            size={btnSize}
            iconazure='PowerButton'
            bgColor={substate === true && value['substate'] === 1 ? 'l3-green-dark' : 'l4-red'}
            fgColor='l3-white'
            className='mr-0'
            tooltip={lang.getBtn(`subform_${substate === true && value['substate'] === 1 ? 'enabled' : 'disabled'}`)}
        />;
    }
    if ( readonly === false ) {
        if ( substate ) {
            stateBtn = <AauMixinIconButton
                key={`${dynamicKey}-btn-state`}
                variant='full'
                size={btnSize}
                iconazure='PowerButton'
                bgColor={substate === true && state['content']['substate'] === 1 ? 'l3-green-dark' : 'l4-red'}
                fgColor='l3-white'
                className='mr-0'
                tooltip={lang.getBtn(`subform_${substate === true && state['content']['substate'] === 1 ? 'disabled' : 'enabled'}`)}
                mouseClickHandler={e => {
                    e.stopPropagation();
                    subStateClick(index);
                }}
            />;
        }
        ctrlBtn = <div>
            <AauMixinIconButton
                key={`${dynamicKey}-btn-up`}
                variant='onlyicon'
                size={btnSize}
                iconazure='TriangleUp12'
                fgColor={fgColorL1}
                disabled={index === '0' ? true : false }
                className='mr-0'
                tooltip={lang.getBtn('subform_up')}
                mouseClickHandler={e => {
                    e.stopPropagation();
                    if ( index !== '0' ) {
                        subModeUpClick(index);
                    }
                }}
            />
            <AauMixinIconButton
                key={`${dynamicKey}-btn-down`}
                variant='onlyicon'
                size={btnSize}
                iconazure='TriangleDown12'
                fgColor={fgColorL1}
                disabled={Number(index)+1 >= itemsCount ? true : false }
                className='mr-0'
                tooltip={lang.getBtn('subform_down')}
                mouseClickHandler={e => {
                    e.stopPropagation();
                    if ( Number(index)+1 < itemsCount ) {
                        subModeDownClick(index);
                    }
                }}
            />
            <AauMixinIconButton
                key={`${dynamicKey}-btn-copy`}
                variant='onlyicon'
                size={btnSize}
                iconazure='Copy'
                fgColor={fgColorL1}
                className='mr-0'
                tooltip={lang.getBtn('susubform_copyform_down')}
                mouseClickHandler={e => {
                    e.stopPropagation();
                    subCopyClick(index, {...state});
                }}
            />
            <AauMixinIconButton
                key={`${dynamicKey}-btn-delete`}
                variant='onlyicon'
                size={btnSize}
                iconazure='Delete'
                fgColor={fgColorL1}
                className='mr-3'
                tooltip={lang.getBtn('subform_delete')}
                mouseClickHandler={e => {
                    e.stopPropagation();
                    subDeleteClick(index);
                }}
            />
        </div>;
    }

    const css_expanded_ok = readonly ? 'd-block' : 'fit-content width-100 op-100 z-100';
    const css_expanded_ko = readonly ? 'd-none' : 'height-px-0 op-0 z-10';

    return <div
        className={`float-clear width-100 pos-relative`}
    >
        <div
            onClick={() => {
                subExpandedClick(index);
            }}
            className={`z-200 p-0 pr-3 pl-3 mt-1 bd-1 bd-l3-black fg-${fgColorL1}`}
        >
            {stateBtn}
            {ctrlBtn}
            <div className={`p-1 fg-${fgColorL1} float-left ${stateBtn !== null ? 'ml-10' : ''}`}>
                {format(titleStr, state['content'])} {isValid ? null : <span className='ml-3 fg-l4-red text-bold'>Erreur</span>}
            </div>
            <div className='float-clear' />
        </div>
        <div
            className={`${expanded ? css_expanded_ok : css_expanded_ko}`}
        >
            <AauComponentForm
                key={`${dynamicKey}-form`}
                columns={columns}
                className={`${expanded ? 'fit-content width-90 op-100 z-100' : 'height-px-0 op-0 z-10'}`}
                item={state['content']}
                mainForm={false}
                readonly={readonly}
                dynamicKey={dynamicKey}
                parentFunction={subSetData}
                lang={lang}
                config={config}
                fgColorL1={fgColorL1}
            />
        </div>
    </div>;
};
AauDynamicAccordionItem.defaultProps = defaultProps;
