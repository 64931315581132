/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';

import {Link} from '@fluentui/react';
import {AauMixinIcon} from "../aauMixinIcon/aauMixinIcon";
import {AauMixinPersona} from "../aauMixinPersona/aauMixinPersona";


export interface AauPageSearchCardProps {
    id: string,
    user: string,
    url: string,
    name: string,
    state: boolean,
    module: string,
    timestamp: string,
    config: AauToolsConfiguration;
}

const defaultProps = {
} as AauPageSearchCardProps;

export const AauPageSearchCard: FC<AauPageSearchCardProps> = props => {
    const {id, user, url, name, state, module, timestamp, config} = props;

    return <Link href={url} key={id} className={`pos-relative bd-1 height-px-60-min width-100`}>
        <div className={`pos-absolute width-px-40 p-2`}>
            <AauMixinPersona
                displayName={user}
                config={config}
            />
        </div>
        <div className={`float-left mt-0 pl-14 width-100`}>
            <span className={`d-block mt-2 font-11 fg-l3-grey`}><span className={`text-bold fg-l3-grey`}>{user}</span> le <span className={`text-bold`}>{timestamp}</span></span>
            <span className={`d-block mt-2 font-14`}><span className={`text-bold`}>[{module}]</span>&nbsp;<span className={` fg-l3-white`}>{name}</span></span>
        </div>
        <AauMixinIcon
            className='pos-absolute right-0 top-0 p-2'
            color={state ? 'l5-green' : 'l5-red'}
            icon={state ? 'checkmark' : 'cross'}
            size='tiny'
        />
    </Link>
};
AauPageSearchCard.defaultProps = defaultProps;
