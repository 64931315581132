/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';


export interface AauFormHelperProps {
    key: string;
    name: string;
    helper: string;
    className?: string;
    lang: AauToolsLang;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    className: '',
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauFormHelperProps;

export const AauFormHelper: FC<AauFormHelperProps> = props => {
    const {name, helper, className, lang, fgColorL1} = props;

    return <div className={`font-10 mt-2 ${className}`}>
        <div className={`fg-${fgColorL1}`} dangerouslySetInnerHTML={{__html: lang.getHelper(helper, name)}}></div>
    </div>;
};
AauFormHelper.defaultProps = defaultProps;
