/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {Helmet} from 'react-helmet-async';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';
import {useBoolean} from '@fluentui/react-hooks';
import {TeachingBubble} from '@fluentui/react/lib/TeachingBubble';
import {DirectionalHint} from '@fluentui/react/lib/Callout';
import {AauMixinIcon} from '../aauMixinIcon/aauMixinIcon';
import {AauMixinBadgeVersion} from "../aauMixinBadgeVersion/aauMixinBadgeVersion";

export interface AauMixinPageHeaderProps {
    pageTitle: string;
    pageHelp: string;
    menuBadgeText?: string;
    menuBadgeState?: string;
    config: AauToolsConfiguration;
    bgColorL2?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
}

const defaultProps = {
    menuBadgeText: null,
    menuBadgeState: null,
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum
} as AauMixinPageHeaderProps;

export const AauMixinPageHeader: FC<AauMixinPageHeaderProps> = props => {
    const {pageTitle, pageHelp, menuBadgeText, menuBadgeState, config, bgColorL2, fgColorL1} = props;

    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);

    return <div>
        <Helmet>
            <title>{config.getGlobal('sitename')}</title>
            <link rel='canonical' href={window.location.href} />
        </Helmet>
        <div className={`bg-${bgColorL2}-light fg-${fgColorL1} float-clear width-100 pt-2 pb-2 text-center text-bold pos-fixed top-px-48 z-30`}>
            <div className={`fit-content mx-auto`}>
                <div className='float-left  height-px-19-min'>
                    {pageTitle}
                    {
                        menuBadgeText !== null && <AauMixinBadgeVersion
                            className={`font-9 p-1 ml-4`}
                            text={menuBadgeText}
                            state={menuBadgeState}
                        />
                    }
                </div>
                {
                    pageHelp !== null && <div
                        id='teachingBubbleToggle'
                        className='bd-0 p-0 ml-2 float-left'
                        onClick={toggleTeachingBubbleVisible}
                    >
                        <AauMixinIcon
                            size='inline'
                            color='l3-white'
                            icon=''
                            iconazure='Info'
                        />
                    </div>
                }
                <div className='float-clear' />
            </div>
            {
                teachingBubbleVisible && (
                    <TeachingBubble
                        calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                        target='#teachingBubbleToggle'
                        isWide={true}
                        hasCloseButton={true}
                        closeButtonAriaLabel='Close'
                        onDismiss={toggleTeachingBubbleVisible}
                        headline={`${pageTitle}`}
                    >
                        <div dangerouslySetInnerHTML={{__html: pageHelp}}></div>
                    </TeachingBubble>
                )
            }
        </div>
    </div>;
};
AauMixinPageHeader.defaultProps = defaultProps;
