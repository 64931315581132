/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';

export interface AauMixinProgressProps {
    value: number;
}

const defaultProps = {
} as AauMixinProgressProps;

export const AauMixinProgress: FC<AauMixinProgressProps> = props => {
    const { value } = props;

    return <div className='p-2'>
        <div className={`width-100 text-center`}>{value.toString()}%</div>
        <ProgressIndicator
            percentComplete={value/100}
            barHeight={10}
            ariaValueText={value.toString()}
        />
    </div>;
};
AauMixinProgress.defaultProps = defaultProps;
