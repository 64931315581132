/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';

import axios from 'axios';
import Cookies from 'js-cookie';
import {Helmet} from 'react-helmet-async';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

import {AauComponentLogin} from '../aauComponentLogin/aauComponentLogin';
import {toast} from 'react-toastify';


export interface AauPageLoginProps {
    login_action: boolean;
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorL2?: aauToolsColorEnum;
    bgColorMain?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode?: boolean;
}

const defaultProps = {
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    bgColorMain: 'l3-blue' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    darkMode: false
} as AauPageLoginProps;

export const AauPageLogin: FC<AauPageLoginProps> = props => {
    const {login_action, lang, config, bgColorL1, bgColorL2, bgColorMain, fgColorL1, darkMode} = props;

    const processLogin = async dataLogin => {
        await axios.post(
            `/auth`,
            JSON.stringify(dataLogin),
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            const user = res.data['login'];
            Cookies.set('JWT', res.data['token']);
            Cookies.set('user', user);
            Cookies.set('rank', res.data['rank']);
            Cookies.set('displayname', res.data['displayname']);

            let profil = null;
            if (localStorage.getItem(user) === null) {
                profil = {};
            } else {
                profil = JSON.parse(localStorage.getItem(user));
            }
            if ( profil.hasOwnProperty('favorites') !== true  ) {
                profil['favorites'] = {};
            }
            if ( profil.hasOwnProperty('filters') !== true ) {
                profil['filters'] = {};
            }
            if ( profil.hasOwnProperty('darkMode') !== true ) {
                profil['darkMode'] = true;
            }
            localStorage.setItem(user, JSON.stringify(profil));

            var qs = require('qs'); //SONAR
            const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

            let ret = '/';
            if ( params.hasOwnProperty('redirect') ) {
                ret = params.redirect;
            }

            window.location.href = ret;
        }).catch(err => {
            toast.error(err.response.data.msg);
        });
    };

    if ( login_action === false ) {
        Cookies.remove('JWT');
        Cookies.remove('user');
        Cookies.remove('rank');
        Cookies.remove('displayname');
        window.location.href = '/login';
    }

    return (
        <div>
            <Helmet>
                <title>{config.getGlobal('sitename')}</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            <AauComponentLogin
                lang={lang}
                config={config}
                bgColorL1={bgColorL1}
                bgColorL2={bgColorL2}
                bgColorMain={bgColorMain}
                fgColorL1={fgColorL1}
                darkMode={darkMode}
                processLogin={processLogin}
            />
        </div>
    );
};
AauPageLogin.defaultProps = defaultProps;