/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';
import {AauMixinIconButton} from '../aauMixinIconButton/aauMixinIconButton';
import {aauSize} from '../aauMixinIcon/aauMixinIcon';
import AauToolsLang from '../aauToolsLang/aauToolsLang';


export interface AauMixinBadgeProps {
    less?: number;
    greater?: number;
    precision?: number;
    value: number;
    size?: aauSize;
    lang: AauToolsLang;
}

const defaultProps = {
    less: 40,
    greater: 70,
    precision: 2,
    size: 'tiny'
} as AauMixinBadgeProps;

export const AauMixinBadge: FC<AauMixinBadgeProps> = props => {
    const { value, less, greater, precision, size, lang } = props;

    let color;
    let cssIcon;
    let tooltip;

    if (value < less) {
        color = 'l4-red';
        cssIcon = 'cross';
        tooltip = 'mixinBadgeLess';
    } else if (value > greater) {
        color = 'l3-green-dark';
        cssIcon = 'checkmark';
        tooltip = 'mixinBadgeGreater';
    } else {
        color = 'l3-orange-dark';
        cssIcon = 'warning-v2';
        tooltip = 'mixinBadgeBetween';
    }

    return <div className='pos-relative mx-auto text-center mt-3'>
        <div className={`pos-absolute bg-l3-black fg-l3-white font-12 p-1 bd-radius-3 top-30-minus right-20 z-10`}>{value.toFixed(precision)}</div>
        <AauMixinIconButton
            variant='onlyicon'
            size={size}
            fgColor={color}
            icon={cssIcon}
            type='div'
            tooltip={lang.getBtn(tooltip)}
            className={`pos-relative z-11`}
            classContainer={`mx-auto`}
        />
    </div>;
};
AauMixinBadge.defaultProps = defaultProps;
