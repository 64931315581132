/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useParams} from 'react-router-dom';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../../Components/aauToolsConfiguration/aauToolsConfiguration';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';

import {AauMixinFabBack} from '../../Components/aauMixinFab/aauMixinFabPredined';
import axios from 'axios';
import Cookies from 'js-cookie';
import {aauToolsColorEnum} from '../../Components/aauToolsColor/aauToolsColor';

import {IComboBoxOption} from '@fluentui/react';
import {AauMixinFilters} from '../../Components/aauMixinFilters/aauMixinFilters';
import {AauMixinCartoVis} from '../../Components/aauMixinCartoVis/aauMixinCartoVis';
import {confirmAlert} from "react-confirm-alert";
import {AauMixinIconButton} from "../../Components/aauMixinIconButton/aauMixinIconButton";


export interface AauPageCartoAudienceProps {
    lang: AauToolsLang;
    config: AauToolsConfiguration;
    bgColorL2?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    darkMode?: boolean;
}

const defaultProps = {
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    darkMode: false

} as AauPageCartoAudienceProps;

export const AauPageCartoAudience: FC<AauPageCartoAudienceProps> = props => {
    const {admin} = useParams();
    const {lang, config, bgColorL2, fgColorL1, darkMode} = props;
    const [data, setData] = useState(null);
    const [visnetwork, setVisnetwork] = useState(null);
    const [showlabel, setShowlabel] = useState<boolean>(true);
    const [showNetwork, setShowNetwork] = useState<boolean>(true);
    const [shapeFilter, setShapeFilter] = useState<string>('shapeall');
    const [colorFilter, setColorFilter] = useState<string>('colorall');
    const [showfollow, setShowfollow] = useState<boolean>(true);
    const [shownofollow, setShownofollow] = useState<boolean>(true);
    const [showredirect, setShowredirect] = useState<boolean>(true);
    const [onlyerror, setOnlyerror] = useState<boolean>(false);
    const [tooltip, setTooltip] = useState<number>(null);
    const [websitedraw, setWebsitedraw] = useState<number>(null);

    const module = 'linksdirectory';
    const action = 'cartonetwork';
    const identifier = 0;

    let content = <AauMixinLoader />;
    let tooltipContent = null;
    let filterContent = null;
    let websitepopup = null;

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/${action}/${identifier}`,
            {
                baseURL: `${config.getGlobal('back')}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    if ( data !== null && data !== false ) {
        const nodesTmp = [];
        const edgesTmp = [];

        const nodesSearch = [];
        const fqdnToId = {};
        const idToFqdn = {};
        const idToData = {};

        const colors: IComboBoxOption[] = [{key: 'colorall', text: `${lang.getSelectOption(module, 'colorall')}`}];
        const colorsList = {};

        const shapes: IComboBoxOption[] = [{key: 'shapeall', text: `${lang.getSelectOption(module, 'shapeall')}`}];
        const shapesList = {};

        const widthFactorReduction = 10;

        const edgeIds = [];

        Object(data['info']['data']).forEach(website => {
            nodesSearch.push(website['fqdn']);
            fqdnToId[website['fqdn']] = website['id'];
            idToFqdn[website['id']] = website['fqdn'];
            idToData[website['id']] = website;

            let hidden = !onlyerror || ( onlyerror && website['error'] === true ) ? false : true;
            hidden = !hidden && shapeFilter !== 'shapeall' && website['shape'] !== shapeFilter ? true : hidden;
            hidden = !hidden && colorFilter !== 'colorall' && website['network_color'] !== colorFilter ? true : hidden;

            if ( !colorsList.hasOwnProperty(website['network_color']) ) {
                colorsList[website['network_color']] = `${lang.getSelectOption(module, website['network_color'])}`;
            }

            if ( !shapesList.hasOwnProperty(website['shape']) ) {
                shapesList[website['shape']] = `${lang.getSelectOption(module, website['shape'])}`;
            }

            nodesTmp.push({
                id: `${website['id']}`,
                label: showlabel === false  ? '' : website['fqdn'],
                shape: website['shape'],
                color: website[showNetwork ? 'network_color' : 'google_color'],
                hidden
            });

            Object.keys(website['links']).forEach(link => {
                const [siteType, siteName] = link.split('_');

                let edgeHidden = false;
                let edgeDashes = false;
                let edgeOpacity = 0.3;
                if ( siteType === 'NF' ) {
                    edgeHidden = shownofollow === false? true : edgeHidden;
                    edgeOpacity = 1;
                } else if ( siteType === 'R' ) {
                    edgeHidden = showredirect === false ? true : edgeHidden;
                    edgeDashes = true;
                    edgeOpacity = 1;
                } else {
                    edgeHidden = showfollow === false ? true : edgeHidden;
                }


                if ( data['info']['domains_list'].hasOwnProperty(siteName) && data['info']['websites_list'].includes(siteName) ) {
                    const edge_id = `${website['id']}_to_${data['info']['domains_list'][siteName]}`;

                    if ( !edgeIds.includes(edge_id) ) {
                        edgesTmp.push({
                            id: `${website['id']}_to_${data['info']['domains_list'][siteName]}`,
                            from: website['id'],
                            to: data['info']['domains_list'][siteName],
                            dashes: edgeDashes,
                            hidden: edgeHidden,
                            color: {
                                'inherit': 'both',
                                'opacity': edgeOpacity
                            },
                            value: website['links'][link] / widthFactorReduction
                        });
                        edgeIds.push(edge_id)
                    }
                }
            });
        });

        Object.keys(shapesList).sort((first, second) => {
            let ret = 0;
            if ( shapesList[first].toLowerCase() < shapesList[second].toLowerCase() ) {
                ret = -1;
            } else if ( shapesList[first].toLowerCase() > shapesList[second].toLowerCase() ) {
                ret = 1;
            } else {
                //NOSONAR
            }

            return ret;
        }).forEach(key => {
            shapes.push({
                key,
                text: shapesList[key]
            });
        });

        Object.keys(colorsList).sort((first, second) => {
            let ret = 0;
            if ( colorsList[first].toLowerCase() < colorsList[second].toLowerCase() ) {
                ret = -1;
            } else if ( colorsList[first].toLowerCase() > colorsList[second].toLowerCase() ) {
                ret = 1;
            } else {
                //NOSONAR
            }

            return ret;
        }).forEach(key => {
            colors.push({
                key,
                text: colorsList[key]
            });
        });

        if ( websitedraw !== null ) {
            const internalNodes = [];
            const internalEdges = [];
            const internalEdgesExcl = [];

            const nodes = idToData[websitedraw]['internal']['nodes'];
            const edges = idToData[websitedraw]['internal']['edges'];

            const colors = [
                '#FF0000',
                '#B39DDB',
                '#80DEEA',
                '#FFCC80',
                '#CCCCCC'
            ];

            for (let url in nodes) {
                const urlTmp = (url.slice(0, -1) === '/' ? url.slice(0, -1) : url).split('/').filter(x => x !== "");

                let shape = 'dot';
                let color = colors[urlTmp.length-2];
                if ( urlTmp.length === 2 && urlTmp[1] === idToData[websitedraw]['fqdn'] ) {
                    shape = 'diamond';
                }
                if ( urlTmp[1] !== idToData[websitedraw]['fqdn'] ) {
                    shape = 'square';
                    color = '#00FF00';
                }

                internalNodes.push({
                    id: `${idToData[websitedraw]['fqdn']}_node_${nodes[url]}`,
                    label: urlTmp[1] !== idToData[websitedraw]['fqdn'] ? urlTmp[1] : urlTmp[urlTmp.length-1],
                    shape: shape,
                    color: color

                });
            };

            for (let idspair in edges) {
                if ( ! internalEdgesExcl.includes(`${idToData[websitedraw]['fqdn']}_edge_${edges[idspair].split('@')[0]}_to_${edges[idspair].split('@')[1]}`) ) {
                    internalEdges.push({
                        id: `${idToData[websitedraw]['fqdn']}_edge_${edges[idspair].split('@')[0]}_to_${edges[idspair].split('@')[1]}`,
                        from: `${idToData[websitedraw]['fqdn']}_node_${edges[idspair].split('@')[0]}`,
                        to: `${idToData[websitedraw]['fqdn']}_node_${edges[idspair].split('@')[1]}`,
                        dashes: false,
                        hidden: false,
                        color: {
                            'inherit': 'both',
                            'opacity': 0.8
                        },
                        value: 0.00005
                    });
                    internalEdgesExcl.push(`${idToData[websitedraw]['fqdn']}_edge_${edges[idspair].split('@')[0]}_to_${edges[idspair].split('@')[1]}`);
                }
            };

            confirmAlert({
                title: `${config.getGlobal('sitename')}`,
                message: `Cartographie du site ${idToData[websitedraw]['fqdn']}`,
                closeOnEscape: true,
                closeOnClickOutside: true,
                customUI: ({onClose}) => {
                    const closeHandler = () => {
                        setWebsitedraw(null);
                        onClose();
                    };
                    return (
                        <div className={`confirm-ui p-10 bg-l4-grey fg-l3-white`}>
                            <h1 className={`text-center`}>{config.getGlobal('sitename')}</h1>
                            <p className={`text-center`}>Cartographie du site <b>{idToData[websitedraw]['fqdn']}</b></p>
                            <div className={`d-block width-vw-70 height-vh-70`}>
                                <AauMixinCartoVis
                                    events={{}}
                                    nodes={internalNodes}
                                    edges={internalEdges}
                                    color={config.getColor('fluent_ui_palette' , darkMode)['palette']['neutralSecondary']}
                                    networkHandler={setVisnetwork}
                                />
                            </div>
                            <AauMixinIconButton
                                variant='righticontext'
                                size='tiny'
                                bgColor='l3-green-dark'
                                fgColor='l3-white'
                                icon='checkmark'
                                className='width-90 text-bold'
                                classContainer='mx-auto mt-8 width-50 float-left'
                                mouseClickHandler={closeHandler}
                                type='div'
                                tooltip={lang.getBtn('continue')}
                            />
                            <div className='float-clear'></div>
                        </div>
                    );
                }
            })
        }

        content = <AauMixinCartoVis
            events={{
                doubleClick: function (params) { //NOSONAR
                    var nodeId = this.getNodeAt(params.pointer.DOM);
                    if (nodeId !== undefined) {
                        window.open(`http://${idToFqdn[nodeId]}`, '_blank');
                    }
                },
                oncontext: function (params) { //NOSONAR
                    params.event.preventDefault();
                    var nodeId = this.getNodeAt(params.pointer.DOM);
                    if (nodeId !== undefined) {
                        setWebsitedraw(nodeId);
                        setTooltip(null);
                    }
                },
                hoverNode: function(params) { //NOSONAR
                    const nodeId = this.getNodeAt(params.pointer.DOM);
                    setTooltip(nodeId);
                },
                blurNode: function(params) { //NOSONAR
                    setTooltip(null);
                }
            }}
            nodes={nodesTmp}
            edges={edgesTmp}
            color={config.getColor('fluent_ui_palette' , darkMode)['palette']['neutralSecondary']}
            networkHandler={setVisnetwork}
            sprintLength={400}
        />;

        tooltipContent = tooltip !== null ? <div
            className={`right-px-20 width-px-300-min top-25 bd-1 d-block pos-absolute p-2 pt-0 z-200 bg-${bgColorL2}`}
            style={{'borderColor': config.getColor('fluent_ui_palette' , darkMode)['palette']['neutralSecondary']}}
        >
            <h1 className={`text-center fg-${fgColorL1} width-100 mt-2 font-16`}>{idToData[tooltip]['fqdn']}</h1>
            <ul>
                <li className='mt-2'><span className='text-bold text-underline'>Audience</span>
                    <ul>
                        <li><b>Branche</b>: {idToData[tooltip]['group']}</li>
                        <li><b>Link IN</b>: {idToData[tooltip]['network_link_in']}</li>
                        <li><b>Link OUT</b>: {idToData[tooltip]['network_link_out']}</li>
                    </ul>
                </li>
                <li className='mt-2'><span className='text-bold text-underline'>Réseau</span>
                    <ul>
                        <li><b>IP</b>: {idToData[tooltip]['ip_value']}</li>
                        <li><b>IP Region</b>: {idToData[tooltip]['ip_geoip_country']} ({idToData[tooltip]['ip_geoip_continent']})</li>
                        <li><b>IP Utilisé</b>: {idToData[tooltip]['ip_use']}</li>
                    </ul>
                </li>
                <li className='mt-2'><span className='text-bold text-underline'>DNS</span>
                    <ul>
                        <li><b>Registar</b>: {idToData[tooltip]['dns_registar']}</li>
                        <li>Expire dans <b>{idToData[tooltip]['dns_delta']}</b> jours</li>
                        <li><b>NS</b>:
                            {
                                idToData[tooltip]['dns_ns'] !== 'N.C.' && idToData[tooltip]['dns_ns'] !== '' ?
                                <ul>{idToData[tooltip]['dns_ns'].split(',').map(val => <li>{val}</li>)}</ul> :
                                ' N.C.'
                            }
                        </li>
                    </ul>
                </li>
            </ul>
        </div> : null;

        const substractIndex = 2;
        const searchNode = newValue => {
            const nodeSearch =  newValue.toLowerCase();
            const ns = nodesSearch.join('|');
            const nodesSearchList = ns.substr( 1, ns.indexOf('|', ns.indexOf(nodeSearch)) ).split('|');
            const nodeId = fqdnToId.hasOwnProperty(
                nodesSearchList[nodesSearchList.length-substractIndex]
            ) ? fqdnToId[nodesSearchList[nodesSearchList.length-substractIndex]] : null;

            if ( nodeSearch === '' ) {
                visnetwork.moveTo({
                    position:{
                        x: 0,
                        y: 0
                    },
                    scale: 0.75
                });
            } else if ( visnetwork !== null && nodeId !== null ) {
                visnetwork.focus({
                    locked: true
                });
                visnetwork.moveTo({
                    position:{
                        x: visnetwork.body.nodes[nodeId].x,
                        y: visnetwork.body.nodes[nodeId].y
                    },
                    scale: 1.2
                });
            } else {
                //NOSONAR
            }
        };

        filterContent = <AauMixinFilters
            lang={lang}
            filters={[
                {component: 'search', name: 'nodename', 'width': 200, changeHandler: value => {searchNode(value);}},
                {component: 'select', name: 'shape', options: shapes, 'width': 200, changeHandler: value => {setShapeFilter(value);},
                    value: `${lang.getSelectOption(module, shapeFilter)}`
                },
                {component: 'select', name: 'color', options: colors, 'width': 200, changeHandler: value => {setColorFilter(value);},
                    value: `${lang.getSelectOption(module, colorFilter)}`
                },
                {component: 'toggle', name: 'type', options: colors, 'width': 200, changeHandler: () => {setShowNetwork(!showNetwork);}, value: showNetwork},
                {component: 'toggle', name: 'error', options: colors, 'width': 200, changeHandler: () => {setOnlyerror(!onlyerror);}, value: onlyerror},
                {component: 'toggle', name: 'follow', options: colors, 'width': 125, changeHandler: () => {setShowfollow(!showfollow);}, value: showfollow},
                {component: 'toggle', name: 'nofollow', options: colors, 'width': 140, changeHandler: () => {setShownofollow(!shownofollow);}, value: shownofollow},
                {component: 'toggle', name: 'redirect', options: colors, 'width': 125, changeHandler: () => {setShowredirect(!showredirect);}, value: showredirect},
                {component: 'toggle', name: 'label', options: colors, 'width': 150, changeHandler: () => {setShowlabel(!showlabel);}, value: showlabel}
            ]}
        />;
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <div className='width-vw-99 height-vh-95-min'>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
                config={config}
                bgColorL2={bgColorL2}
                fgColorL1={fgColorL1}
            />
            <div className='pos-absolute top-px-87'>
                {filterContent}
            </div>
            <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-150'>
                {content}
                {tooltipContent}
            </div>
            <AauMixinFabBack lang={lang} config={config} url={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`} />
            {websitepopup}
        </div>
    );
};
AauPageCartoAudience.defaultProps = defaultProps;
