/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';
import AauToolsLang from '../aauToolsLang/aauToolsLang';
import AauToolsConfiguration from '../aauToolsConfiguration/aauToolsConfiguration';

export interface AauComponentTableColumnProps {
    showFilter?: boolean;
    name: string;
    label: string;
    mixin: string;
    enums: object;
    width: number,
    filter: string | null;
    filterValue?: string | number | boolean | object;
    component: object;
    primary: boolean;
    state: boolean;
    title: string;
    setFilters: any;
    sort: string;
    sortSetColumn?: any;
    columns?: AauComponentTableColumnProps[];
    parentSetColumns?: any;
    lang?: AauToolsLang;
    config?: AauToolsConfiguration;
    bgColorL1?: aauToolsColorEnum;
    bgColorL2?: aauToolsColorEnum;
    bgColorMain?: aauToolsColorEnum;
    fgColorL1?: aauToolsColorEnum;
    data?: object[];
    darkMode?: boolean;
}

export const AauComponentTableColumnPropsDefault = {
    label: '',
    showFilter: null,
    bgColorL1: 'l3-black' as aauToolsColorEnum,
    bgColorL2: 'l4-grey' as aauToolsColorEnum,
    bgColorMain: 'l3-blue' as aauToolsColorEnum,
    fgColorL1: 'l3-white' as aauToolsColorEnum,
    sort: 'none',
    data: [],
    darkMode: false
} as AauComponentTableColumnProps;
