/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC, MouseEvent} from 'react';

import './aauMixinIconButton.css';

import {TooltipHost} from '@fluentui/react/lib/Tooltip';
import {AauMixinIcon, aauSize, aauMixinIconsEnum} from '../aauMixinIcon/aauMixinIcon';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';
import {Link} from 'react-router-dom';
import {useId} from '@fluentui/react-hooks';

export type aauMixinIconButtonVariant = 'outlined' | 'contained_onlytext' | 'outlined_onlytext' | 'contained' | 'onlyicon' |
    'onlytext' | 'righticontext' | 'lefticontext' | 'nooutlined_righticontext' | 'nooutlined_lefticontext' | 'full';


export interface AauMixinIconButtonProps {
    id?: string;
    variant?: aauMixinIconButtonVariant;
    size?: aauSize;
    bgColor?: aauToolsColorEnum;
    fgColor?: aauToolsColorEnum;
    icon?: aauMixinIconsEnum;
    iconazure?: string;
    className?: string;
    classContainer?: string;
    disabled?: boolean;
    tooltip: string;

    mouseClickHandler?: (e: MouseEvent<HTMLElement>) => void;
    mouseEnterHandler?: (e: MouseEvent<HTMLElement>) => void;
    mouseLeaveHandler?: (e: MouseEvent<HTMLElement>) => void;
    mouseOverHandler?: (e: MouseEvent<HTMLElement>) => void;
    mouseOutHandler?: (e: MouseEvent<HTMLElement>) => void;

    link?: string;
    type?: 'a' | 'link' | 'div';
    radius?: number;
    refHandler?: any;
    fab?: boolean;
}

const defaultProps = {
    variant: 'full',
    size: 'medium',
    bgColor: 'l3-white',
    fgColor: 'l3-blue',
    icon: 'windows',
    iconazure: '',
    tooltip: null,
    classContainer: '',
    className: '',
    type: 'div',
    fab: false,
    radius: 0
} as AauMixinIconButtonProps;

export const AauMixinIconButton: FC<AauMixinIconButtonProps> = props => {
    const {variant, refHandler, size, bgColor, fgColor, icon, iconazure, className, classContainer, disabled, tooltip,
        mouseClickHandler, mouseEnterHandler, mouseLeaveHandler, mouseOverHandler, mouseOutHandler,
        link, type, radius, fab} = props;

    const tooltipId = useId('tooltip');

    let BtnBgColor = bgColor;
    let shadow = true;
    if (disabled) {
        BtnBgColor = 'l3-grey-light' as aauToolsColorEnum;
        shadow = false;
    }

    let btn;
    const shadowcss = shadow ? 'aau-btn-shadow' : '';
    switch (variant) {
        case 'outlined':
            btn = <button
                aria-describedby={tooltipId}
                ref={refHandler}
                type='button'
                className={`aau-btn bd-radius-${fab === true ? size : radius} ${fab === true ? 'pt-1' : ''} btn-${size} ${shadowcss} bg-transparent bd-${BtnBgColor} bd-${BtnBgColor}-dark-hover ${className}`}
                onClick={mouseClickHandler}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
                onMouseOver={mouseOverHandler}
                onMouseOut={mouseOutHandler}
            >
                <AauMixinIcon size={size} color={BtnBgColor} icon={icon} iconazure={iconazure}/>
            </button>;
            break;
        case 'onlyicon':
            btn = <button
                aria-describedby={tooltipId}
                ref={refHandler}
                type='button'
                className={`aau-btn bd-radius-${fab === true ? size : radius} btn-${size} bg-transparent bd-1 bd-${fgColor}-dark-hover ${className}`}
                onClick={mouseClickHandler}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
                onMouseOver={mouseOverHandler}
                onMouseOut={mouseOutHandler}
            >
                <AauMixinIcon size={size} color={fgColor} icon={icon} iconazure={iconazure}/>
            </button>;
            break;
        case 'onlytext':
            btn = <button
                aria-describedby={tooltipId}
                ref={refHandler}
                type='button'
                className={`aau-btn bd-radius-${fab === true ? size : radius} ${shadowcss} p-2 bg-${BtnBgColor} bg-${BtnBgColor}-dark-hover p-1 ${className}`}
                onClick={mouseClickHandler}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
                onMouseOver={mouseOverHandler}
                onMouseOut={mouseOutHandler}
            >
                {tooltip}
            </button>;
            break;
        case 'righticontext':
            btn = <button
                aria-describedby={tooltipId}
                ref={refHandler}
                type='button'
                className={`aau-btn bd-radius-${fab === true ? size : radius} ${shadowcss} p-2 bg-${BtnBgColor} bg-${BtnBgColor}-dark-hover fg-${fgColor} ${className}`}
                onClick={mouseClickHandler}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
                onMouseOver={mouseOverHandler}
                onMouseOut={mouseOutHandler}
            >
                <AauMixinIcon size={size} color={fgColor} icon={icon} iconazure={iconazure}/>&nbsp;&nbsp;{tooltip}
            </button>;
            break;
        case 'lefticontext':
            btn = <button
                aria-describedby={tooltipId}
                ref={refHandler}
                type='button'
                className={`aau-btn bd-radius-${fab === true ? size : radius} ${shadowcss} p-2 bg-${BtnBgColor} bg-${BtnBgColor}-dark-hover fg-${fgColor} ${className}`}
                onClick={mouseClickHandler}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
                onMouseOver={mouseOverHandler}
                onMouseOut={mouseOutHandler}
            >
                {tooltip}&nbsp;&nbsp;<AauMixinIcon size={size} color={fgColor} icon={icon} iconazure={iconazure}/>
            </button>;
            break;
        case 'outlined_onlytext':
            btn = <button
                aria-describedby={tooltipId}
                ref={refHandler}
                type='button'
                className={`aau-btn bd-radius-${fab === true ? size : radius} btn-${size} ${shadowcss} bg-transparent bd-${BtnBgColor} bd-${BtnBgColor}-dark-hover fg-${BtnBgColor} p-1 ${className}`}
                onClick={mouseClickHandler}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
                onMouseOver={mouseOverHandler}
                onMouseOut={mouseOutHandler}
            >
                {tooltip}
            </button>;
            break;
        case 'nooutlined_righticontext':
            btn = <button
                aria-describedby={tooltipId}
                ref={refHandler}
                type='button'
                className={`aau-btn text-left bg-transparent fg-${fgColor} p-1 ${className} fit-content`}
                onClick={mouseClickHandler}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
                onMouseOver={mouseOverHandler}
                onMouseOut={mouseOutHandler}
            >
                <AauMixinIcon size={size} color={fgColor} icon={icon} iconazure={iconazure}/>&nbsp;&nbsp;{tooltip}
            </button>;
            break;
        case 'nooutlined_lefticontext':
            btn = <button
                aria-describedby={tooltipId}
                ref={refHandler}
                type='button'
                className={`aau-btn text-left bg-transparent fg-${fgColor} p-1 ${className} fit-content`}
                onClick={mouseClickHandler}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
                onMouseOver={mouseOverHandler}
                onMouseOut={mouseOutHandler}
            >
                {tooltip}&nbsp;&nbsp;<AauMixinIcon size={size} color={fgColor} icon={icon} iconazure={iconazure}/>
            </button>;
            break;
        case 'contained_onlytext':
            btn = <button
                aria-describedby={tooltipId}
                ref={refHandler}
                type='button'
                className={`aau-btn bd-radius-${fab === true ? size : radius} btn-${size} ${shadowcss} bg-transparent bg-${BtnBgColor} bg-${BtnBgColor}-dark-hover fg-${BtnBgColor} p-1 ${className}`}
                onClick={mouseClickHandler}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
                onMouseOver={mouseOverHandler}
                onMouseOut={mouseOutHandler}
            >
                {tooltip}
            </button>;
            break;
        case 'contained':
        case 'full':
        default:
            btn = <button
                aria-describedby={tooltipId}
                ref={refHandler}
                type='button'
                className={`aau-btn bd-radius-${fab === true ? size : radius} ${fab === true ? 'pt-1' : ''} btn-${size} ${shadowcss} bg-${BtnBgColor} bg-${BtnBgColor}-dark-hover ${className}`}
                onClick={mouseClickHandler}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
                onMouseOver={mouseOverHandler}
                onMouseOut={mouseOutHandler}
            >
                <AauMixinIcon size={size} color={fgColor} icon={icon} iconazure={iconazure}/>
            </button>;
    }

    btn = <TooltipHost
        content={tooltip}
        id={tooltipId}
        calloutProps={{gapSpace: 0}}
    >
        {btn}
    </TooltipHost>;

    let content;

    let BtnClassContainer = `aau-btn-container m-0 mb-1 ml-1 float-left ${classContainer}`;

    if (classContainer.indexOf('mx-auto') !== -1) {
        BtnClassContainer = `aau-btn-container ${classContainer}`;
    }

    if (type === 'div') {
        content = <div className={BtnClassContainer}>
            {btn}
        </div>;
    } else if (type === 'a') {
        content = <a className={BtnClassContainer} href={link} target='_blank' rel='noopener noreferrer'>
            {btn}
        </a>;
    } else {
        content = <Link className={BtnClassContainer} to={{pathname: link}}>
            {btn}
        </Link>;
    }

    return content;
};
AauMixinIconButton.defaultProps = defaultProps;
