/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';
import {Rating, RatingSize} from '@fluentui/react';
import nextId from 'react-id-generator';

export interface AauMixinRatingProps {
    value: number;
    max?: number;
}

const defaultProps = {
    max: 5
} as AauMixinRatingProps;

export const AauMixinRating: FC<AauMixinRatingProps> = props => {
    const { value, max } = props;

    return <div className='pos-relative'>
        <Rating
            key={nextId()}
            max={max}
            size={RatingSize.Small}
            defaultRating={value}
        />
        <div className='pos-absolute z-10 width-px-150 height-px-40 top-0 d-block' />
    </div>;
};
AauMixinRating.defaultProps = defaultProps;
