/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';
import './aauComponentLogFile.css';

import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';

export interface AauComponentLogFileProps {
    value?: string;
    loglevel?: string;
    searchPattern?: string;
    colorTRACE?: aauToolsColorEnum;
    colorDEBUG?: aauToolsColorEnum;
    colorINFO?: aauToolsColorEnum;
    colorWARN?: aauToolsColorEnum;
    colorERROR?: aauToolsColorEnum;
    colorFATAL?: aauToolsColorEnum;
}

const defaultProps = {
    value: 'N.C.',
    loglevel: 'all',
    searchPattern: '',
    colorDEBUG: 'l3-white',
    colorINFO: 'l4-green',
    colorWARN: 'l3-orange',
    colorERROR: 'l3-red',
    colorFATAL: 'l3-white',
    colorTRACE: 'l3-white'
} as AauComponentLogFileProps;

export const AauComponentLogFile: FC<AauComponentLogFileProps> = props => {
    const { value, searchPattern, loglevel, colorDEBUG, colorINFO, colorWARN, colorERROR, colorFATAL, colorTRACE } = props;

    const colorLine = (line:string):string => {
        let ret;
        if ( line.indexOf(' DEBUG ') !== -1) {
            ret = colorDEBUG;
        } else if ( line.indexOf(' INFO ') !== -1) {
            ret = colorINFO;
        } else if ( line.indexOf(' WARN ') !== -1) {
            ret = colorWARN;
        } else if ( line.indexOf(' ERROR ') !== -1) {
            ret = colorERROR;
        } else if ( line.indexOf(' FATAL ') !== -1) {
            ret = colorFATAL;
        } else if ( line.indexOf(' TRACE ') !== -1) {
            ret = colorTRACE;
        } else {
            ret = 'l3-blue-light';
        }

        return ret;
    };

    const hideLine = (line:string):string => {
        let ret;

        const nologlevel = line.indexOf(' DEBUG ') === -1 && line.indexOf(' INFO ') === -1 && line.indexOf(' WARN ') === -1 && line.indexOf(' ERROR ') === -1;

        if ((line.indexOf(' DEBUG ') !== -1 && ['all', 'debug'].includes(loglevel) && !nologlevel)
            || (line.indexOf(' INFO ') !== -1 && ['all', 'debug', 'info'].includes(loglevel) && !nologlevel)
            || (line.indexOf(' WARN ') !== -1 && ['all', 'debug', 'info', 'warn'].includes(loglevel) && !nologlevel)
            || (line.indexOf(' ERROR ') !== -1 && ['all', 'debug', 'info', 'warn', 'error'].includes(loglevel) && !nologlevel)
            || (['all'].includes(loglevel) && nologlevel)
        ) {
            ret = 'd-block';
        }  else {
            ret = 'd-none';
        }

        return ret;
    };

    let logContent = value.split('\n');
    let foundIndex = 0;

    return (
        <div className='bg-l3-black width-100-min fit-content'>
            {
                logContent.map((line, index) => {
                    let ret = null;

                    if ( line !== '' ) {
                        if ( searchPattern !== '' && value.indexOf(searchPattern) ) {
                            ret = <span
                                id={`found${foundIndex}`}
                                key={`line_${index}`}
                                className={`${hideLine(line)} fg-${colorLine(line)} p-1`}
                                dangerouslySetInnerHTML={{__html: line.replace(new RegExp(searchPattern, 'gi'), `<span id='' style='background-color: yellow; font-weight: bold'>${searchPattern}</span>`)}}
                            />;
                        } else {
                            ret = <span
                                key={`line_${index}`}
                                className={`${hideLine(line)} fg-${colorLine(line)} p-1`}
                                dangerouslySetInnerHTML={{__html: line}}
                            />;
                        }
                    }
                    return ret;
                })
            }
            <div className='float-clear' />
        </div>
    );
};
AauComponentLogFile.defaultProps = defaultProps;
