/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {AauMixinIconButton} from '../aauMixinIconButton/aauMixinIconButton';
import {aauToolsColorEnum} from '../aauToolsColor/aauToolsColor';
import {AauMixinIcon, aauMixinIconsEnum} from '../aauMixinIcon/aauMixinIcon';

export type aauMixinStrLinkScheme = 'http' | 'https';

export type aauMixinStrLinkType = 'text' | 'button' | 'buttonText';


export interface AauMixinStrLinkProps {
    url: string;
    scheme?: aauMixinStrLinkScheme;
    name?: string;
    type?: aauMixinStrLinkType;
    color?: aauToolsColorEnum;
    icon?: aauMixinIconsEnum;
}

const defaultProps = {
    url: '#',
    scheme: 'https',
    name: '',
    type: 'text',
    color: 'l1-grey',
    icon: 'windows'
} as AauMixinStrLinkProps;

export const AauMixinStrLink: FC<AauMixinStrLinkProps> = props => {
    const {url, scheme, name, type, color, icon} = props;

    const href = `${scheme}://${url}`;

    let content;
    switch( type ) {
        case 'text':
            content = (
                <a href={href} target='_blank'  rel='noopener noreferrer' style={{color: "inherit"}} className='p-2'>
                    <AauMixinIcon color={color} icon={null} iconazure={'PageLink' as aauMixinIconsEnum} size={'tiny'} className={'mr-3'} />
                    <span>{name}</span>
                </a>);
            break;
        case 'button':
            content = <AauMixinIconButton variant='righticontext' bgColor={color} link={href} icon={icon} tooltip='' />;
            break;
        case 'buttonText':
            content = <AauMixinIconButton variant='onlytext' bgColor={color} link={href} tooltip={name === '' ? url : name} />;
            break;
        default:
            //NOSONAR
    }

    return content;
};
AauMixinStrLink.defaultProps = defaultProps;
